import { Link } from "react-router-dom";
import styled from "styled-components";

import * as C from "../../constants/styles/colors";
import * as T from "../../constants/styles/typography";

export const Login = styled.div`
  /* margin-bottom: 100px; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 80px;
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  margin: 38px 0px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 279px;
`;
export const Anchor = styled(Link)`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const WrapIcon = styled.span`
  svg {
    width: 50px;
  }
`;

export const WrapAnchor = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 28px;
  width: calc(100% - 30px);

  svg {
    width: 24px;
  }
`;

export const Text = styled.p`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`;

export const Center = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${Text} {
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  margin: 38px 0px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;
  margin-top: 20px;

  button {
    margin: 0 auto;
  }
`;

export const ToggleContainer = styled.div`
  p {
    font-size: 1.2rem;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }
`;
