import React, { useRef, useState } from 'react';

import { handleLength } from 'utils/handleRegex';
import { ReactComponent as PinkArrow } from 'assets/common/arrow-pink.svg';

import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './styled';
import api from 'services/api';

import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/Text';
import Loader from 'components/Loader';
import Textarea from 'components/Textarea';
import SelectGrey from 'components/SelectGrey';
import Variacoes from './Variacoes';
import HeaderCheckout from 'components/HeaderCheckout';

const EmpresaProdutoDetalhes = ({ setSteps, setDados, dados }) => {
  const handleRegister = async () => {
    if (handleError()) {
      setProduto({
        ...produto,
        error: {
          name: !handleLength(produto.name, 'bigger', 0),
          value: !handleLength(produto.value, 'bigger', 0),
          quantity: !produto.variations.map((item) => {
            return handleLength(item.quantity, 'bigger', 0);
          }),
        },
      });
    } else {
      setProduto({
        ...produto,
        error: {
          name: false,
          value: false,
          quantity: false,
        },
        loading: true,
      });
      const response = await api.post('/products/create', {
        products: [produto],
        eventId: dados.eventId,
      });
      if (!response.data.content && !response.data.content.length < 0)
        throw false;
      setDados({
        ...dados,
        products: [
          ...dados.products,
          {
            ...produto,
            _id: response.data.content[0]._id,
          },
        ],
      });
      setSteps(1);
    }
  };

  const fileInput = useRef();
  const [produto, setProduto] = useState({
    photos: [],
    name: '',
    description: '',
    value: '',
    discount: '',
    variations: [],
    loading: false,
    error: {
      name: false,
      value: false,
      quantity: false,
    },
  });

  const handleError = () => {
    const { photos, name, value, variations } = produto;

    if (
      !handleLength(name, 'bigger', 0) ||
      !photos.length > 1 ||
      !handleLength(value, 'bigger', 0) ||
      !variations[0]?.quantity
    ) {
      return true;
    } else {
      return false;
    }
  };

  function processFileChange(event) {
    setProduto((el) => {
      return {
        ...el,
        photos: [],
      };
    });

    if (event.target.files?.length > 5) {
      return alert('O máximo são 5');
    }

    if (event.target.files && event.target.files.length) {
      const fileMap = new Map();

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const fileReader = new FileReader();
        fileMap.set(fileReader, file);
      }

      const mapEntries = fileMap.entries();
      readFile(mapEntries);
    }
  }

  function readFile(mapEntries) {
    const nextValue = mapEntries.next();

    if (nextValue.done === true) {
      return;
    }

    const [fileReader, file] = nextValue.value;

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      // Do black magic for each file here (using fileReader.result)
      setProduto((el) => {
        return {
          ...el,
          photos: [...el.photos, fileReader.result],
        };
      });
      // Read the next file
      readFile(mapEntries);
    };
  }

  const handleChange = ({ value, name }) => {
    setProduto({
      ...produto,
      [name]: value,
    });
  };

  return (
    <>
      <HeaderCheckout
        backAction={() => {
          setSteps(1);
        }}
        title="Cadastrar Produtos"
        icon="packagePlus"
      />
      <S.CadastroContainer className="cadastro-inner-container">
        {produto.photos.length <= 0 && (
          <S.FileInputContainer>
            <S.FileLabel htmlFor="input-avatar">
              <div>
                <PinkArrow />
                <Text as="p" className="labeltext">
                  Pressione aqui e selecione até 5 imagens do produto.
                </Text>
              </div>
            </S.FileLabel>
            <S.FileInput
              type="file"
              id="input-avatar"
              accept="image/png,   image/jpeg"
              onChange={(e) => processFileChange(e)}
              multiple
            />
          </S.FileInputContainer>
        )}
        {produto.photos.length > 0 && (
          <S.SwiperContainer onClick={() => fileInput.current.click()}>
            <Swiper
              pagination={{ clickable: true }}
              centeredSlides={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: true,
              }}
            >
              {produto.photos.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <S.Avatar background={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <PinkArrow />
            <S.FileInput
              ref={fileInput}
              type="file"
              id="input-avatar"
              accept="image/png,   image/jpeg"
              onChange={(e) => processFileChange(e)}
              multiple
            />
          </S.SwiperContainer>
        )}

        <S.InputsContainer>
          <S.Form>
            <Input
              placeholder="Nome do Produto"
              type="text"
              value={produto.name}
              handleChange={handleChange}
              error={produto.error.name}
              name="name"
              required={true}
            />
            <S.TextareaContainer>
              <Textarea
                placeholder="Descrição do Produto"
                type="text"
                value={produto.description}
                handleChange={handleChange}
                name="description"
              />
            </S.TextareaContainer>
            <S.HalfInputContainer>
              <S.RealContainer>
                <Input
                  placeholder="Valor"
                  value={produto.value}
                  handleChange={handleChange}
                  error={produto.error.value}
                  name="value"
                  required={true}
                  type="number"
                />
                {produto.value > 0 && <p>R$</p>}
              </S.RealContainer>
              <SelectGrey
                placeholder="Desconto"
                value={produto.discount}
                handleChange={handleChange}
                name="discount"
                optionList={[
                  { discount: 'Sem desconto' },
                  { discount: '5%' },
                  { discount: '10%' },
                  { discount: '15%' },
                  { discount: '20%' },
                  { discount: '50%' },
                  { discount: '70%' },
                ]}
              />
            </S.HalfInputContainer>
            <Variacoes setProduto={setProduto} produto={produto} />
            {(produto.error.name ||
              produto.error.value ||
              produto.error.quantity) && (
              <S.ErrorMessageContainer>
                <ErrorMessage>
                  Preencha todos os campos necessários
                </ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>

          <S.ButtonContainer>
            {!produto.loading && (
              <Button
                disabled={
                  !produto.photos.length > 0 ||
                  !produto.name ||
                  !produto.value ||
                  !produto.variations[0]?.quantity
                }
                handleClick={handleRegister}
                style={{ background: '#FF3D68' }}
                notScroll
              >
                Continuar
              </Button>
            )}
            {produto.loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
      </S.CadastroContainer>
    </>
  );
};

export default EmpresaProdutoDetalhes;
