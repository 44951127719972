import LiveLabel from 'components/LiveLabel';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleDate } from 'utils/handleDate';
import { capitalize } from 'utils/handleString';

import * as S from './styled';

const SlideCircleItem = ({ item }) => {
  const history = useHistory();

  const handleClick = (item) => {
    history.push(`/live/${item.shop.nameURL}/${item.protoId}/watch`);
  };
  return (
    <S.SlideItem>
      <S.SlideCircle {...item} onClick={() => handleClick(item)} />
      <LiveLabel
        text={handleDate(item.scheduled)}
        textSize="1.4rem"
        top="80px"
        padding="3px 8px"
        style={{
          boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.15)',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      />
      <S.ContainerSlideItemTitle>
        <S.SlideItemTitle>{item?.shop.name}</S.SlideItemTitle>
        <S.SlideItemType>
          {capitalize(item?.categories?.length ? item?.categories[0] : '')}
        </S.SlideItemType>
      </S.ContainerSlideItemTitle>
    </S.SlideItem>
  );
};

export default SlideCircleItem;
