import React from 'react';

import * as S from './styled';

const StepsLine = ({ step }) => {
  const steps = [
    { step: 'Dados', number: 0 },
    { step: 'Produtos', number: 1 },
  ];

  return (
    <S.Steps>
      {steps.map((el) => {
        return (
          <S.StepsLine
            key={el.number}
            active={el.number === step}
          ></S.StepsLine>
        );
      })}
    </S.Steps>
  );
};

export default StepsLine;
