import { Button } from 'components/Button/styled';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 40px;

  svg {
    margin-right: 10px;
  }
`;
export const CarrinhoVazio = styled.div`
  height: 600px;

  > p {
    margin-top: 62px;
  }

  button {
    margin: 49px auto 0px;
  }
`;

export const Carrinho = styled.div`
  height: calc(100% - 270px);
  position: absolute;
  overflow: auto;
  width: 100%;
  padding-bottom: 100px;
`;

export const WrapProducts = styled.div`
  width: 300px;
  margin: auto;
`;


export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;

  gap: 20px;
`;

export const WrapShopName = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
`

export const ShopName = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;

  color: #545454;
`

export const ShopItems = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  color: #545454;

  margin-bottom: 25px;
  padding-bottom: 4px;

  border-bottom: 1px solid #DCDCDC;
  width: 100%;
`

export const ShopDetails = styled.div`
  margin-bottom: 20px;
`

export const BackToLive = styled.div`
  width: 100%;
`