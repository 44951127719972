import React, { useEffect, useState } from 'react';
import BackBar from 'components/BackBar';
import AnchorButton from 'components/AnchorButton';

import * as S from './styled';
import Footer from 'components/Footer';

import Text from 'components/Text';
import { useAuthContext } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { useShopContext } from 'contexts/ShopsContext';
import Loader from 'components/Loader';
import IconComponent from 'components/V2/Icon';
import Button from 'components/V2/Button';
import { getMenuByUser } from './menuList';
import Header from './Header';
import HeaderCheckout from 'components/HeaderCheckout';

const Perfil = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const context = useAuthContext();
  const [avatar, setAvatar] = useState(null);
  const { shopInfo, isLoading } = useShopContext();
  const history = useHistory();

  useEffect(() => {
    setUser(context.user);
    setAvatar(context.user?.avatar || null);
    setLoading(false);
  }, [context.user]);

  const handleLogout = () => {
    context.logout();
    history.push('/');
  };

  const menuItem = getMenuByUser(user?.type);
  return (
    <>
      <HeaderCheckout
        title={''}
        backAction={() => {
          history.push('/');
        }}
        iconBack="home"
        backText="Home"
      />
      <S.Perfil>
        {loading && <Loader />}
        {user && !loading && (
          <>
            <Header user={user} shopInfo={shopInfo} />
            <S.ButtonsContainer>
              {isLoading && <Loader />}

              {!isLoading &&
                (shopInfo?.length == 0 ||
                  !Object.keys(shopInfo || {}).length ||
                  !shopInfo) &&
                user?.type === 'seller' && (
                  <AnchorButton
                    text={'Adicionar outra loja'}
                    background="secondary"
                    disabled={false}
                    path="/empresa/cadastro"
                  />
                )}
            </S.ButtonsContainer>
            <S.PerfilContainer>
              <S.MenuList>
                {user?.type === 'seller' && (
                  <Link to="/empresa/cadastro">
                    <Button styleOfButton="primaryRed">
                      CADASTRE OUTRA LOJA
                    </Button>
                  </Link>
                )}
                {menuItem.map((el, index) => {
                  return (
                    <S.MenuItem key={index}>
                      <IconComponent icon={el.icon} />
                      <Link to={el.pathName}>{el.text}</Link>
                    </S.MenuItem>
                  );
                })}
                <S.MenuItem onClick={() => handleLogout()}>
                  <IconComponent icon="exit" />
                  <S.LogoutButton>Sair</S.LogoutButton>
                </S.MenuItem>

                {user?.type === 'shopper' && (
                  <Link to="/empresa/cadastro">
                    <Button styleOfButton="outlinePrimary" icon="shop">
                      CADASTRE SUA LOJA
                    </Button>
                  </Link>
                )}
              </S.MenuList>
            </S.PerfilContainer>
          </>
        )}
      </S.Perfil>

      <Footer showTop={true} />
    </>
  );
};

export default Perfil;
