export const filterArray = (list = [], keys = [], value = '') => {
  const newValue = value.toLowerCase();
  const arr = new Set();

  list.filter(function (e) {
    for (let i = 0; i < keys.length; i++) {
      const objValue = e[keys[i]].toLowerCase();

      if (objValue.includes(newValue)) {
        arr.add(e);
      }
    }
  });

  return Array.from(arr);
};
