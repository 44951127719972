import { Link } from "react-router-dom";
import styled from "styled-components";

import * as C from "../../constants/styles/colors";
import * as T from "../../constants/styles/typography";

export const Perfil = styled.div`
  height: calc(100% - 141px);
  position: absolute;
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 1em;
`;

export const PerfilContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 279px;
`;

export const PerfilHeader = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PerfilAvatar = styled(Link)`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const Avatar = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 84px 84px;
  background-size: cover;
`;

export const PerfilName = styled.p`
  font-size: 2.2rem;

  margin-bottom: 8px;
  font-weight: 600;
`;

export const PerfilLink = styled(Link)`
  color: #00dfff;
  font-size: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
  margin-bottom: 34px;
  margin-top: 12px;

  > button,
  > a {
    width: 100%;
  }
`;

export const MenuList = styled.ul`
  display: grid;
  grid-gap: 24px;
`;
export const MenuItem = styled.li`
  padding: 16px;
  width: 100%;
  color: ${({ theme }) => theme.colors.SECONDARY_COLOR};
  border: 2px solid ${({ theme }) => theme.colors.SECONDARY_COLOR};
  border-radius: 40px;
  text-align: center;

  text-decoration: underline;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  svg {
    height: 20px;
    width: 20px;
    object-fit: cover;

    path {
      fill: ${({ theme }) => theme.colors.SECONDARY_COLOR};
    }
  }

  a,
  p {
    color: ${({ theme }) => theme.colors.SECONDARY_COLOR};
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export const ShopsContainer = styled.div`
  margin-top: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 26px 0px 40px;

  > div {
    width: 279px;
    margin: 0 auto;

    > a {
      width: 100%;
    }
  }
`;

export const StoreLogo = styled.div`
  background: ${({ logo }) => (logo ? `url(${logo})` : "black")} no-repeat;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  border: 1px solid #adadad;
  background-size: 81px 81px;
  margin-right: 18px;
`;

export const ShopsContainerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  font-size: 1.4rem;
  font-weight: 400;

  a {
    color: #ff3d68;
    display: inline-block;
  }
`;

export const ShopsContainerInfo = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .storeName {
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 3px;
  }

  a {
    color: #ff3d68;
    display: inline-block;
    font-size: 1.4rem;
  }
`;

export const LogoutButton = styled.p`
  cursor: pointer;
`;
