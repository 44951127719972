import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as C from '../../constants/styles/colors';

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 264px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  padding-bottom: 56px;
  position: relative;

  div {
    position: absolute;
    bottom: 20px;
  }
`;
export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;
  width: 50%;
  min-width: 188px;

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  margin: 38px auto 20px;
  width: 75%;
`;

export const LoaderContainer = styled.div`
  margin-top: 25px;
  height: 41px;
`;

export const CheckSent = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin: ${({ margin }) => margin};
  color: ${C.GREEN_CHECK};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
