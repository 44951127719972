import LiveLabel from 'components/LiveLabel';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleDay } from 'utils/handleDate';

import * as S from './styled';

const ShopperSlideItem = ({ item = {}, children, handleOpenModal, type }) => {
  const history = useHistory();
  
  const handleClick = (item) => {
    if (!item?._id) return;

    if (type === 'shopper') {
      handleOpenModal(item._id);
    } else {
      history.push(`/live/${item.shop.nameURL}/${item.protoId}/watch`);
    }
  };

  return (
    <S.SlideItem {...item} onClick={() => handleClick(item)}>
      <S.ContainerSlideItemTitle>
        <S.SlideItemTitle>{item?.title}</S.SlideItemTitle>
      </S.ContainerSlideItemTitle>
      {children}
    </S.SlideItem>
  );
};

export default ShopperSlideItem;
