import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { handleBirthDay, handleCPF, handleIsString, handleLength } from 'utils/handleRegex';

import * as S from './styled';
import { ReactComponent as UserIcon } from 'assets/user/bigAvatar.svg';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';
import Done from 'components/Done';

const ChangePassword = () => {
  const AuthContext = useAuthContext();
  const [user, setUser] = useState();
  const context = useAuthContext();
  const [done, setDone] = useState(false);

  const [password, setPassword] = useState({
    oldPw: '',
    newPw: '',
    reNewPw: '',
    error: false,
    loading: false,
  });

  const handleChange = ({ name, value }) => {
    setPassword({
      ...password,
      [name]: value,
    });
  };

  const handleRegister = async () => {
    setPassword({
      ...password,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setPassword({
        ...password,
        error: true,
      });
    } else {
      try {
        // atualizar usuário

        setPassword({
          ...password,
          error: false,
        });
        setDone(true);
      } catch (err) {
        setPassword({
          ...password,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { oldPw, reNewPw, newPw } = password;

    if (
      !handleLength(oldPw, 'bigger', 8) ||
      !handleLength(reNewPw, 'bigger', 8) ||
      !handleLength(newPw, 'bigger', 8) ||
      !(reNewPw === newPw)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setUser(context.user);
  }, [context]);

  return (
    <>
      <BackBar type="back" path="/perfil" shadow={true} text="Voltar" />
      {!done && (
        <S.CadastroContainer>
          <S.TitleContainer>
            <Title>Alterar senha</Title>
          </S.TitleContainer>
          <S.InputsContainer>
            <S.Form>
              <Input
                placeholder="Digite sua senha anterior"
                type="password"
                value={password.oldPw}
                handleChange={handleChange}
                error={password.error}
                name="oldPw"
                required={true}
              />
              <Input
                placeholder="Nova senha"
                type="password"
                value={password.newPw}
                handleChange={handleChange}
                error={password.error}
                name="newPw"
                required={true}
              />
              <Input
                placeholder="Confirme a nova senha"
                type="password"
                value={password.reNewPw}
                handleChange={handleChange}
                error={password.error}
                name="reNewPw"
                required={true}
              />
              {password.error && (
                <S.ErrorMessageContainer>
                  <ErrorMessage>Digite dados válidos</ErrorMessage>
                </S.ErrorMessageContainer>
              )}
            </S.Form>

            <S.ButtonContainer>
              {!password.loading && (
                <Button
                  disabled={password.newPw.length <= 0 || password.oldPw.length <= 0 || password.reNewPw.length <= 0}
                  handleClick={handleRegister}
                >
                  Continuar
                </Button>
              )}
              {password.loading && <Loader />}
            </S.ButtonContainer>
          </S.InputsContainer>
        </S.CadastroContainer>
      )}
      {done && <Done text="Senha alterada com sucesso" to="/perfil" button="Voltar para conta" />}
      <Footer showTop={true} />
    </>
  );
};

export default ChangePassword;
