import styled, { css } from 'styled-components';
import { Text } from 'components/V2/Text/styled';

export const StepItem = styled.span`
  position: relative;

  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  background-color: ${({ theme, active, error }) =>
    error
      ? theme.colors.PRIMARY_COLOR
      : active
      ? theme.colors.SECONDARY_COLOR
      : theme.colors.GREY_2};

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.WHITE};

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 35px;
    background-color: ${({ theme, active, error }) =>
      !error && active ? theme.colors.SECONDARY_COLOR : theme.colors.GREY_2};
    top: 32px;
    left: 15px;
  }
`;

export const Step = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 35px;

  &:last-child {
    ${StepItem} {
      &::after {
        display: none;
      }
    }
  }
`;

export const WrapText = styled.div`
  ${Text} {
    text-align: start;
  }
`;

export const WrapDescription = styled.div`
  ${Text} {
    font-weight: 400;
  }
`;

export const Wrap = styled.div`
  margin: auto;
  width: 100%;
  margin-top: 20px;
  max-width: 400px;
`;
