import React from 'react';
import { ReactComponent as UserIcon } from 'assets/user/bigAvatar.svg';
import { Link } from 'react-router-dom';

import * as S from '../styled';
import AnchorButton from 'components/AnchorButton';

const Header = ({ user, shopInfo }) => {
  const avatar = user?.avatar;
  return (
    <>
      <S.PerfilContainer>
        <S.PerfilHeader>
          {/* <S.PerfilAvatar to="/cadastro/completo">
        </S.PerfilAvatar> */}
          {!avatar ? <UserIcon /> : <S.Avatar background={avatar} />}

          {user?.type !== 'viewer' && user?.fullName && (
            <div>
              <S.PerfilName>{user?.fullName}</S.PerfilName>
              <S.PerfilLink to="/pedidos">Meus pedidos</S.PerfilLink>
            </div>
          )}

          {(user?.type === 'viewer' || !user?.fullName) && (
            <div>
              <S.PerfilName>Complete seu cadastro</S.PerfilName>
              <S.PerfilLink to="/cadastro/completo">
                Completar cadastro
              </S.PerfilLink>
            </div>
          )}
        </S.PerfilHeader>
      </S.PerfilContainer>
      {user?.type === 'seller' &&
        shopInfo?.map((el) => (
          <S.ShopsContainer key={el._id}>
            <div>
              <S.ShopsContainerMenu>
                <p>Loja Cadastrada</p>
                <Link to={'/empresa/cadastro/' + el._id}>Alterar Cadastro</Link>
              </S.ShopsContainerMenu>
              <S.ShopsContainerInfo>
                <S.StoreLogo logo={el?.logo} />
                <div>
                  <p className="storeName">{el?.name}</p>
                  <Link to="/relatorios">Relatórios de vendas</Link>
                </div>
              </S.ShopsContainerInfo>
              <AnchorButton
                text="Gerenciar lives"
                background="secondary"
                disabled={false}
                path="/"
              />
            </div>
          </S.ShopsContainer>
        ))}
    </>
  );
};

export default Header;
