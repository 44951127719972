import styled from 'styled-components';

import * as C from '../../constants/styles/colors';
import * as T from '../../constants/styles/typography';

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  margin: 38px 0px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 219px;
`;

export const TitleContainer = styled.div`
  margin: 38px 0px;
`;
