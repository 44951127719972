import React from 'react';
import * as S from './styled';

export const copyToClipboard = (text) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  alert('Link copiado!');
  textField.remove();
};

const ClipBoardButton = ({ children, text }) => {
  return (
    <S.Button onClick={() => copyToClipboard(text)}>
      <b>Copiar link</b>
    </S.Button>
  );
};

export default ClipBoardButton;
