import React, { useState } from 'react';

import * as S from './styled';

import Modal from 'components/Modal';
import Title from 'components/Title';
import Text from 'components/Text';

import { uso, privacidade } from 'constants/data/Terms';
import SelectGrey from 'components/SelectGrey';
import Button from 'components/Button';
import Textarea from 'components/Textarea';

const Denunciar = ({ onClick }) => {
  const [step, setStep] = useState(0);
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  return (
    <Modal onClick={onClick}>
      <S.TitleContainer>
        <Title>Denunciar</Title>
      </S.TitleContainer>

      {step === 0 && (
        <>
          <S.TextContainer>
            <Text>Porque você está denunciando esta publicação?</Text>
          </S.TextContainer>
          <S.Description>
            Sua denuncia é anônima. Se alguém estiver em perigo imediato, ligue para o serviço de emergência local. Não
            espere.
          </S.Description>
          <SelectGrey
            placeholder="Selecionar"
            value={type}
            handleChange={(e) => setType(e.value)}
            name="type"
            optionList={[
              { type: 'É SPAM' },
              { type: 'Nudez ou atividade sexual' },
              { type: 'Símbolos ou discurso de ódio' },
              { type: 'Golpe ou fraudes' },
              { type: 'Venda de produtos ilícitos' },
              { type: 'Violência' },
              { type: 'Outro' },
            ]}
          />
          <Button disabled={type === ''} style={{ marginTop: 20 }} handleClick={() => setStep(1)}>
            Continuar
          </Button>
        </>
      )}
      {step === 1 && (
        <>
          <S.TextContainer>
            <Text>Ajude-nos a entender o problema</Text>
          </S.TextContainer>
          <S.TextareaContainer>
            <Textarea
              placeholder="Escreva um pouco sobre o ocorrido"
              type="text"
              value={description}
              handleChange={(e) => setDescription(e.value)}
              name="description"
            />
          </S.TextareaContainer>
          <Button disabled={description.length <= 3} style={{ marginTop: 20 }} handleClick={() => setStep(2)}>
            Enviar
          </Button>
        </>
      )}
      {step === 2 && (
        <>
          <S.TextContainer>
            <Text>Obrigado por sua resposta, iremos verificar o ocorrido.</Text>
          </S.TextContainer>
          <Button disabled={description.length <= 3} style={{ marginTop: 20 }} handleClick={onClick}>
            Voltar para Live
          </Button>
        </>
      )}
    </Modal>
  );
};

export default Denunciar;
