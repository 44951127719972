import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
  }
`;

export const ModalContent = styled.div`
  width: 90%;
  max-width: 295px;
  height: fit-content;
  overflow: auto;
  background: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 68px 30px 43px;
  position: relative;
`;

export const CloseContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`;
