import styled from 'styled-components';

export const Steps = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Step = styled.div`
  width: 70px;
  height: 3px;

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.PRIMARY_COLOR : theme.colors.GREY_2};
`;
