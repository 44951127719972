import styled from 'styled-components';

export const CadastroEmpresa = styled.div`
  height: 100vh;
  position: absolute;
  overflow: auto;
  width: 100%;
  padding-bottom: 45px;

  .cadastro-inner-container {
    overflow: visible;
    height: auto;
    padding-top: 40px;
    padding-bottom: 100px;
  }
`;

export const DoneContainer = styled.div`
  height: calc(100vh - 150px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 40px;

  button {
    margin-top: 20px;
  }

`

export const WrapText = styled.div`
  svg {
    display: block;
    width: 60px;
    margin: auto;

    margin-bottom: 33px;
  }
`