import React from 'react';

import * as S from './styled';

const StepsLine = ({ step }) => {

  const steps = [
    { step: 'Dados', number: 0 },
    { step: 'Endereco', number: 1 },
    { step: 'Categorias', number: 2 },
    // { step: 'LoginInstagram', number: 3 },
    { step: 'Carteira', number: 4 },
  ];

  return (
    <S.Steps>
      {steps.map((el) => {
        return <S.StepsLine key={el.number} active={el.number === step}></S.StepsLine>;
      })}
    </S.Steps>
  );
};

export default StepsLine;
