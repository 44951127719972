import React from 'react';
import * as S from './styled';

const Stepper = ({ steps, currentStep }) => {
  const renderSteps = () => {
    const rows = [];

    for (let index = 1; index <= steps; index++) {
      rows.push(<S.Step key={index} isActive={currentStep === index} />);
    }

    return rows;
  };

  return <S.Steps>{renderSteps()}</S.Steps>;
};

export default Stepper;
