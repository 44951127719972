import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import * as S from './styled';
import { convertMoney } from 'utils/convertMoney';
import { ReactComponent as CloseIcon } from 'assets/common/close-white.svg';

SwiperCore.use([Autoplay, Pagination]);

const ProductImageSlider = ({ list, setProduct }) => {
  return (
    <S.SwiperContainer>
      <div onClick={() => setProduct(null)}>
        <CloseIcon />
      </div>
      <Swiper
        pagination={{ clickable: true }}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
      >
        {list?.photos.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <S.ProdutoPrice>{convertMoney(list.originalPrice)}</S.ProdutoPrice>
    </S.SwiperContainer>
  );
};

export default ProductImageSlider;
