import { useAuthContext } from 'contexts/AuthContext';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/* import { AuthContext } from '../contexts/authContext'; */

function PrivateRoute({ component: Component, ...rest }) {
  const { isLogin } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (isLogin()) {
          return <Component {...routeProps} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: routeProps.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
