import styled from 'styled-components';

import * as C from '../../../constants/styles/colors';
import * as T from '../../../constants/styles/typography';

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  margin: 38px 0px;
`;

export const InstitucionalContent = styled.div`
  width: 85%;
  margin: 0 auto 219px;
`;

export const TitleContainer = styled.div`
  margin: 38px 0px;
`;

export const Text = styled.p`
  ${T.BODY_TEXT_MEDIUM};
  margin-bottom: 20px;
  text-align: justify;
`;

export const Anchor = styled.a`
  display: block;
  margin-bottom: 10px;
  ${T.BODY_TEXT_MEDIUM}
`;
