import React, { useState } from 'react';

import { handleCNPJ, handleLength } from 'utils/handleRegex';
import { ReactComponent as UserIcon } from 'assets/empresa/avatar.svg';

import * as S from './styled';

import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/V2/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/V2/Text';
import InputWithMask from 'components/InputMask';
import Loader from 'components/Loader';
import api from 'services/api';
import { isJsonRpcResponsePayload } from '@magic-sdk/provider';
import IconComponent from 'components/V2/Icon';
import { Link } from 'react-router-dom';

const EmpresaDados = ({ dados, setDados, handleChange, setSteps }) => {
  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      cnpjExists: false,
      loading: true,
    });
    if (handleError()) {
      setDados({
        ...dados,
        error: true,
      });
    } else {
      try {
        let response = await api.get('/shops/check', {
          params: { cnpj: dados.cnpj, uri: window.location.href },
        });
        if (
          response.data?.content &&
          response.data?.content[0]?.companyDetails.cnpj
        ) {
          setDados({
            ...dados,
            cnpjExists: true,
          });
        } else {
          setDados({
            ...dados,
            error: false,
          });
          setSteps(1);
        }
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { logo, razaoSocial, nomeFantasia, cnpj } = dados;
    if (
      !handleLength(logo, 'bigger', 0) ||
      !handleLength(razaoSocial, 'bigger', 3) ||
      !handleLength(nomeFantasia, 'bigger', 0) ||
      !handleLength(cnpj, 'bigger', 17) ||
      !handleCNPJ(cnpj)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateFile = (e) => {
    // Assuming only image
    let file = e.target.files[0];

    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setDados({
          ...dados,
          logo: reader.result,
        });
      };
    }
  };

  return (
    <>
      <S.CadastroContainer>
        <S.TitleContainer>
          <IconComponent icon="company" />
          <Text type="title">Conta Profissional</Text>
        </S.TitleContainer>

        <S.FileInputContainer>
          <S.FileLabel htmlFor="input-avatar">
            <S.PerfilAvatar>
              {!dados.logo ? (
                <UserIcon />
              ) : (
                <S.Avatar
                  src=""
                  alt=""
                  background={dados.logo ? dados.logo : ''}
                />
              )}
            </S.PerfilAvatar>
            <Text>{!dados.logo ? 'Adicionar Logo *' : 'Alterar Logo'}</Text>
          </S.FileLabel>
          <S.FileInput
            type="file"
            id="input-avatar"
            accept="image/png,   image/jpeg"
            onChange={(e) => handleUpdateFile(e)}
          />
        </S.FileInputContainer>
        <S.InputsContainer>
          <S.Form>
            <InputWithMask
              mask="99.999.999/9999-99"
              placeholder="CNPJ"
              value={dados.cnpj}
              handleChange={handleChange}
              error={dados.error || dados.cnpjExists}
              name="cnpj"
              required={true}
            />
            <Input
              placeholder="Razão Social"
              type="text"
              value={dados.razaoSocial}
              handleChange={handleChange}
              error={dados.error}
              name="razaoSocial"
              required={true}
            />
            <Input
              placeholder="Nome Fantasia"
              type="text"
              value={dados.nomeFantasia}
              handleChange={handleChange}
              error={dados.error}
              name="nomeFantasia"
              required={true}
            />
            <InputWithMask
              mask="999999999.99-99"
              placeholder="Inscrição Estadual"
              type="text"
              value={dados.inscricaoEstadual}
              handleChange={handleChange}
              name="inscricaoEstadual"
            />
            <Input
              placeholder="Código de indicação"
              type="text"
              value={dados.codigoIndicacao}
              handleChange={handleChange}
              name="codigoIndicacao"
            />

            {dados.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
            {dados.cnpjExists && (
              <S.ErrorMessageContainer>
                <ErrorMessage>CNPJ já existente na UGONG</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>

          <S.ButtonContainer>
            {!dados.loading && (
              <Button
                disabled={
                  dados.logo.length <= 0 ||
                  dados.cnpj.length <= 17 ||
                  dados.razaoSocial.length <= 0 ||
                  dados.nomeFantasia.length <= 0
                }
                handleClick={handleRegister}
                styleOfButton="primaryRed"
                icon="arrowNext"
              >
                Continuar
              </Button>
            )}
            {dados.loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
      </S.CadastroContainer>
    </>
  );
};

export default EmpresaDados;
