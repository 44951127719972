import Footer from 'components/Footer';

import Header from 'containers/Header';
import HomeLives from 'containers/HomeLive';
import HomeBuscar from 'containers/HomeBuscar';
import HomeAgenda from 'containers/HomeAgenda';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HomeMenu from 'components/HomeMenu';
import { useAuthContext } from 'contexts/AuthContext';
import Title from 'components/Title';

import * as S from './styled';

const Home = () => {
  const [show, setShow] = useState(false);
  const [isShop, setIsShop] = useState(false);
  const context = useAuthContext();
  const [path, setPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setIsShop(context.user?.type === 'seller');
  }, [context]);

  useEffect(() => {
    setPath(location.search ? location.search : location.pathname);
    setShow(true);
  }, [location]);

  return (
    <div>
      <Header />
      {show && !path.includes('search') && <HomeMenu active={path} isshop={isShop} />}
      {show &&
        !path.includes('agenda') &&
        !path.includes('search') &&
        !path.includes('relatorios') &&
        (path.includes('live') || !path.includes('live')) && <HomeLives isshop={isShop} />}
      {show && path.includes('search') && path !== '' && !path.includes('live') && <HomeBuscar />}
      {show && path.includes('agenda') && path !== '' && !path.includes('live') && <HomeAgenda />}
      {show && path.includes('relatorios') && path !== '' && !path.includes('live') && (
        <S.SoonContainer>
          <div>
            <Title>Em breve</Title>
          </div>
        </S.SoonContainer>
      )}
      {show && <Footer showTop={true} />}
    </div>
  );
};

export default Home;
