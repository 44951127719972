import { Button } from 'components/V2/Button/styled'
import { Text } from 'components/V2/Text/styled'
import styled from 'styled-components'

export const SwiperContainer = styled.div`
  ${({ live }) =>
    live
      ? 'background: linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%);'
      : ''};
  padding-left: 20px;

  position: relative;

  overflow: hidden;
  height: ${({ hasProductSelect }) => (hasProductSelect ? '300px' : '130px')};

  transition: height 0.3s, transform 0.3s;

  .swiper-slide {
    margin-right: ${({ hasProductSelect }) =>
      !hasProductSelect ? '20px' : '0'};
  }

  @media screen and (min-width: 1000px) {
    width: 1000px;
    margin: auto;
  }
`

export const WrapSwiper = styled.div`
  width: 100%;
  ${({ isProductLastOne, productPosition }) =>
    isProductLastOne ? `transform: translateX(${productPosition}px)` : ''};
`

export const SwiperTitle = styled.p`
  margin-bottom: 13px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #807f7f;
`

export const ContainerSlideItemTitle = styled.div`
  margin-top: 10px;
  padding-right: 20px;
  text-align: center;
`

export const SlideFloatTitle = styled.div`
  position: absolute;
  top: 18px;
  left: 100px;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  z-index: 2;
`

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  margin-bottom: 10px;
`

export const SlideItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: white;

  text-align: start;

  min-height: 80px;
`

export const Link = styled.a`
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;

  margin-bottom: 10px;

  color: rgba(0, 223, 255, 1);

  text-align: start;
  cursor: pointer;
`

export const Select = styled.select`
  background: #f4f4f4;
  -webkit-appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
`

export const WrapDetails = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;

  ${Button} {
    height: 30px;
    width: 120px;
  }
`

export const WrapForm = styled.div`
  > ${Text} {
    margin: 10px;
    font-size: 15px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.GREY_2};
  }
`

export const WrapDescription = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WrapColumn = styled.div`
  width: 70%;
  padding-right: 20px;
`

export const WrapButton = styled.div`
  svg path {
    fill: white;
  }
`

export const WrapButtonSecondary = styled.div`
  margin-top: 10px;
  svg path {
    fill: ${({ theme }) => theme.colors.SECONDARY_COLOR};
  }
`

export const Pill = styled.div`
  width: 110px;
  height: 28px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.PRIMARY_COLOR};
  color: #fff;

  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const CostPrevious = styled.p`
  color: #c0c0c0;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;

  display: inline;

  &::after {
    content: '';
    display: block;
    width: 120%;
    height: 1px;

    background-color: #c0c0c0;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`

export const Cost = styled.p`
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  display: inline;
`

export const WrapCost = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const WrapContentCost = styled.div`
  display: flex;
  justify-content: flex-end;
`
