import React from 'react';

import * as S from './styled';

import Modal from 'components/Modal';
import Title from 'components/Title';
import Text from 'components/Text';

import { uso, privacidade } from 'constants/data/Terms';

export const CondicoesUso = ({ onClick }) => {
  return (
    <Modal onClick={onClick}>
      <S.TitleContainer>
        <Title>{uso.title}</Title>
      </S.TitleContainer>

      <S.TextContainer>
        <Text>{uso.text}</Text>
      </S.TextContainer>
    </Modal>
  );
};

export const Privacidade = ({ onClick }) => {
  return (
    <Modal onClick={onClick}>
      <S.TitleContainer>
        <Title>{privacidade.title}</Title>
      </S.TitleContainer>

      <S.TextContainer>
        <Text>{privacidade.text}</Text>
      </S.TextContainer>
    </Modal>
  );
};
