import LiveLabel from "components/LiveLabel";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { handleDate } from "utils/handleDate";
import { capitalize, truncateWithout } from "utils/handleString";

import * as S from "./styled";
import { useProducts } from "contexts/ProductsContext";

const SlideCircleProduct = ({ item, changeSlide }) => {

  const { 
    setSelectedProduct, 
    checkIfitemIsSelected, 
    productIsSetted 
  } = useProducts();

  const isItemSelected = checkIfitemIsSelected(item._id);

  const renderCollapseInfo = () => {
    if (productIsSetted) return null;

    return (
      <S.ContainerSlideItemTitle>
        <S.SlideItemTitle>{truncateWithout(item.title, 18)}</S.SlideItemTitle>
        <S.SlideItemType>
          {item.hasStock ? "Disponível" : "Indisponível"}
        </S.SlideItemType>
      </S.ContainerSlideItemTitle>
    );
  };

  return (
    <S.SlideItem>
      <S.SlideCircle
        {...item}
        onClick={() => {
          setSelectedProduct(item);
          changeSlide()
        }}
        selected={isItemSelected}
        highlight={!productIsSetted || isItemSelected}
      />

      {renderCollapseInfo()}
      {/* {renderShortInfo()} */}
    </S.SlideItem>
  );
};

export default SlideCircleProduct;
