import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto 280px;
  width: 100%;
  min-height: 300px;

  button {
    width: 100%;
  }
`;

export const TitleContainerDone = styled.div`
  margin: 100px auto 50px;
  width: 60%;

  p {
    line-height: 35px;
  }
`;
