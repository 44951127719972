import styled from 'styled-components';

export const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 49px);
  gap: 9px;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StepsLine = styled.div`
  width: 49px;
  height: 3px;
  background-color: ${({ active }) => (active ? '#FF3D68' : '#F4F4F4')};
`;
