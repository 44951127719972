import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './styled';

import { ReactComponent as EyeIcon } from 'assets/live/eye.svg';
import { ReactComponent as CloseIcon } from 'assets/live/close.svg';
import { ReactComponent as HeartsIcon } from 'assets/live/hearts.svg';
import { ReactComponent as Send } from 'assets/common/send.svg';

import ReactDOMServer from 'react-dom/server';

import LiveLabel from 'components/LiveLabel';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Share from '../Share';
import BottomMenu from './BottomMenu';
import Denunciar from 'components/Denunciar';
import { useAuthContext } from 'contexts/AuthContext';
import api from 'services/api';
import LiveVideoPlayer from 'components/LiveVideoPlayer';

const Streamer = ({ item, url, liveId, setCarrinho, setProduct }) => {

  const [live, setLive] = useState(null);
  const [share, setShare] = useState(false);
  const [denunciar, setDenunciar] = useState(false);
  const history = useHistory();
  const fatherHearts = useRef();
  const [message, setMessage] = useState('');
  const { user } = useAuthContext();
  const [sentMessages, setSentMessages] = useState([]);

  useEffect(() => {
    setLive(item);
  }, [item]);

  const handleLike = () => {
    const father = fatherHearts.current;
    const element = ReactDOMServer.renderToStaticMarkup(
      <S.Hearts>
        <HeartsIcon />
      </S.Hearts>,
    );
    const children = document.querySelectorAll('.hearts');

    let htmlObject = document.createElement('div');
    htmlObject.classList.add('hearts');
    htmlObject.innerHTML = element;

    if (children.length <= 3) {
      father.append(htmlObject);
    } else {
      father.append(htmlObject);
      const oldElement = children[0];

      oldElement.remove();
    }

    setLive({
      ...live,
      likes: +live.likes + 1,
    });
  };

  const sendMessage = async () => {
    if (message.length <= 0) return;

    try {
      const newMessage = message;
      setMessage('');

      const body = { text: newMessage, event: live._id };
      const response = await api.post('/messages/send', body);
      setSentMessages(response.data.content);
    } catch (err) {
      setMessage('');
      console.error(err);
    }
  };

  return (
    <>
      {live && (
        <S.Streamer className="noselect">
          <S.Banner>
            <LiveVideoPlayer URLs={live.videoURLs} />
            <S.BannerHeader>
              <S.BannerHeaderTop>
                <div>
                  <S.StoreName>{live.shop?.name + ' - ' + live.title}</S.StoreName>
                </div>
                {live.onAir && (
                  <S.LabelsContainer>
                    <LiveLabel
                      text="Live!"
                      style={{ position: 'static' }}
                      background="pink"
                      textSize="1.6rem"
                      padding="4px 8px"
                    />
                    <S.ViewsLabel>
                      <p>{live.views || '0'}</p>
                      <EyeIcon />
                    </S.ViewsLabel>
                  </S.LabelsContainer>
                )}
                <div onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
                  <CloseIcon />
                </div>
              </S.BannerHeaderTop>
              <S.StoreLogo logo={live.shop.logo}>
                {live.shop.logo ? '' : live.shop.name?.charAt(0) + live?.shop.name.charAt(1)}
              </S.StoreLogo>
            </S.BannerHeader>

            <S.BottomContainer>
              <BottomMenu
                live={live}
                sentMessages={sentMessages}
                item={item}
                setDenunciar={setDenunciar}
                setProduct={setProduct}
              />
            </S.BottomContainer>
          </S.Banner>
          <S.ButtonContainer>
            <input
              onKeyDown={(e) => (e.key === 'Enter' ? sendMessage() : null)}
              type="text"
              placeholder="Comentar"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <Send onClick={sendMessage} />
          </S.ButtonContainer>
          {share && (
            <Modal onClick={() => setShare(false)}>
              <Title as="p">Compartilhe</Title>
              <Share selected={live} />
            </Modal>
          )}
          {denunciar && <Denunciar onClick={() => setDenunciar(false)} />}
        </S.Streamer>
      )}
    </>
  );
};

export default Streamer;
