import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const Wrapper = styled.div`
    height: calc(100% - 141px);
    position: absolute;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${({ background }) =>
      `linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%),url(${background})`}
    no-repeat;
  background-size: 100%;
  ${({ live }) =>
    live
      ? 'background: linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%);'
      : '#FFF'};
`;

export const WrapTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;

  svg {
    width: 20px;
  }
`;

export const FileInputContainer = styled.div`
  text-align: center;

  flex-direction: column;
  margin: 0 auto 26px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  height: 182px;
  border: 0.5px solid #d2d2d2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  width: 281px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .pinkArrow {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  .labeltext {
    font-weight: 600;
    font-size: 1rem;
    width: 60%;
    margin: 5px auto 0px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  margin-bottom: 24px;
  position: relative;

  input,
  select {
    width: 279px;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  padding: 50px 0px 24px;
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 15px;
`;

export const ContainerSlideItemTitle = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 70%;
  text-align: right;
`;

export const SlideItemTitle = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.32px;
`;

export const HalfInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;

  div {
    width: auto;
  }

  input {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const DeleteLiveContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 279px;
  margin-top: 20px;
  margin-bottom: 24px;

  p {
    color: ${C.PRIMARY_COLOR};
    font-size: 14px;

    :hover {
      cursor: pointer;
    }
  }
`;
