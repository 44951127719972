import * as yup from 'yup'

export const Schema = yup.object({
  hasVariation: yup.bool(),
  quantity: yup.object({
    value: yup.number().required()
  }),
  color: yup.object({
    value: yup.string().when('$hasVariation', {
      is: true,
      then: (schema) => schema.required()
    })
  }),
  size: yup.object({
    value: yup.string().when('$hasVariation', {
      is: true,
      then: (schema) => schema.required()
    })
  }),
})
