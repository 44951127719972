import React, { useState, useEffect } from 'react'

import { useAuthContext } from 'contexts/AuthContext'
import { handleLength } from 'utils/handleRegex'
import {
  loadFromLocalstorage,
  saveToLocalStorage,
  removeFromLocalStorage,
} from 'utils/handleLocalStorage'

import * as S from './styled'
import api from 'services/api'

import Text from 'components/V2/Text'
import Input from 'components/Input'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Loader from 'components/Loader'
import InputWithMask from 'components/InputMask'

import Modal from 'components/Modal'

import SelectGrey from 'components/SelectGrey'

import states from './states.json'
import IconComponent from 'components/V2/Icon'

const AddressForm = ({ setAddress, getAddress, setShowAddressForm }) => {
  const AuthContext = useAuthContext()
  const [user, setUser] = useState()
  const context = useAuthContext()

  const [address, setTheAddress] = useState({
    zipCode: '',
    street: '',
    city: '',
    state: '',
    country: '',
    number: '',
    district: '',
    complemento: '',
    favorite: true,
    error: false,
    loading: false,
  })

  const handleChange = async ({ name, value }) => {
    setTheAddress({
      ...address,
      [name]: value,
    })
  }

  const handleRegister = async () => {
    setTheAddress({
      ...address,
      error: false,
      loading: true,
    })

    if (handleError()) {
      setTheAddress({
        ...address,
        error: true,
      })
    } else {
      try {
        removeFromLocalStorage('@Ugong/user')
        setUser({})
        const response = await api.post('/user/saveAddress', address)
        saveToLocalStorage('@Ugong/user', response.data.content)
        setUser(response.data.content)
        AuthContext.setUser((el) => {
          return {
            ...el,
            ...response.data.content,
          }
        })
        getAddress && getAddress()
        setAddress(false)
        setShowAddressForm(false)
        setTheAddress((value) => ({
          ...value,
          error: false,
          loading: false,
        }))
      } catch (err) {
        console.log(err)
        setTheAddress({
          ...address,
          error: true,
          loading: false,
        })
      }
    }
  }

  const handleError = () => {
    const { zipCode, street, city, state, number } = address

    if (
      !handleLength(zipCode, 'bigger', 8) ||
      !handleLength(street, 'bigger', 3) ||
      !handleLength(city, 'bigger', 0) ||
      !handleLength(state, 'bigger', 0) ||
      !handleLength(number, 'bigger', 0)
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setUser(context.user)
  }, [context])

  return (
    <>
      <S.CadastroContainer>
        <S.WrapTitle>
          <IconComponent icon="location" />
          <Text type="bodyEnphasis">Adicionar endereço</Text>
        </S.WrapTitle>
        <S.InputsContainer>
          <S.Form>
            <InputWithMask
              mask="99999-999"
              placeholder="CEP"
              type="text"
              value={address.zipCode}
              handleChange={handleChange}
              error={address.error}
              name="zipCode"
              required={true}
            />
            <Input
              placeholder="Logradouro"
              type="text"
              value={address.street}
              handleChange={handleChange}
              error={address.error}
              name="street"
              required={true}
            />
            <Input
              placeholder="Bairro"
              type="text"
              value={address.district}
              handleChange={handleChange}
              error={address.error}
              name="district"
              required={true}
            />
            <Input
              placeholder="Cidade"
              type="text"
              value={address.city}
              handleChange={handleChange}
              error={address.error}
              name="city"
              required={true}
            />
            <S.HalfInputContainer>
              <S.CVVContainer>
                <Input
                  placeholder="Número"
                  type="number"
                  value={address.number}
                  handleChange={handleChange}
                  error={address.error}
                  name="number"
                  required={true}
                />
              </S.CVVContainer>
              <S.CVVContainer>
                <SelectGrey
                  required={true}
                  placeholder="UF"
                  value={address.genero}
                  handleChange={(e) =>
                    setTheAddress({ ...address, state: e.value })
                  }
                  error={address.error}
                  name="sigla"
                  optionList={states}
                />
              </S.CVVContainer>
            </S.HalfInputContainer>
            <Input
              placeholder="Complemento"
              type="text"
              value={address.complemento}
              handleChange={handleChange}
              error={address.error}
              name="complemento"
            />
            {address.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>

          <S.ButtonContainer>
            {!address.loading && (
              <Button
                disabled={
                  address.zipCode.length <= 0 ||
                  address.street.length <= 0 ||
                  address.state.length <= 0 ||
                  address.city.length <= 0 ||
                  address.number.length <= 0
                }
                handleClick={handleRegister}
              >
                Continuar
              </Button>
            )}
            {address.loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
      </S.CadastroContainer>
    </>
  )
}

export default AddressForm
