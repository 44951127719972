import React from 'react';

import { useHistory } from 'react-router-dom';

import HeaderCheckout from 'components/HeaderCheckout';
import Text from 'components/V2/Text';
import Container from 'components/V2/Container';
import IconComponent from 'components/V2/Icon';

import * as S from './styled';
import ProductCarrinho from 'containers/ProductPagamento';
import TrackingPackage from 'components/V2/TrackingPackage';
import { handleFormatBR } from 'utils/handleDate';
import Footer from 'components/Footer';
import ClipBoardButton from 'components/Button/clipBoardButton';
import { useEffect } from 'react';
import { status } from '../status';
import icons from 'components/V2/Icon/files';

const Details = (props) => {
  const history = useHistory();

  const order = props?.history?.location?.state?.item || {};

  const shop = order?.shop || {};
  const charge = order?.payment_data?.charges[0];
  const isPix = !!order.qrcode_pix;

  const renderPaymentDetails = () => {
    if (isPix) {
      return 'PIX';
    }

    const creditCard = charge?.last_transaction?.card?.last_four_digits || '';

    return `**** **** **** ${creditCard}`;
  };

  const renderPaymentStatus = () => {
    return status[order?.status] || '';
  };

  useEffect(() => {
    if (!order) {
      history.push('/pedidos');
    }
  }, [order]);

  const renderAddressDetails = () => {
    const address = order?.address;

    return `${address.street}, ${address.number} - ${address.district} - ${address.city} - ${address.state}`;
  };

  const renderFlag = () => {
    if (isPix) return 'pixLogo';

    const flag = charge?.last_transaction?.card?.brand || '';

    if (icons[flag.toLocaleLowerCase()]) {
      return flag.toLocaleLowerCase();
    }

    return 'creditCard';
  };

  const renderPixCode = () => {
    if (!isPix) return <></>;

    if (order?.status !== 'open') return <></>;

    return (
      <S.Card>
        <Text type="cardTitle">Copie este código para pagar</Text>
        <S.StepsToPay>
          <li>Acesse seu internet banking ou app de pagamentos.</li>
          <li>Escolha pagar via Pix.</li>
          <li>Cole o seguinte código.</li>
        </S.StepsToPay>

        <S.GreyBar>{order?.qrcode_pix}</S.GreyBar>

        <S.WrapButton>
          <ClipBoardButton text={order?.qrcode_pix}>Copiar</ClipBoardButton>
        </S.WrapButton>

        <S.StepsToPay>Ou efetue a leitura do QRCode abaixo </S.StepsToPay>

        <S.WrapPix>
          <img src={order?.qrcode_url_pix} alt="" />
        </S.WrapPix>
      </S.Card>
    );
  };

  return (
    <S.WrapAll>
      <HeaderCheckout
        title={'Detalhe da compra'}
        backAction={() => {
          history.push('/pedidos');
        }}
      />
      <S.Content>
        <Container>
          <S.ShopName>
            <S.WrapImage>
              <img src={shop?.logo} />
            </S.WrapImage>
            <Text type="bodyEnphasis">{shop?.name}</Text>
          </S.ShopName>

          <S.WrapProducts>
            {order?.items?.map((el) => (
              <ProductCarrinho
                product={el}
                key={el?.product._id}
                canEdit={false}
              />
            ))}
          </S.WrapProducts>

          <S.Card>
            <Text type="cardTitle">Detalhes do pagamento</Text>
            <S.WrapInfoCard>
              <IconComponent icon={renderFlag()} />
              <Text type="small">{renderPaymentDetails()}</Text>
            </S.WrapInfoCard>
          </S.Card>

          {renderPixCode()}

          <S.Card>
            <Text type="cardTitle">Endereço do envio</Text>
            <S.WrapInfoCard>
              <IconComponent icon="truck" />
              <Text type="small">{renderAddressDetails()}</Text>
            </S.WrapInfoCard>
          </S.Card>

          <TrackingPackage
            createdAt={handleFormatBR(order.createdAt)}
            payAt={
              order?.payment_data?.closed_at
                ? handleFormatBR(order?.payment_data?.closed_at)
                : null
            }
            notPayed={order.status === 'payment_failed'}
            preparedAt={null}
            sendedAt={null}
            recivedAt={null}
          />
        </Container>
        <Footer />
      </S.Content>
    </S.WrapAll>
  );
};

export default Details;
