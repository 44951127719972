import styled from 'styled-components';

export const SlideItem = styled.div`
  position: relative;
  cursor: pointer;
`;

export const SlideCircle = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.35) 95.83%),
    ${({ photos }) => (photos[0] ? `url(${photos[0]})` : 'grey')} no-repeat;
  border: 0.5px solid #c1c1c1;
  background-size: 100% 62px;
  position: relative;
  
  margin: ${({ selected }) => !selected ? '0 auto' : ''};
  filter: brightness(1.1) saturate(1.5);

  opacity: ${({ highlight }) => highlight ? 1 : 0.3} ;

`;

export const ContainerSlideItemTitle = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const SlideItemTitle = styled.p`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 1.2rem;
  height: 41px;
  color: #8a8a8a;
`;

export const SlideItemType = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0.6;
  color: white;
`;

export const SlideItemDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: white;

  margin-top: 15px;
  padding-left: 10px;
`;
