import { Link } from "react-router-dom";
import styled from "styled-components";

export const Menu = styled.div`
  padding: 13px 0px 20px;
  height: 65px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const MenuThirds = styled.div`
  padding: 13px 0px 20px;
  height: 65px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

export const MenuItem = styled(Link)`
  width: 100%;
  padding: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-weight: 500;
  background: ${({ active, isshop }) =>
    active && !isshop ? "#00DFFF" : active && isshop ? "#FF3D68" : "#FFFFFF"};
  color: ${({ active }) => (active ? "#FFFFFF" : "#8A8A8A")};
  cursor: pointer;
  text-align: center;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  svg {
    height: 25px;
    width: 25px;

    object-fit: cover;

    path {
      fill: ${({ active }) => (active ? "#FFFFFF" : "#8A8A8A")};
    }
  }
`;
