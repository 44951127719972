import React from 'react';
import { ReactComponent as BackArrow } from 'assets/common/back-arrow.svg';
import { useLiveContext } from 'contexts/LivesContext';
import { useHistory } from 'react-router-dom';
import Text from 'components/V2/Text';

import * as S from './styled';
import IconComponent from 'components/V2/Icon';
const HeaderCheckout = ({
  title,
  icon,
  backAction,
  backText = '',
  iconBack = 'arrowBack',

  rightBackAction,
  rightIconBack,
  rightBackText,
}) => {
  const { liveDetails } = useLiveContext();
  const history = useHistory();

  return (
    <S.Header>
      <S.Content>
        <S.Link
          onClick={() => {
            if (backAction) {
              return backAction();
            }
            history.push(`/live/${liveDetails.shop.nameURL}/${liveDetails.protoId}/watch`);
          }}
        >
          <div>
            <IconComponent icon={iconBack} />
          </div>
          <Text type="bodyEnphasis">{backText}</Text>
        </S.Link>
        <S.WrapTitle>
          <IconComponent icon={icon} />
          <S.HeaderTitle>{title}</S.HeaderTitle>
        </S.WrapTitle>
        <S.Link
          onClick={() => {
            if (rightBackAction) {
              return rightBackAction();
            }
          }}
        >
          <Text type="bodyEnphasis">{rightBackText}</Text>
          <div>
            <IconComponent icon={rightIconBack} />
          </div>
        </S.Link>
      </S.Content>
    </S.Header>
  );
};

export default HeaderCheckout;
