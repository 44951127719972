export const PRIMARY_COLOR = "#FF3D68";
export const SECONDARY_COLOR = "#00DFFF";

export const INFO = "#00DFB2";
export const SUCCESS = "#FFCF4F";
export const WARNING = "#F0BDD9";
export const ERROR = "#EB5757";

export const GREY_1 = "#545454";
export const GREY_2 = "#DCDCDC";
export const GREY_3 = "#F4F4F4";
/**
 * Components
 */
export const TEXT_PRIMARY_COLOR = GREY_1;
export const TEXT_SECONDARY_COLOR = GREY_2;

export const INPUT_ERROR_COLOR = "#FF0000";
export const GREEN_CHECK = "#31DA42";

/*
COMMON
*/
export const WHITE = "#FFF";
export const BLACK = "#000";

/**
 * Companys
 */
export const INSTAGRAM =
  "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)";

export const FACEBOOK = "#0085FF";

export const GOOGLE = "#C5C5C5";
