import styled from 'styled-components';

 // height is 100% minus the sum of all other elements'
 // fixed heights, such as the Header, HomeMenu and Footer
export const HomeContainer = styled.div`
  height: calc(100% - 197px);
  position: absolute;
  overflow: auto;
  width: 100%;
`;
