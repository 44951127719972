import React from 'react';

import { ReactComponent as DropdownIcon } from 'assets/common/select.svg';

import * as S from './styled';

const Select = ({ disabled, value, optionList = [], placeholder, error, handleChange, name }) => {
  return (
    <S.SelectContainer>
      <S.Select disabled={disabled} value={value} onChange={(e) => handleChange(e.target)} name={name} error={error}>
        <option value="">{placeholder}</option>
        {optionList.map((element, index) => {
          return (
            <option key={index} value={element[name]}>
              {element[name]}
            </option>
          );
        })}
      </S.Select>
      {!value && (
        <S.DropdownIcon>
          <DropdownIcon />
        </S.DropdownIcon>
      )}
    </S.SelectContainer>
  );
};

export default Select;
