import React, { useState } from 'react';

import * as S from './styled';

import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/V2/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/V2/Text';
import InputWithMask from 'components/InputMask';
import Loader from 'components/Loader';
import { CondicoesUso, Privacidade } from 'components/Terms';
import { handleLength } from 'utils/handleRegex';
import IconComponent from 'components/V2/Icon';

const EmpresaEndereco = ({ dados, setDados, handleChange, setSteps }) => {
  const [terms, setTerms] = useState({
    privacidade: false,
    uso: false,
  });
  const [preview, setPreview] = useState('');
  const [done, setDone] = useState(false);

  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setDados({
        ...dados,
        error: true,
      });
    } else {
      try {
        setDados({
          ...dados,
          error: false,
        });
        setSteps(2);
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { website, address, cellphone } = dados;

    if (
      !handleLength(website, 'bigger', 0) ||
      !handleLength(address, 'bigger', 0) ||
      !handleLength(cellphone, 'bigger', 14)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateFile = (e) => {
    // Assuming only image
    let file = e.target.files[0];

    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setPreview(reader.result);
        setDados({
          ...dados,
          logo: reader.result,
        });
      };
    }
  };

  return (
    <>
      <S.CadastroContainer>
        <S.TitleContainer>
          <IconComponent icon="company" />
          <Text type="title">Conta Profissional</Text>
        </S.TitleContainer>

        <S.FileInputContainer>
          <S.Logo src="" alt="" background={dados.logo} />
        </S.FileInputContainer>
        <S.InputsContainer>
          <S.Form>
            <Input
              placeholder="Endereço comercial (completo)"
              type="text"
              value={dados.address}
              handleChange={handleChange}
              error={dados.error}
              name="address"
              required={true}
            />
            <Input
              placeholder="URL do website"
              type="text"
              value={dados.website}
              handleChange={handleChange}
              error={dados.error}
              name="website"
              required={true}
            />
            <InputWithMask
              mask="(99) 99999-9999"
              placeholder="Celular"
              type="text"
              value={dados.cellphone}
              error={dados.error}
              handleChange={handleChange}
              name="cellphone"
              required={true}
            />
            <Input
              placeholder="Telefone comercial"
              type="text"
              value={dados.telefone}
              handleChange={handleChange}
              name="telefone"
            />

            {dados.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>
          <S.TermsContainer>
            <Text>
              Ao continuar, você confirma que é responsável pela empresa e
              concorda com a{' '}
              <S.LinkExternal
                href="https://ugong.com.br/wp-content/uploads/2022/04/Ugong-politica-de-privacidade-e-cookies_PDF.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text type="bodyEnphasis">Politica de privacidade</Text>
              </S.LinkExternal>{' '}
              e as
              <S.LinkExternal
                href="https://ugong.com.br/wp-content/uploads/2022/04/Termos-e-Condic%CC%A7o%CC%83es-Gerais-de-Uso-UGONG.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text type="bodyEnphasis">Condições de Uso</Text>
              </S.LinkExternal>{' '}
              da Ugong.
            </Text>
          </S.TermsContainer>

          <S.ButtonContainer>
            {!dados.loading && (
              <Button
                disabled={
                  dados.address.length <= 0 ||
                  dados.cellphone.length <= 0 ||
                  dados.website.length <= 0
                }
                handleClick={handleRegister}
                styleOfButton="primaryRed"
                icon="arrowNext"
              >
                Continuar
              </Button>
            )}
            {dados.loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
        {terms.privacidade && (
          <Privacidade
            onClick={() => setTerms({ ...terms, privacidade: false })}
          />
        )}
        {terms.uso && (
          <CondicoesUso onClick={() => setTerms({ ...terms, uso: false })} />
        )}
      </S.CadastroContainer>
    </>
  );
};

export default EmpresaEndereco;
