import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { handleLength } from 'utils/handleRegex';

import * as S from './styled';
import api from 'services/api';

import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';
import InputWithMask from 'components/InputMask';
import {
  valCardDate,
  valCardName,
  valCardNumber,
  valCvv,
} from 'utils/validationCard';
import Modal from 'components/Modal';
import BackBar from 'components/BackBar';
import HeaderCheckout from 'components/HeaderCheckout';

const CardPaymentForm = ({ setAddCard, getCards }) => {
  const AuthContext = useAuthContext();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const context = useAuthContext();
  const [cvvTip, setCvvTip] = useState(false);

  const [card, setCard] = useState({
    cardNumber: '',
    cardName: '',
    cardValidate: '',
    cardCVV: '',
    error: false,
    loading: false,
  });

  const handleChange = ({ name, value }) => {
    setCard({
      ...card,
      [name]: value,
    });
  };

  const handleRegister = async () => {
    setLoading(true);
    setCard({
      ...card,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setLoading(false);
      setCard({
        ...card,
        error: true,
      });
    } else {
      try {
        setCard({
          ...card,
          error: false,
        });
        const response = await api.post('/user/card', card); //pendente
        getCards && getCards();
        setLoading(false);
        setAddCard(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        setCard({
          ...card,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { cardName, cardCVV, cardNumber, cardValidate } = card;

    if (
      !valCardNumber(cardNumber) ||
      !valCardDate(cardValidate) ||
      !valCardName(cardName) ||
      !valCvv(cardCVV)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setUser(context.user);
  }, [context]);

  return (
    <>
      <S.CadastroContainer>
        <HeaderCheckout
          title={'Adicionar Cartão'}
          icon="creditCardAdd"
          backAction={() => {
            setAddCard(false);
          }}
        />
        <S.InputsContainer>
          <S.Form>
            <InputWithMask
              mask="9999 9999 9999 9999"
              placeholder="Número do Cartão"
              type="text"
              value={card.cardNumber}
              handleChange={handleChange}
              error={card.error}
              name="cardNumber"
              required={true}
            />
            <Input
              placeholder="Nome no cartão"
              type="text"
              value={card.cardName}
              handleChange={handleChange}
              error={card.error}
              name="cardName"
              required={true}
            />
            <S.HalfInputContainer>
              <S.CVVContainer>
                <InputWithMask
                  mask="99/99"
                  placeholder="Data de Validade"
                  value={card.cardValidate}
                  handleChange={handleChange}
                  error={card.error}
                  name="cardValidate"
                  required={true}
                />
              </S.CVVContainer>
              <S.CVVContainer>
                <InputWithMask
                  mask="999"
                  placeholder="CVV"
                  type="text"
                  value={card.cardCVV}
                  handleChange={handleChange}
                  error={card.error}
                  name="cardCVV"
                  required={true}
                />
                <p onClick={() => setCvvTip(true)}>O que é isso?</p>
              </S.CVVContainer>
            </S.HalfInputContainer>
            {card.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>

          <S.ButtonContainer>
            {!loading && (
              <Button
                disabled={
                  card.cardCVV.length <= 0 ||
                  card.cardValidate.length <= 0 ||
                  card.cardName.length <= 0 ||
                  card.cardNumber.length <= 2
                }
                handleClick={handleRegister}
              >
                Continuar
              </Button>
            )}
            {loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
      </S.CadastroContainer>
      {cvvTip && (
        <Modal onClick={() => setCvvTip(false)}>
          É muito fácil de achar o CVV do cartão, pois fica na parte traseira
          dele. Mas, atenção para não confundí-lo com o número do cartão, data
          de validade (mm/aa) e outras informações. O CVV tem apenas três
          dígitos, sem espaço, tamanho menor e, em alguns casos, pode estar
          impresso em itálico. Os demais conjuntos numéricos apresentam
          características diferentes, como fonte maior, barras ou espaços.
        </Modal>
      )}
    </>
  );
};

export default CardPaymentForm;
