import React from 'react';

import { ReactComponent as DropdownIcon } from 'assets/common/select.svg';

import * as S from './styled';

const SelectGrey = ({
  disabled,
  hide = false,
  value,
  optionList = [],
  placeholder,
  error,
  handleChange,
  name,
  required,
}) => {
  return (
    <S.SelectContainer>
      <S.Select disabled={disabled} value={value} onChange={(e) => handleChange(e.target)} name={name} error={error}>
        {placeholder && <option value="">{placeholder}</option>}
        {optionList.map((element, index) => {
          return (
            <option key={index} value={element[name]}>
              {element[name]}
            </option>
          );
        })}
      </S.Select>
      {(!value || hide) && (
        <S.DropdownIcon>
          <DropdownIcon />
        </S.DropdownIcon>
      )}
      {required && <S.Required>*</S.Required>}
    </S.SelectContainer>
  );
};

export default SelectGrey;
