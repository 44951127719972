import SlideCircleItem from 'components/SlideCircle';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

import * as S from './styled';

const CircleSlider = ({ list, title, live }) => {
  return (
    <S.SwiperContainer live={live}>
      <S.SwiperTitle>{title}</S.SwiperTitle>
      <Swiper spaceBetween={30} slidesPerView="auto" style={{ overflow: 'visible', paddingRight: 20 }}>
        {list.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ width: 102 }}>
              <SlideCircleItem item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </S.SwiperContainer>
  );
};

export default CircleSlider;
