import styled from 'styled-components';

import * as C from '../../constants/styles/colors';
import * as T from '../../constants/styles/typography';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 143px;
`;
export const ImageContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  background-color: gold;

  img {
    width: 100%;
  }
`;

export const BlueContainer = styled.div`
  background-color: ${C.SECONDARY_COLOR};
  padding: 45px 50px 80px;
  color: white;

  h2 {
    margin-bottom: 30px;
    text-transform: uppercase;
    ${T.TITLE_EXTRA_LARGE}
  }

  p {
    line-height: 22px;
    ${T.BODY_TEXT_MEDIUM};
    font-weight: 400;
  }
`;

export const IconsContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 40px 0px 15px;
`;
export const IconsItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    height: 43px;
  }

  p {
    margin-top: 16px;
    ${T.BODY_TEXT_MEDIUM};
    font-weight: 500;
  }
`;

export const HandContainer = styled.div`
  position: relative;
  padding-bottom: 120px;
  margin-bottom: 65px;

  .handImgContainer {
    overflow: hidden;
    z-index: -1;
    img {
      left: calc(50% + 20px);
      transform: translateX(-50%);
      position: relative;
    }
  }
`;
export const PinkContainer = styled.div`
  background-color: ${C.PRIMARY_COLOR};
  padding: 45px 50px 58px;
  color: white;
  position: absolute;
  width: 100%;
  top: 390px;

  p {
    line-height: 22px;
    ${T.BODY_TEXT_MEDIUM};
    font-weight: 400;
  }
`;

export const WhiteContainer = styled.div`
  padding: 0px 50px 42px;

  h2 {
    color: ${C.SECONDARY_COLOR};
    margin-bottom: 30px;
    text-transform: uppercase;
    ${T.TITLE_EXTRA_LARGE}
  }

  p {
    line-height: 22px;
    ${T.BODY_TEXT_MEDIUM};
    font-weight: 400;
  }
`;

export const HandContainerFooter = styled.div`
  position: relative;

  .handImgContainer {
    overflow: hidden;
    z-index: -1;

    img {
      left: calc(50% + 20px);
      transform: translateX(-50%);
      position: relative;
    }
  }

  .footer {
    position: absolute;
    top: 390px;
  }
`;
