import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from 'services/api';

export const LiveContext = createContext();

export const LiveProvider = ({ children }) => {
  const [lives, setLives] = useState({});
  const [liveDetails, setLiveDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getOneByOne = async (type) => {
    switch (type) {
      case 'getOnAir':
        const getOnAir = await api.get('/events/getOnAir');
        return getOnAir;
      case 'getUpComing':
        const getUpComing = await api.get('/events/getUpComing');
        return getUpComing;
      case 'getHighlights':
        const getHighlights = await api.get('/events/getHighlights');
        return getHighlights;
      case 'getDiscovery':
        const getDiscovery = await api.get('/events/getDiscovery');
        return getDiscovery;
      case 'getByCategory':
        const getByCategory = await api.get('/events/getByCategory');
        return getByCategory;
      case 'getByShop':
        const getByShop = await api.get('/events/getByShop');
        return getByShop;
      default:
        break;
    }
  };

  const getLives = useCallback(async () => {
    setIsLoading(true);
    let getOnAir = null;
    let getUpComing = null;
    let getHighlights = null;
    let getByCategory = null;
    let getDiscovery = null;
    let getByShop = null;

    try {
      getOnAir = await getOneByOne('getOnAir');
    } catch (err) {
      console.error(err);
    }

    try {
      getUpComing = await getOneByOne('getUpComing');
    } catch (err) {
      console.error(err);
    }

    try {
      getHighlights = await getOneByOne('getHighlights');
    } catch (err) {
      console.error(err);
    }

    try {
      getByCategory = await getOneByOne('getByCategory');
    } catch (err) {
      console.error(err);
    }

    try {
      getDiscovery = await getOneByOne('getDiscovery');
    } catch (err) {
      console.error(err);
    }

    try {
      getByShop = await getOneByOne('getByShop');
    } catch (err) {
      getByShop = null;
      console.error(err);
    }

    setLives({
      getOnAir: getOnAir?.data.content,
      getUpComing: getUpComing?.data.content,
      getHighlights: getHighlights?.data.content,
      getByCategory: getByCategory?.data.content,
      getDiscovery: getDiscovery?.data.content,
      getByShop: getByShop?.data.content,
    });

    setIsLoading(false);
  }, []);

  useEffect(() => {
    getLives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store = {
    isLoading,
    lives,
    liveDetails, 
    setLiveDetails
  };

  return <LiveContext.Provider value={store}>{children}</LiveContext.Provider>;
};

export const useLiveContext = () => {
  const context = useContext(LiveContext);
  if (context === undefined) {
    throw new Error('useLiveContext must be used within a LiveProvider');
  }
  return context;
};
