import styled from 'styled-components';

export const SearchBar = styled.input`
  background: #f4f4f4;
  border-radius: 25px;
  width: 100%;
  padding: 11px 15px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #aaaaaa;
`;
