import styled from "styled-components";
import { calcRem } from "constants/styles/typography";
import { Text as TextStyled } from "../Text/styled";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.WHITE};
  height: ${({ height }) => height || "83px"};
  width: ${({ width }) => width || "100%"};

  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 14px 14px;
`;

export const Text = styled(TextStyled)`
  font-size: ${({ theme }) => theme.typography.SIZES.sml};
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.WHITE};

  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }
`;
