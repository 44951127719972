import styled from 'styled-components';

export const SocialLogin = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  margin-bottom: 78px;

  button > div {
    width: 23.26px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
