import React, { useEffect, useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Button from 'components/Button';

import * as S from './styled';
import ErrorMessage from 'components/ErrorMessage';
import { useParams, useHistory } from 'react-router-dom';
import Loader from 'components/Loader';

import { ReactComponent as CheckIcon } from '../../assets/common/check.svg';

const Verificacao = () => {
  const [data, setData] = useState({
    type: '',
    id: '',
  });
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const params = useParams();
  const history = useHistory();

  const handleCode = () => {
    const isError = handleError();
    if (!isError) {
      setError(false);
      history.push('/conta/localizacao');
    } else {
      setError(true);
    }
  };

  const handleError = () => {
    // chamada de validação

    const response = code === '123456';
    if (response) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setData(params);
  }, [params]);

  const handleResend = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSent(true);
    }, 2000);
  };

  return (
    <div>
      <BackBar type="back" path="/cadastrar" shadow={true} text="Voltar" />
      <S.TitleContainer>
        <Title>Verificação de {data.type === 'email' ? 'Email' : 'Celular'}</Title>
      </S.TitleContainer>
      <S.Text margin="12px auto 33px" /* onClick={() => setRecoverPassword(true)} */>
        Aguarde, você receberá um e-mail com um código para verificação.
      </S.Text>
      <S.InputsContainer>
        <S.Form>
          <Input
            placeholder="Código de verificação"
            type="number"
            value={code}
            handleChange={(e) => setCode(e.value)}
            error={error}
            name="code"
          />
          {error && <ErrorMessage>Código de verificação incorrreto.</ErrorMessage>}
        </S.Form>

        <Button disabled={code.length <= 5} handleClick={handleCode}>
          Continuar
        </Button>
        {!loading && !sent && (
          <>
            <S.Text margin="25px 0px 5px" /* onClick={() => setRecoverPassword(true)} */>Não recebeu o e-mail?</S.Text>
            <S.Text margin="0px 0px 0px" onClick={() => handleResend()}>
              <span>Para reenviar, clique aqui</span>
            </S.Text>
          </>
        )}
        {loading && !sent && (
          <S.LoaderContainer>
            <Loader />
          </S.LoaderContainer>
        )}
        {!loading && sent && (
          <S.CheckSent margin="25px 0px" /* onClick={() => setRecoverPassword(true)} */>
            <CheckIcon />
            {data.type === 'email' ? 'Email' : 'SMS'} reenviado
          </S.CheckSent>
        )}
      </S.InputsContainer>
      <Footer showTop={true} />
    </div>
  );
};

export default Verificacao;
