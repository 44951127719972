import React from 'react';

import * as S from './styled';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import ButtonClipBoard from '../../../components/Button/clipBoardButton.jsx';
import { ReactComponent as WhatsappIcon } from 'assets/social/blue-whatsapp.svg';
import { ReactComponent as FacebookIcon } from 'assets/social/blue-facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/social/blue-twitter.svg';
import { ReactComponent as EmailIcon } from 'assets/social/blue-email.svg';
import Text from 'components/Text';

const Share = ({ selected }) => {
  return (
    <S.ShareContainer>
      <WhatsappShareButton url={`Vem assistir a live! app.ugong.com.br/live/${selected.shop.nameURL}/${selected.protoId}`}>
        <div>
          <WhatsappIcon />
        </div>
        <Text as="p">Whatsapp</Text>
      </WhatsappShareButton>
      <FacebookShareButton
        url={'ugong.com.br'}
        quote={`Vem assistir a live! app.ugong.com.br/live/${selected.shop.nameURL}/${selected.protoId} #ugong`}
        hashtag={'#ugong'}
      >
        <div>
          <FacebookIcon />
        </div>
        <Text as="p">Facebook</Text>
      </FacebookShareButton>
      <TwitterShareButton
        url={`Vem assistir a live! app.ugong.com.br/live/${selected.shop.nameURL}/${selected.protoId}`}
        quote={'Venha assistir a live na Ugong'}
        hashtag={'#ugong'}
      >
        <div>
          <TwitterIcon />
        </div>
        <Text as="p">Twitter</Text>
      </TwitterShareButton>
      <EmailShareButton
        body={`Vem assistir a live! app.ugong.com.br/live/${selected.shop.nameURL}/${selected.protoId} #ugong`}
        subject={'Live Ugong'}
        quote={'Venha assistir a live na Ugong'}
        hashtag={'#ugong'}
      >
        <div>
          <EmailIcon />
        </div>
        <Text as="p">E-mail</Text>
      </EmailShareButton>
      <ButtonClipBoard text={`${window.location.hostname}/live/${selected.shop.nameURL}/${selected.protoId}`} />
    </S.ShareContainer>
  );
};

export default Share;
