import styled from 'styled-components';

export const SlideItem = styled.div`
  width: 156px;
  height: 101px;
  border-radius: 11.6453px;

  background-image: ${({ thumb }) => thumb ? `url(${thumb})` : 'transparent'};
  background-size: cover;
  background-position-y: 50%;


  position: relative;
  cursor: pointer;
  
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerSlideItemTitle = styled.div`
  position: absolute;
  bottom: 4px;
  right: 10px;
  width: 70%;
  text-align: right;
`;

export const SlideItemTitle = styled.p`
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 1px 1px 4px black;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.32px;
`;
