import React, { useState, useEffect, useMemo } from 'react';
import * as S from './styled';

import { ReactComponent as Logo } from 'assets/logos/icon.svg';
import { ReactComponent as User } from 'assets/user/avatar.svg';
import SearchBar from 'containers/SearchBar';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useAuthContext } from 'contexts/AuthContext';

const Header = () => {
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [user, setUser] = useState({});
  const context = useAuthContext();

  useEffect(() => {
    if (!location.search) {
      setSearch('');
    } else {
      setSearch(qs.parse(location.search, { ignoreQueryPrefix: true }).search);
    }
  }, [location]);

  useEffect(() => {
    setUser(context.user);
  }, [context]);

  return (
    <S.Header>
      <Link to="/">
        <Logo />
      </Link>
      <SearchBar
        placeholder="Buscar"
        type="text"
        value={search}
        handleChange={(e) => setSearch(e.value)}
        name="search"
        pathname={location.pathname}
      />
      <Link to={!user?._id ? '/menu' : '/perfil'}>
        {!user?.avatar ? <User /> : <S.Avatar src="" alt="" background={user?.avatar ? user?.avatar : ''} />}
      </Link>
    </S.Header>
  );
};

export default Header;
