import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';
import { Link } from 'react-router-dom';

export const BackBar = styled.div`
  padding: 22px 20px;
  background: ${({ shadow }) => {
    return shadow ? 'linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%)' : 'white';
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  ${T.BODY_TEXT_MEDIUM};
  margin-left: 12px;
  font-weight: 600;
  color: ${C.TEXT_PRIMARY_COLOR};
`;

export const Cart = styled(Link)`
  display: flex;
  align-items: center;

  p {
    color: #8a8a8a;
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 10px;
  }
`;
