import React from 'react';

import Text from 'components/V2/Text';
import * as S from './styled';

const TrackingPackage = ({
  createdAt = null,
  payAt = null,
  notPayed = false,
  preparedAt = null,
  sendedAt = null,
  recivedAt = null,
}) => {
  const rows = [
    {
      label: 'Pedido Confirmado',
      date: createdAt,
      color: 'sucess',
    },
    {
      label: notPayed ? 'Pagamento não autorizado' : 'Pagamento Autorizado',
      date: payAt,
      color: notPayed ? 'error' : 'sucess',
    },
    {
      label: 'Preparando para envio',
      date: preparedAt,
      color: 'sucess',
    },
    {
      label: 'Enviado',
      date: sendedAt,
      color: 'sucess',
    },
    {
      label: 'Recebido',
      date: recivedAt,
      color: 'sucess',
    },
  ];

  return (
    <S.Wrap>
      {rows.map((item, index) => (
        <S.Step key={index}>
          <S.StepItem
            active={item.date !== null}
            error={item.color === 'error'}
          >
            {index + 1}
          </S.StepItem>
          <S.WrapText>
            <Text type="cardTitle">{item.label}</Text>
            <S.WrapDescription>
              <Text type="cardTitle">{item.date || 'aguardando'}</Text>
            </S.WrapDescription>
          </S.WrapText>
        </S.Step>
      ))}
    </S.Wrap>
  );
};

export default TrackingPackage;
