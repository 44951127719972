import React from 'react'
import PropTypes from 'prop-types'
import icons from './files'

const IconComponent = ({ icon }) => {
  const Component = icons[icon]

  if (!Component) return <></>

  return <Component />
}

export default IconComponent
