import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/common/edit.svg';

import * as S from './styled';
import api from 'services/api';
import Button from 'components/V2/Button';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Modal from 'components/Modal';
import Title from 'components/Title';
import HeaderCheckout from 'components/HeaderCheckout';
import {
  ProductCarrinho,
  ProductCarrinhoContent,
  RemoveButton,
} from 'pages/Empresa/AdicionarEvento/Steps/Produto/styled';
import IconComponent from 'components/V2/Icon';
import ProductPagamento from 'containers/ProductPagamento';

const EditarLive = () => {
  const params = useParams();
  const history = useHistory();
  const eventId = params.id;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await api.get('/events/getStuff', {
        params: { _id: eventId },
      });
      setProducts(response.data.content.productsDetails);
      setLoading(false);
    };
    getProducts();
  }, []);

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await api.delete('/products/delete', {
        params: { _id: productId },
      });
      if (response.data.content == 1) {
        const newProducts = products.filter(
          (product) => product._id !== productId
        );
        setProducts(newProducts);
        setShowConfirmation(false);
        setShowModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRedirectToEditProduct = (productId) => {
    try {
      history.push(`/edit-event/${eventId}/product/edit/${productId}`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleConfirmationDelete = (productId) => {
    setShowConfirmation(true);
    setShowModal(true);
    setCurrentProduct(productId);
  };

  const handleRedirectToAddProduct = () => {
    try {
      history.push(`/edit-event/${eventId}/product/add`);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <HeaderCheckout
        backAction={() => {
          history.push(`/edit-event/${eventId}`);
        }}
        title="Editar Produto"
        icon="packageEdit"
      />
      <S.Wrapper>
        <S.EditContainer>
          {loading && <Loader />}
          {!loading &&
            products.length > 0 &&
            products.map((el, index) => {
              return el.variations.map((product, indexEl) => {
                return (
                  <ProductCarrinho key={`${index}-${indexEl}`}>
                    <ProductCarrinhoContent>
                      <ProductPagamento
                        product={{
                          ...el,
                          _id: el._id,
                          selectedColor: product.color,
                          selectedSize: product.size,
                          selectedQuantity: 1,
                          hasVariation:
                            product.color != '' || product.size != '',
                        }}
                        canEdit={false}
                      />
                      <RemoveButton
                        onClick={() => {
                          handleRedirectToEditProduct(el._id);
                        }}
                      >
                        Editar
                        <IconComponent icon="pencilBackground" />
                      </RemoveButton>
                    </ProductCarrinhoContent>
                  </ProductCarrinho>
                );
              });
            })}
          {!loading && (
            <Button icon="add" handleClick={() => handleRedirectToAddProduct()}>
              Adicionar Produto
            </Button>
          )}
        </S.EditContainer>
      </S.Wrapper>

      <Footer showTop={true} />
    </>
  );
};

export default EditarLive;
