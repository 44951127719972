import { ReactComponent as add } from 'assets/iconSets/add.svg'
import { ReactComponent as arrowBack } from 'assets/iconSets/arrowBack.svg'
import { ReactComponent as arrowNext } from 'assets/iconSets/arrowNext.svg'
import { ReactComponent as arrowLeft } from 'assets/iconSets/arrowLeft.svg'
import { ReactComponent as arrowRight } from 'assets/iconSets/arrowRight.svg'
import { ReactComponent as selectBottom } from 'assets/iconSets/selectBottom.svg'
import { ReactComponent as cam } from 'assets/iconSets/cam.svg'
import { ReactComponent as cancel } from 'assets/iconSets/cancel.svg'
import { ReactComponent as cartCancel } from 'assets/iconSets/cartCancel.svg'
import { ReactComponent as cartDiscount } from 'assets/iconSets/cartDiscount.svg'
import { ReactComponent as cartFull } from 'assets/iconSets/cartFull.svg'
import { ReactComponent as cartOutline } from 'assets/iconSets/cartOutline.svg'
import { ReactComponent as cartOutline2 } from 'assets/iconSets/cartOutline2.svg'
import { ReactComponent as check } from 'assets/iconSets/check.svg'
import { ReactComponent as checked } from 'assets/iconSets/checked.svg'
import { ReactComponent as creditCard } from 'assets/iconSets/creditCard.svg'
import { ReactComponent as creditCardAdd } from 'assets/iconSets/creditCardAdd.svg'
import { ReactComponent as facebook } from 'assets/iconSets/facebook.svg'
import { ReactComponent as like } from 'assets/iconSets/like.svg'
import { ReactComponent as pencil } from 'assets/iconSets/pencil.svg'
import { ReactComponent as pencilBackground } from 'assets/iconSets/pencilBackground.svg'
import { ReactComponent as location } from 'assets/iconSets/location.svg'
import { ReactComponent as pix } from 'assets/iconSets/pix.svg'
import { ReactComponent as pixLogo } from 'assets/iconSets/pixLogo.svg'
import { ReactComponent as question } from 'assets/iconSets/question.svg'
import { ReactComponent as refresh } from 'assets/iconSets/refresh.svg'
import { ReactComponent as home } from 'assets/iconSets/home.svg'
import { ReactComponent as safe } from 'assets/iconSets/safe.svg'
import { ReactComponent as save } from 'assets/iconSets/save.svg'
import { ReactComponent as send } from 'assets/iconSets/send.svg'
import { ReactComponent as star } from 'assets/iconSets/star.svg'
import { ReactComponent as starFull } from 'assets/iconSets/starFull.svg'
import { ReactComponent as stream } from 'assets/iconSets/stream.svg'
import { ReactComponent as whatsapp } from 'assets/iconSets/whatsapp.svg'
import { ReactComponent as sum } from 'assets/iconSets/sum.svg'
import { ReactComponent as minor } from 'assets/iconSets/minor.svg'
import { ReactComponent as calendar } from 'assets/iconSets/calendar.svg'
import { ReactComponent as calendarCheck } from 'assets/iconSets/calendarCheck.svg'
import { ReactComponent as calendarPlus } from 'assets/iconSets/calendarPlus.svg'
import { ReactComponent as share } from 'assets/iconSets/share.svg'
import { ReactComponent as shareMore } from 'assets/iconSets/shareMore.svg'
import { ReactComponent as bag } from 'assets/iconSets/bag.svg'
import { ReactComponent as exit } from 'assets/iconSets/exit.svg'
import { ReactComponent as userEdit } from 'assets/iconSets/userEdit.svg'
import { ReactComponent as userGo } from 'assets/iconSets/userGo.svg'
import { ReactComponent as list } from 'assets/iconSets/list.svg'
import { ReactComponent as linkBack } from 'assets/iconSets/linkBack.svg'
import { ReactComponent as fileEdit } from 'assets/iconSets/fileEdit.svg'
import { ReactComponent as selectRight } from 'assets/iconSets/selectRight.svg'
import { ReactComponent as truck } from 'assets/iconSets/truck.svg'
import { ReactComponent as shop } from 'assets/iconSets/shop.svg'
import { ReactComponent as change } from 'assets/iconSets/change.svg'
import { ReactComponent as company } from 'assets/iconSets/company.svg'
import { ReactComponent as packagePlus } from 'assets/iconSets/packagePlus.svg'
import { ReactComponent as packageEdit } from 'assets/iconSets/packageEdit.svg'
import { ReactComponent as copy } from 'assets/iconSets/copy.svg'
import { ReactComponent as play } from 'assets/iconSets/play.svg'
import { ReactComponent as transmission } from 'assets/iconSets/transmission.svg'
import { ReactComponent as transmissionClose } from 'assets/iconSets/transmissionClose.svg'

import { ReactComponent as visa } from 'assets/iconSets/visa.svg'
import { ReactComponent as mastercard } from 'assets/iconSets/mastercard.svg'
import { ReactComponent as amex } from 'assets/iconSets/amex.svg'
import { ReactComponent as elo } from 'assets/iconSets/elo.svg'

const icons = {
  add,
  arrowBack,
  arrowLeft,
  arrowNext,
  arrowRight,
  bag,
  calendar,
  calendarCheck,
  calendarPlus,
  fileEdit,
  cam,
  cancel,
  cartCancel,
  cartDiscount,
  cartFull,
  cartOutline,
  cartOutline2,
  change,
  check,
  checked,
  company,
  copy,
  creditCard,
  creditCardAdd,
  exit,
  facebook,
  home,
  like,
  linkBack,
  list,
  location,
  minor,
  packageEdit,
  packagePlus,
  pencil,
  pencilBackground,
  pix,
  pixLogo,
  play,
  question,
  refresh,
  safe,
  save,
  selectBottom,
  selectRight,
  send,
  share,
  shareMore,
  shop,
  star,
  starFull,
  stream,
  sum,
  transmission,
  transmissionClose,
  truck,
  userEdit,
  userGo,
  whatsapp,
  visa,
  mastercard,
  amex,
  elo,
}

export default icons
