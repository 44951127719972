import styled from 'styled-components';

export const TextContainer = styled.div`
  line-height: 22px;
  div {
    text-align: justify;
  }
`;

export const TitleContainer = styled.div`
  margin: 0px 0px 38px;
`;
