import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const Select = styled.select`
  border-radius: 10px;
  width: 100%;
  max-width: 279px;
  padding: 19px 0px;
  text-align: center;
  text-align: -webkit-center;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  border: 2px solid ${({ error }) => (error ? C.INPUT_ERROR_COLOR : '#F4F4F4')};
  background: #f4f4f4;
  color: ${({ value }) => (!value ? '#a9a9a9' : C.SECONDARY_COLOR)};
  font-weight: ${({ value }) => (!value ? '400' : '600')};
  text-align-last: center;
  -moz-text-align-last: center;
`;

export const SelectContainer = styled.div`
  position: relative;
`;

export const DropdownIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;
