import React, { useState } from 'react'

import * as S from './styled'
import IconComponent from '../Icon'
import Text from 'components/V2/Text'
import Button from 'components/V2/Button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'
import api from 'services/api';

const types = {
  info: {
    color: 'secondary',
  },
  confirmExclusion: {
    color: 'primary',
  },
  error: {
    color: 'primary',
  },
}

const Modal = ({
  isOpen = false,
  setIsOpen,
  icon = 'check',
  title,
  bodyMessage,
  type = 'info',
  renderButtons,
  liveId,

}) => {
  const [event, setEvent] = useState({})

  useEffect(() => {
    const getEvent = async () => {
      if (liveId) {
        if(!event?.shop?.nameURL && !event.protoId){
          const response = await api.get('/events/getStuff', {
            params: { _id: liveId },
          })
          setEvent(response.data.content)  
        }
      }
    }
    getEvent()
  }, [liveId])

  useEffect(() => {
    if (document) {
      if (isOpen) {
        document.querySelector('body').style.overflowY = 'hidden'
        document.querySelector('body').style.height = '100vh'
      } else {
        document.querySelector('body').style.overflowY = ''
        document.querySelector('body').style.height = ''
      }
    }

    return () => {
      document.querySelector('body').style.overflowY = ''
      document.querySelector('body').style.height = ''
    }
  }, [isOpen])

  if (!isOpen) return <></>
  return (
    <S.Outside>
      <S.Modal>
        <S.Button onClick={() => setIsOpen(false)}>
          <IconComponent icon="cancel" />
        </S.Button>

        <S.WrapIcon color={types[type].color} icon={icon}>
          <IconComponent icon={icon} />
        </S.WrapIcon>

        <div>
          <Text type="title">{title}</Text>
          <br />
          <br />
          <Text type="body">{bodyMessage}</Text>
        </div>

        <S.WrapButton>
          {liveId ? (
            <Link to={`/live/${event.shop.nameURL}/${event.protoId}`}>
              <Button icon="linkBack">Voltar para a Live</Button>
            </Link>
          ) : null}
          {renderButtons && renderButtons()}
        </S.WrapButton>
      </S.Modal>
    </S.Outside>
  )
}

export default Modal
