import styled from 'styled-components'

export const AddressContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 1em 1em 65px 1em;

  button {
    width: 100%;
  }
`

export const TitleContainer = styled.div`
  margin: 33px 0px;
  display: flex;
  justify-content: center;
  gap: 10px;

  svg {
    width: 24px;
    object-fit: cover;
  }
`

export const AddressAddItem = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 279px;
  height: 113px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddressCard = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 279px;

  position: relative;

  margin: auto;
  margin-bottom: 27px;
  padding: 40px 30px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  p {
    margin-bottom: 20px;
    font-weight: 400;
    text-align: center;
    font-size: 1.3rem;
  }
`

export const AddButton = styled.div`
  background: #00dfff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  width: 204px;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`

export const RemoveButton = styled.div`
  position: absolute;
  top: 15px;
  right: -30px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;

  cursor: pointer;

  svg {
    margin-left: 5px;
    width: 24px;
    object-fit: cover;

    path {
      stroke: ${({ theme }) => theme.colors.ERROR};
    }
  }
`

export const ButtonFav = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;

  cursor: pointer;

  svg {
    width: 24px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 50%;

    path {
      fill: ${({ theme, favorite }) =>
        !favorite ? theme.colors.GREY_1 : theme.colors.SECONDARY_COLOR};
    }
  }
`

export const ButtonEdit = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
  width: 30px;

  cursor: pointer;

  svg {
    width: 24px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 50%;
    object-fit: cover;

    path {
      fill: ${({ theme }) => theme.colors.SECONDARY_COLOR};
    }
  }
`
