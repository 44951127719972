import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0 16px;
  margin: auto;

  @media screen and (min-width: 992px) {
    width: 1000px;
  }
`;
