import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const LiveLabel = styled.div`
  background-color: ${({ background }) => (background === 'pink' ? C.PRIMARY_COLOR : C.WHITE)};
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: ${({ padding }) => (padding ? padding : '4px 14px')};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  position: absolute;

  p {
    color: ${({ background }) => (background === 'pink' ? C.WHITE : C.PRIMARY_COLOR)};
    text-transform: uppercase;
    font-size: ${({ textSize }) => textSize};
    font-weight: 800;
  }
`;
