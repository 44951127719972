import React, { useEffect, useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Button from 'components/Button';

import * as S from './styled';
import { useHistory } from 'react-router-dom';

import Text from 'components/Text';

const Categorias = () => {
  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const history = useHistory();

  const handleNext = () => {
    if (selectedCategories.length > 0) {
      history.push('/conta/done');
    }
  };

  const handleLogoff = () => {};

  useEffect(() => {
    // puxar categorias

    setData(['Fashion', 'Beleza', 'Viagem', 'Higiene', 'Mobile', 'Hardware']);
  }, []);

  const handleSelect = (e) => {
    e.classList.toggle('selected');

    const newData = [...selectedCategories];
    const filter = selectedCategories.filter((el) => el === e.innerHTML);
    const hasClass = e.classList.contains('selected');

    if (filter.length <= 0) {
      newData.push(e.innerHTML);
      setSelectedCategories(newData);
    } else if (!hasClass) {
      const index = newData.indexOf(e.innerHTML);
      newData.splice(index, 1);
      setSelectedCategories(newData);
    }
  };

  return (
    <>
      <BackBar type="back" path="/cadastrar" shadow={true} text="Voltar" onClick={() => handleLogoff()} />
      <S.Container>
        <S.TitleContainer>
          <Title>Categorias</Title>
        </S.TitleContainer>

        <Text>Quais os seus interesses? Selecione pelo menos uma área.</Text>
        <S.CategoryList>
          {data.map((el, index) => {
            return (
              <S.CategoryItem key={index} onClick={(e) => handleSelect(e.target)}>
                {el}
              </S.CategoryItem>
            );
          })}
        </S.CategoryList>
        <Button disabled={selectedCategories.length < 1} handleClick={handleNext}>
          Continuar
        </Button>
      </S.Container>

      <Footer showTop={true} />
    </>
  );
};

export default Categorias;
