import styled from 'styled-components';

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 25px 0px 50px;
  gap: 20px;
`;

export const Concluir = styled.div`
  min-height: 600px;
  padding-bottom: 300px;
`;

export const PagamentoContent = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
`;

export const TextContainer = styled.div`
  margin-bottom: 20px;
  line-height: 2.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 30px;
  width: 80%;
  margin: 0 auto;

  svg {
    margin-right: 10px;
  }
`;

export const GreyBar = styled.div`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  max-width: 279px;
  padding: 25px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  margin: 0 auto 30px;
  text-align: center;

  h5 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;
