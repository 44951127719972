import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const FooterContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const Footer = styled.div`
  background-color: ${C.SECONDARY_COLOR};
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9999;
  ul {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-left: 50px;
  }
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 60px);
  padding: 0px 20px 0px 25px;
  text-shadow: 1px -1px 3px #FFFFFF;

  small, a {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${C.BLACK};
  }
`;

export const UpperButton = styled.div`
  height: 45px;
  width: 60px;
  border-radius: 20px 20px 0px 0px;
  background-color: ${C.SECONDARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`;
