import React from 'react';

import { handleLength } from 'utils/handleRegex';
import { ReactComponent as PinkArrow } from 'assets/common/arrow-pink.svg';

import * as S from './styled';

import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/Text';
import InputWithMask from 'components/InputMask';
import Loader from 'components/Loader';
import api from 'services/api';
import HeaderCheckout from 'components/HeaderCheckout';
import { useHistory } from 'react-router-dom';

const EmpresaDados = ({ dados, setDados, handleChange, setSteps }) => {
  const history = useHistory();

  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setDados({
        ...dados,
        error: true,
      });
    } else {
      try {
        // atualizar usuário

        let body = dados;
        let datetime = new Date();
        datetime.setFullYear(parseInt(body.date.split('/')[2]));
        datetime.setMonth(parseInt(body.date.split('/')[1]) - 1);
        datetime.setDate(parseInt(body.date.split('/')[0]));
        datetime.setHours(parseInt(body.hour.split(':')[0]));
        datetime.setMinutes(parseInt(body.hour.split(':')[1]));
        body.scheduled = datetime;
        body.videoURLs = { youtube: dados.link };
        delete body.link;

        const response = await api.post('/events/create', body);
        if (!response.data.content && !response.data.content._id) throw false;

        setDados({
          ...dados,
          eventId: response.data.content._id,
          error: false,
        });
        setSteps(1);
      } catch (err) {
        console.error(err);
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { hour, date, name, banner, link } = dados;
    if (
      !handleLength(banner, 'bigger', 0) ||
      !handleLength(date, 'bigger', 6) ||
      !handleLength(hour, 'bigger', 4) ||
      !handleLength(name, 'bigger', 2) ||
      !handleLength(link, 'bigger', 10)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdateFile = (e) => {
    // Assuming only image
    let file = e.target.files[0];

    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setDados({
          ...dados,
          banner: reader.result,
        });
      };
    }
  };

  return (
    <>
      <HeaderCheckout
        backAction={() => {
          history.push('/');
        }}
        title="Adicionar evento"
        icon="calendarPlus"
      />
      <S.CadastroContainer className="cadastro-inner-container">
        <S.TextContainer>
          <Text>Preencha os campos abaixo para cadastrar seu novo evento.</Text>
        </S.TextContainer>

        <S.FileInputContainer>
          <S.FileLabel htmlFor="input-avatar">
            {!dados.banner && (
              <div>
                <PinkArrow />
                <Text as="p" className="labeltext">
                  Pressione aqui e selecione uma imagem para o evento.
                </Text>
              </div>
            )}
            {dados.banner && (
              <>
                <S.Avatar background={dados.banner ? dados.banner : ''} />
                <div className="pinkArrow">
                  <PinkArrow />
                </div>
                <S.ContainerSlideItemTitle>
                  <S.SlideItemTitle>{dados.name}</S.SlideItemTitle>
                </S.ContainerSlideItemTitle>
              </>
            )}
          </S.FileLabel>
          <S.FileInput
            type="file"
            id="input-avatar"
            accept="image/png,image/jpeg"
            onChange={(e) => handleUpdateFile(e)}
          />
        </S.FileInputContainer>
        <S.InputsContainer>
          <S.Form>
            <Input
              placeholder="Nome da Live"
              type="text"
              value={dados.name}
              handleChange={handleChange}
              error={dados.error}
              name="name"
              required={true}
            />
            <Input
              placeholder="Link da live (YouTube)"
              type="text"
              value={dados.link}
              handleChange={handleChange}
              error={dados.error}
              name="link"
              required={true}
            />
            <S.HalfInputContainer>
              <InputWithMask
                mask="99/99/9999"
                placeholder="Data"
                value={dados.date}
                handleChange={handleChange}
                error={dados.error}
                name="date"
                required={true}
              />
              <InputWithMask
                mask="99:99"
                placeholder="Hora"
                value={dados.hour}
                handleChange={handleChange}
                error={dados.error}
                name="hour"
                required={true}
              />
            </S.HalfInputContainer>
            {dados.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
          </S.Form>

          <S.ButtonContainer>
            {!dados.loading && (
              <Button
                disabled={
                  !dados.hour ||
                  !dados.date ||
                  !dados.banner ||
                  !dados.name ||
                  !dados.link
                }
                handleClick={handleRegister}
                style={{ background: '#FF3D68' }}
              >
                Continuar
              </Button>
            )}
            {dados.loading && <Loader />}
          </S.ButtonContainer>
        </S.InputsContainer>
      </S.CadastroContainer>
    </>
  );
};

export default EmpresaDados;
