import styled from 'styled-components';

export const UgongPage = styled.div`
  background-color: #ff3d68;
  height: 100vh;
  width: 100vw;
  padding-top: 250px;

  .logo {
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    justify-content: center;
  }
`;
