import { createGlobalStyle } from "styled-components";

import * as T from "./typography";
import * as C from "./colors";

const GlobalStyle = createGlobalStyle`
	* {
    box-sizing: border-box;
		border: none;
		margin: 0;
		padding: 0;
  }

  ul,
  ol {
    list-style: none;
  }

  select,
  input,
  button,
  textarea {
    background: inherit;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    font-family: ${T.PRIMARY_FONT};
    outline: none;
  }

  html {
    font-size: 62.5%
  }

	body {
		font-family: ${T.PRIMARY_FONT};
    color: ${C.TEXT_PRIMARY_COLOR};
    background-color: ${C.WHITE};
  }

	a {
		text-decoration: none;
  }

	img {
		max-width: 100%;
	}

  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
`;
export default GlobalStyle;
