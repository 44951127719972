import styled from 'styled-components';

export const CadastroEmpresa = styled.div`
  height: calc(100% - 140px);
  position: absolute;
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 1em 1em 45px 1em;
`;
