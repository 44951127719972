import { Button } from 'components/Button/styled';
import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 30px;

  svg {
    margin-right: 10px;
  }
`;
export const PagamentoContent = styled.div`
  height: calc(100% - 203px);
  position: absolute;
  overflow: auto;
  width: 100%;
  padding: 0 15px 100px;
`;

export const TextContainer = styled.div`
  margin-bottom: 20px;
  line-height: 2.2rem;
`;

export const InputsContainer = styled.div`
  width: 279px;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GreyBar = styled.div`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  max-width: 279px;
  padding: 25px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  position: relative;

  > p {
    line-height: 2.2rem;
  }

  > div {
    text-align: center;

    h6 {
      color: #00dfff;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
  }

  .paymentAndAddress {
    text-align: left;
    cursor: pointer;

    h5 {
      color: #00dfff;
      font-size: 1.7rem;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 800;
    }

    > p {
      line-height: 2rem;
      text-align: center;
    }

    > div {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #00dfff;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }
`;

export const ChangeButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #00dfff;
  font-weight: 600;
  font-size: 1.1rem;

  cursor: pointer;
`;

export const AddContainer = styled.div`
  width: 279px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  > div > p {
    text-align: left;
  }
`;

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 25px 0px 50px;
  gap: 20px;
`;

export const ContainerFixed = styled.div`
  background: white;
  z-index: 12;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
`;

export const ContentButton = styled.div`
  width: 100%;
  min-height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f4f4f4;
  border-radius: 20px;

  margin-top: 15px;

  position: relative;

  ${({ error, theme }) =>
    error
      ? css`
          border: 1px solid ${theme.colors.PRIMARY_COLOR};
        `
      : ''}

  ${Button} {
    width: 200px;
    height: 37px;
    font-size: 15px;
  }
`;

export const PaymentText = styled.p`
  padding: 15px;
  padding-top: 10px;
  font-size: 15px;
  overflow-wrap: break-word;
`;

export const List = styled.ul`
  list-style: disc;
  padding-left: 15px;

  p {
    padding-left: 10px;
  }
`;

export const WrapLogo = styled.div`
  margin-top: 10px;
  width: 100%;

  svg {
    display: block;
    object-fit: cover;
    width: 50px;
    height: 30px;
    margin: auto;
  }
`;
