import Button from 'components/Button';
import Input from 'components/Input';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

import * as S from './styled';

import { ReactComponent as PlusIcon } from 'assets/common/plus-small-pink.svg';

const Variacoes = ({ setProduto, produto }) => {
  const [mainVar, setMainVar] = useState({
    size: '',
    color: '',
    quantity: '',
  });
  const [altVar, setAltVar] = useState([]);

  const [showPalette, setShowPalette] = useState({
    show: false,
    top: '-200px',
    trueIndex: '',
  });
  const [colors, setColors] = useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
  const [showColors, setShowColors] = useState({
    show: false,
    index: '',
    selectedColor: '',
  });

  const node = useRef();

  const handlePalette = (index, trueIndex) => {
    const top = Number(index) * (56 + 10) - 300;

    setShowPalette({
      show: true,
      top: top + 'px',
      trueIndex,
    });
  };

  const handleColor = () => {
    const index = colors.indexOf('');

    setShowColors({
      ...showColors,
      show: true,
      index,
    });
  };

  const handleChangeColor = () => {
    const copyColors = [...colors];

    copyColors[showColors.index] = showColors.selectedColor;

    setColors(copyColors);

    setShowColors({
      ...showColors,
      show: false,
    });
  };

  const setColorOnContainer = (color) => {
    const index = showPalette.trueIndex;
    if (index === 'main') {
      setMainVar({
        ...mainVar,
        color,
      });
    } else {
      const dataCopy = [...altVar];
      dataCopy[index] = {
        ...dataCopy[index],
        color,
      };

      setAltVar(dataCopy);
    }
    setShowPalette({
      ...showPalette,
      show: false,
    });
  };

  const removeColor = () => {
    const index = showPalette.trueIndex;
    if (index === 'main') {
      setMainVar({
        ...mainVar,
        color: '',
      });
    } else {
      const dataCopy = [...altVar];
      dataCopy[index] = {
        ...dataCopy[index],
        color: '',
      };

      setAltVar(dataCopy);
    }
    setShowPalette({
      ...showPalette,
      show: false,
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    if (!node.current?.contains(e.target)) {
      setShowPalette({
        ...showPalette,
        show: false,
      });
      setShowColors({
        ...showColors,
        show: false,
      });
    }
  };

  const handleAddVariacao = () => {
    setAltVar([
      ...altVar,
      {
        size: '',
        color: '',
        quantity: '',
      },
    ]);
  };

  const handleChangeVariacao = (index, type, value) => {
    const dataCopy = [...altVar];
    dataCopy[index] = {
      ...dataCopy[index],
      [type]: value,
    };

    setAltVar(dataCopy);
  };

  const handleRemoveVar = (index) => {
    const dataCopy = [...altVar];

    dataCopy.splice(index, 1);

    setAltVar(dataCopy);
  };

  useEffect(() => {
    setProduto((old) => {
      return {
        ...old,
        variations: [mainVar, ...altVar],
      };
    });
  }, [mainVar, altVar, setProduto]);

  return (
    <S.Variacoes>
      <p>Variações</p>
      {showPalette.show && (
        <S.Palette top={showPalette.top} ref={node}>
          <p>Cores padrão</p>
          <S.PaletteBallsContainer>
            {colors.map((el, index) => {
              return <S.PaletteBalls key={index} background={el} onClick={() => setColorOnContainer(colors[index])} />;
            })}
            {showColors.show && (
              <S.ColorPickerContainer>
                <ChromePicker
                  color={showColors.selectedColor}
                  disableAlpha
                  onChangeComplete={(e) => setShowColors({ ...showColors, selectedColor: e.hex })}
                />
                <S.Button
                  style={{
                    marginTop: 10,
                    background: `${showColors.selectedColor ? showColors.selectedColor : 'rgb(255, 61, 104)'}`,
                    padding: '10px 0px',
                  }}
                  onClick={handleChangeColor}
                >
                  Adicionar cor
                </S.Button>
              </S.ColorPickerContainer>
            )}
          </S.PaletteBallsContainer>
          <p style={{ marginTop: 10 }}>Nova cor</p>
          <S.PaletteBallsContainer>
            <div onClick={handleColor}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#FF3D68" />
                <path d="M21 17.6H17.18V21.74H14.92V17.6H11.08V15.52H14.92V11.4H17.18V15.52H21V17.6Z" fill="white" />
              </svg>
            </div>
          </S.PaletteBallsContainer>
          <Button style={{width: '100%', height: '30px', background: '#FF3D68', marginTop: 20}} handleClick={() => removeColor()}>Remover Cor</Button>
        </S.Palette>
      )}
      <S.InputsContainer>
        <S.HalfInputContainer>
          <Input
            placeholder="Tamanho"
            type="text"
            value={mainVar.size}
            handleChange={(e) => setMainVar({ ...mainVar, size: e.value })}
            name="size"
          />
          <S.ColorContainer onClick={() => handlePalette(0, 'main')}>
            {mainVar.color.length <= 0 && <p>Cor</p>}
            {mainVar.color.length > 0 && <S.PaletteBalls background={mainVar.color} />}
          </S.ColorContainer>
          <Input
            placeholder="Quantidade"
            type="text"
            value={mainVar.quantity}
            required={true}
            handleChange={(e) => setMainVar({ ...mainVar, quantity: e.value })}
            name="quantity"
            error={produto.error.quantity}
          />
        </S.HalfInputContainer>
      </S.InputsContainer>
      {altVar.length > 0 &&
        altVar.map((el, index) => {
          return (
            <S.InputsContainer key={index}>
              <S.HalfInputContainer>
                <Input
                  placeholder="Tamanho"
                  type="text"
                  value={altVar[index].size}
                  handleChange={(e) => handleChangeVariacao(index, 'size', e.value)}
                  name="size"
                />
                <S.ColorContainer onClick={() => handlePalette(index + 1, index)}>
                  {altVar[index].color.length <= 0 && <p>Cor</p>}
                  {altVar[index].color.length > 0 && <S.PaletteBalls background={altVar[index].color} />}
                </S.ColorContainer>
                <Input
                  placeholder="Quantidade"
                  type="text"
                  value={altVar[index].quantity}
                  handleChange={(e) => handleChangeVariacao(index, 'quantity', e.value)}
                  name="quantity"
                />
              </S.HalfInputContainer>
              <p onClick={() => handleRemoveVar(index)}>remover</p>
            </S.InputsContainer>
          );
        })}
      <S.AddProduto onClick={handleAddVariacao}>
        <PlusIcon />
        <p>Adicionar Variação</p>
      </S.AddProduto>
    </S.Variacoes>
  );
};

export default Variacoes;
