import React from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';

import * as S from './styled';

import AnchorButton from 'components/AnchorButton';

const Done = () => {
  return (
    <>
      <BackBar type="back" path="/conta/categorias" shadow={true} text="Voltar" />
      <S.Container>
        <S.TitleContainerDone>
          <Title>Conta criada com sucesso</Title>
        </S.TitleContainerDone>
        <AnchorButton text="Descubra" background="primary" disabled={false} path="/" />
      </S.Container>

      <Footer showTop={true} />
    </>
  );
};

export default Done;
