import { Button } from 'components/V2/Button/styled';
import styled from 'styled-components';

export const CarteiraContainer = styled.div`
  min-height: 500px;
  width: 279px;
  max-width: 100%;
  margin: 0px auto 100px;
  position: relative;

  button {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  padding: 50px 0px 24px;
  display: flex;
  justify-content: center;
  gap: 5px;

  svg {
    width: 20px;
  }
`;

export const CarteiraAddItem = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;
  height: 113px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${Button} {
    padding: 10px;
    width: 200px;
  }
`;

export const CarteiraCardContainer = styled.div`
  margin-top: 40px;
`;

export const CarteiraCard = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;

  margin-bottom: 27px;
  padding: 40px 0px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  p {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .textContainer {
    text-align: center;
    margin-bottom: 15px;
  }

  .label {
    margin-bottom: 0px;
    color: #ff0000;
  }
`;

export const AddButton = styled.div`
  background: #ff3d68;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  width: 204px;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;

  svg {
    margin-left: 5px;
  }
`;

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 180px;
  background-color: white;
  display: flex;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
`;
