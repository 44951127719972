import React from "react";

import * as S from "./styled";
import IconComponent from "components/V2/Icon";

const HomeMenu = ({ active, isshop }) => {
  return isshop ? (
    <S.MenuThirds>
      <S.MenuItem
        isshop={isshop ? 1 : 0}
        to="/"
        active={active === "/" || active.includes("live") ? 1 : 0}
      >
        Estreia
      </S.MenuItem>
      <S.MenuItem
        isshop={1}
        to={"/relatorios"}
        active={active === "/relatorios" ? 1 : 0}
      >
        {"Relatórios"}
      </S.MenuItem>
      <S.MenuItem
        isshop={1}
        to={"/agenda"}
        active={active === "/agenda" ? 1 : 0}
      >
        {"Agenda"}
      </S.MenuItem>
    </S.MenuThirds>
  ) : (
    <S.Menu>
      <S.MenuItem
        isshop={isshop ? 1 : 0}
        to="/"
        active={active === "/" || active.includes("live") ? 1 : 0}
      >
        <IconComponent icon="cam" />
        Estreia
      </S.MenuItem>
      <S.MenuItem
        isshop={isshop ? 1 : 0}
        to={isshop ? "/relatorios" : "/agenda"}
        active={active === "/agenda" || active === "/relatorios" ? 1 : 0}
      >
        <IconComponent icon="calendar" />
        {isshop ? "Relatórios" : "Proximas Lives"}
      </S.MenuItem>
    </S.Menu>
  );
};

export default HomeMenu;
