import styled from 'styled-components';

import * as T from 'constants/styles/typography';

export const Variacoes = styled.div`
  position: relative;

  > p {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 5px;
  }
`;

export const Palette = styled.div`
  position: absolute;
  width: 319px;
  z-index: 1;
  background-color: #efefef;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2.7rem;
  box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.1);
  padding: 15px;
  top: ${({ top }) => top};

  p {
    padding-left: 15px;
    margin-bottom: 10px;
    font-size: 1.4rem;
    letter-spacing: -0.05em;
    color: #8a8a8a;
  }
`;

export const PaletteBallsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 32px);
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export const PaletteBalls = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ background }) => (background ? background : '#A9A9A9')};
`;

export const InputsContainer = styled.div`
  position: relative;
  width: 279px;
  > p {
    position: absolute;
    right: 0px;
    top: -15px;
    font-weight: 500;
  }
`;

export const HalfInputContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 1fr;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;

  > div {
    width: auto;
  }

  input,
  select {
    width: 100%;
    font-size: 1.2rem;
  }
`;

export const ColorContainer = styled.div`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  max-width: 279px;
  max-height: 56px;
  padding: 19px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

export const ColorPickerContainer = styled.div`
  position: absolute;
  width: 319px;
  z-index: 1;
  background-color: #efefef;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2.7rem;
  box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.1);
  padding: 15px;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  padding: 19px 0px;
  width: 74.4%;
  max-width: 279px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  ${T.BUTTON_TEXT_EXTRA_LARGE};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const AddProduto = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #d2d2d2;
  border-radius: 13px;

  cursor: pointer;
  padding: 10px 0px;

  svg {
    margin-right: 20px;
  }

  p {
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.03em;
  }
`;
