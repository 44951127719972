import { useAuthContext } from 'contexts/AuthContext';
import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

const RestrictedRoute = ({ component: Component, restricted, ...rest }) => {
  const { isLogin } = useAuthContext();
  const history = useHistory();

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route

    <Route
      {...rest}
      render={(props) =>
        !isLogin() ? (
          <Redirect to={{ pathname: history.location.state?.prevPath || '/', state: { prevPath: null } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default RestrictedRoute;
