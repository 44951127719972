import axios from 'axios'
import { BASE_URL } from 'constants/config'
import Cookies from 'universal-cookie'
import { Magic } from 'magic-sdk'
import { removeFromLocalStorage } from 'utils/handleLocalStorage'

const cookies = new Cookies()

// Pré-configurando a URL padrão do servidor Axios aqui
const api = axios.create({
  baseURL: BASE_URL,
})

// Verifica se já temos as informações do usuário logado no localStorage

// Configura a instância do Axios para injetar o cabeçalho de autenticação antes de cada requisição
api.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${cookies.get('UgongAuth')}`,
    'Content-Type': 'application/json',
  }
  config.withCredentials = true
  config.credentials = 'include'

  return config
})

api.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log('err.response', err.response)
    if (err.response.status === 401) {
      const magic = new Magic('pk_live_41BBA9139059A262', { locale: 'pt' })
      magic.user.logout()
      removeFromLocalStorage('@Ugong/user')
      cookies.remove('UgongAuth')

      window.location.href = '/login'
      return
    }

    return Promise.reject(err)
  }
)

export default api
