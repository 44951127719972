import LiveLabel from 'components/LiveLabel';
import Text from 'components/Text';
import React from 'react';
import { handleDay, handleFormatBR, handleLiveDate } from 'utils/handleDate';

import * as S from './styled';

const SearchLives = ({ item, showLabel }) => {
  const labels = {
    live: {
      text: 'Live!',
      color: 'pink',
    },
    day: {
      text: handleDay(item.scheduled),
      color: 'white',
    },
  };

  return (
    <div>
      <S.SearchLives {...item}>
        <S.ContainerSearchLivesTitle>
          <S.SearchLivesTitle>{item.title}</S.SearchLivesTitle>
        </S.ContainerSearchLivesTitle>
        {showLabel && handleLiveDate(item) && (
          <LiveLabel
            text={labels[handleLiveDate(item)].text}
            background={labels[handleLiveDate(item)].color}
            textSize="2.1rem"
            top="8px"
            right="12px"
            padding="4px 14px"
            style={{ width: 81, textAlign: 'center' }}
          />
        )}
        <S.StoreLogo logo={item.shop.logo} />
      </S.SearchLives>
      <Text style={{ textAlign: 'left', marginTop: 10, paddingLeft: 4 }}>
        <span>{handleFormatBR(item.scheduled)}</span> às {item.hour}
      </Text>
    </div>
  );
};

export default SearchLives;
