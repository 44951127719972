import React, { useEffect, useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Button from 'components/V2/Button';

import * as S from './styled';
import { useHistory } from 'react-router-dom';

import Text from 'components/V2/Text';
import Loader from 'components/Loader';
import api from 'services/api';
import IconComponent from 'components/V2/Icon';

const EmpresaCategorias = ({ dados, setDados, setSteps }) => {
  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const history = useHistory();

  useEffect(() => {
    api
      .get('/static/categories/')
      .then((res) => res.data)
      .then((allCategories) => {
        // updated 'selected' field based on customer data
        const categories = allCategories.map((category) => ({
          ...category,
          selected: dados.categories.includes(category.title),
        }));

        setData(categories);
        setSelectedCategories(
          categories.filter((category) => category.selected)
        );
      })
      .catch(console.error);

    // disabled ESLint warning as this is the expected behavior here (run once)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (el, index) => {
    const newData = [...data];

    newData[index] = {
      ...el,
      selected: !el.selected,
    };

    setData(newData);

    const filtrarSelecionados = newData.filter((el) => el.selected);
    setDados({
      ...dados,
      categories: filtrarSelecionados,
    });
    setSelectedCategories(filtrarSelecionados);
  };

  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (selectedCategories.length > 0) {
      try {
        setDados({
          ...dados,
          error: false,
        });
        //setSteps(3); /*DEBT*/
        setSteps(4);
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  return (
    <>
      <S.CategoriasContainer>
        <S.TitleContainer>
          <IconComponent icon="company" />
          <Text type="title">Conta Profissional</Text>
        </S.TitleContainer>

        <Text>
          Selecione abaixo categorias de sua empresa para que os clientes vejam
          seus eventos.
        </Text>
        <S.CategoryList>
          {data.map((el, index) => {
            return (
              <Button
                key={el._id}
                handleClick={(e) => handleSelect(el, index)}
                styleOfButton={el.selected ? 'primary' : 'outline'}
              >
                {el.title}
              </Button>
            );
          })}
        </S.CategoryList>
        <S.ButtonContainer>
          {!dados.loading && (
            <Button
              disabled={selectedCategories.length < 1}
              handleClick={handleRegister}
              styleOfButton="primaryRed"
              icon="arrowNext"
            >
              Continuar
            </Button>
          )}
          {dados.loading && <Loader />}
        </S.ButtonContainer>
      </S.CategoriasContainer>
    </>
  );
};

export default EmpresaCategorias;
