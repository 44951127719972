import styled from 'styled-components';

export const ProductItem = styled.div`
  background: #f5f5f5;
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 6px;
`;

export const ProductItemContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 279px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductItemText = styled.div`
  h2 {
    color: #8a8a8a;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;

export const ProductItemIcon = styled.div`
  height: 62px;
  width: 62px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 62px 62px;
  position: relative;

  > div {
    position: absolute;
    height: 24px;
    width: 24px;
    font-size: 1.2rem;
    background: #00dfff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    bottom: 0px;
    right: 0px;
  }
`;
