import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Title from 'components/Title';
import CardPaymentForm from 'containers/CardPaymentForm';
import React, { useEffect, useState } from 'react';

import * as S from './styled';

import api from 'services/api';
import Loader from 'components/Loader';

import HeaderCheckout from 'components/HeaderCheckout';
import IconComponent from 'components/V2/Icon';

export const CreditCardComponent = ({
  onClick,
  card,
  icon = 'selectRight',
}) => {
  return (
    <S.CarteiraCard onClick={onClick}>
      <S.WrapSvg>
        <IconComponent icon={card?.brand?.toLowerCase()} />
      </S.WrapSvg>
      <S.Arrow icon={icon}>
        <S.WrapSvg>
          <IconComponent icon={icon} />
        </S.WrapSvg>
      </S.Arrow>
      <S.PaymentText>
        {card?.brand + ' ... **** **** ' + card?.last_four_digits}
      </S.PaymentText>
    </S.CarteiraCard>
  );
};

const PagamentoCarteira = ({
  live,
  setStep,
  setSelectedPayment,
  setSelectedPaymentMethod,
}) => {
  const [addCard, setAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);

  const handleGetCards = async () => {
    setLoading(true);
    const response = await api.get('/user/card');
    setCards(response.data.content);
    setLoading(false);
  };

  useEffect(() => {
    handleGetCards();
  }, []);

  return (
    <>
      {!addCard && (
        <>
          <HeaderCheckout
            title={'Carteira'}
            backAction={() => {
              setStep('revisao');
            }}
          />
          <S.CarteiraContainer>
            <>
              {loading && <Loader />}
              {!loading && (
                <>
                  {cards.map((el, index) => {
                    return (
                      <CreditCardComponent
                        key={el.id}
                        onClick={() => {
                          setSelectedPaymentMethod('credit_card');
                          setSelectedPayment(el);
                          setStep('');
                        }}
                        card={el}
                      />
                    );
                  })}

                  <S.CarteiraAddItem>
                    <S.AddButton onClick={() => setAddCard(true)}>
                      Adicionar Cartão
                    </S.AddButton>
                  </S.CarteiraAddItem>
                </>
              )}
            </>
          </S.CarteiraContainer>
        </>
      )}
      {addCard && (
        <CardPaymentForm setAddCard={setAddCard} getCards={handleGetCards} />
      )}
      <Footer showTop={true} />
    </>
  );
};

export default PagamentoCarteira;
