import styled from 'styled-components'

export const ProdutoContainer = styled.div`
  background: white;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: scroll;
`

export const ProdutoContent = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 250px;
`

export const TitleContainer = styled.div`
  padding: 50px 42px;
`

export const ProdutoTitle = styled.p`
  font-size: 2.1rem;
  font-weight: 600;
`

export const TextContainer = styled.div`
  padding: 25px 42px;
  background: #fafafa;

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #807f7f;
    font-weight: 300;
  }
`

export const ProdutoWhiteContainer = styled.div`
  padding: 25px 42px;

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`

export const ProdutoWhiteContainerHalf = styled.div`
  padding: 25px 42px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 47px;

  > div {
    width: 101px;
  }

  select {
    width: 101px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
    text-align-last: left;
  }

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
`

export const ProdutoColors = styled.div`
  display: flex;
  align-items: center;
`

export const ProdutoColorsItem = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 4px solid transparent;
  transition: all 0.3s ease;
  background-color: ${({ color }) => color};
  margin-right: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  &.selected {
    border: 4px solid #00dfff;
  }
`

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;

  gap: 20px;
`

export const WrapButtonSecondary = styled.div`
  margin-top: 10px;
  svg path {
    fill: ${({ theme }) => theme.colors.SECONDARY_COLOR};
  }
`
