export const statesAndCities = [
  {
    state: 'São Paulo',
    id: 0,
    cities: [
      {
        city: 'São Paulo',
        id: 0,
      },
      {
        city: 'Guarulhos',
        id: 1,
      },
      {
        city: 'Osasco',
        id: 2,
      },
    ],
  },
  {
    state: 'Rio de Janeiro',
    id: 1,
    cities: [
      {
        city: 'Rio de Janeiro',
        id: 0,
      },
      {
        city: 'Niterói',
        id: 1,
      },
      {
        city: 'Duque de Caxias',
        id: 2,
      },
    ],
  },
];
