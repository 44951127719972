import React, { createContext, useContext, useState, useEffect } from 'react';
import api from 'services/api';
import { loadFromLocalstorage, saveToLocalStorage, removeFromLocalStorage } from 'utils/handleLocalStorage';
import { Magic } from 'magic-sdk';
import Cookies from 'universal-cookie';

const magic = new Magic('pk_live_41BBA9139059A262', { locale: 'pt' });
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  const login = async ({ login, name = '', type }) => {
    setIsLoading(true);
    setPageLoading(true);

    magic.user.logout();

    const last_live = !localStorage.getItem('@Ugong/last_live') ? null : localStorage.getItem('@Ugong/last_live');

    if(name){
      localStorage.setItem('preloginUsername', name)
    }

    const email = await magic.auth.loginWithMagicLink({
      email: login,
      redirectURI: `${process.env.REACT_APP_REDIRECT_URL}?last_live=${last_live}`,
      showUI: true,
    });

    return email;
  };

  const logout = async () => {
    setIsLoading(true);
    let expiresDate = new Date();
    let time = expiresDate.getTime();
    let expireTime = time - 1 * 86400000;
    expiresDate.setTime(expireTime);
    await api.post('/logout');
    const cookies = new Cookies();
    removeFromLocalStorage('@Ugong/user');
    cookies.set('UgongAuth', null, { path: '/', expires: expiresDate });
    cookies.remove('UgongAuth');
    setUser({});
    setIsLoading(false);
  };

  const isLogin = () => {
    const checkUser = loadFromLocalstorage('@Ugong/user');
    return checkUser;
  };

  const getPreloginUsername = () => {
    const preloginUsername = localStorage.getItem('preloginUsername') || ''
    localStorage.removeItem('preloginUsername')
    return preloginUsername
  };

  const handleMagicLink = async () => {
    if (window.location.href.includes('magic')) {
      let credential = await magic.auth.loginWithCredential();
      return credential;
    }
  };

  useEffect(() => {
    const checkUser = loadFromLocalstorage('@Ugong/user');
    setUser(checkUser);
    if (!checkUser._id) {
      logout();
    }
  }, []);

  const store = {
    isLoading,
    user,
    login,
    logout,
    isLogin,
    pageLoading,
    setUser,
    handleMagicLink,
    getPreloginUsername,
  };

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
