import styled, { css } from 'styled-components'

const types = {
  small: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 10px;
    font-weight: 400;
  `,
  label: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 10px;
    font-weight: 600;
  `,
  cardTitle: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 14px;
    font-weight: 600;
  `,
  body: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 18px;
    font-weight: 400;
  `,
  bodyEnphasis: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 18px;
    font-weight: 600;
  `,
  title: css`
    color: ${({ theme }) => theme.colors.TEXT_PRIMARY_COLOR};
    font-size: 20px;
    font-weight: 600;
  `,
  error: css`
    color: ${({ theme }) => theme.colors.ERROR};
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
  `,
}

export const Text = styled.p`
  font-family: ${({ theme }) => theme.typography.PRIMARY_FONT};
  text-align: center;

  ${({ type }) => (type && types[type] ? types[type] : '')}
`
