import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Title from 'components/Title';
import CardPaymentForm from 'containers/CardPaymentForm';
import React, { useEffect, useState } from 'react';

import * as S from './styled';

import api from 'services/api';
import Loader from 'components/Loader';

import { ReactComponent as StarIcon } from 'assets/common/star.svg';
import { ReactComponent as DeleteIcon } from 'assets/common/close-red.svg';
import { maskNumber } from 'utils/maskNumber';
import HeaderCheckout from 'components/HeaderCheckout';
import { useHistory } from 'react-router-dom';
import { CreditCardComponent } from 'pages/Pagamento/Carteira';
import Modal from 'components/V2/Modal';
import Button from 'components/V2/Button';

const CadastroCarteira = () => {
  const [addCard, setAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);

  const [cardToExclude, setCardToExclude] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();

  const handleGetCards = async () => {
    setLoading(true);
    const response = await api.get('/user/card');
    setTimeout(() => {
      setCards(response.data.content);
      setLoading(false);
    }, 1000);
  };

  const handleFavorite = async (card) => {
    setLoading(true);
    const copyData = cards?.map((el) => {
      if (el.id === card.id) {
        return {
          ...el,
          favorite: true,
        };
      } else {
        return {
          ...el,
          favorite: false,
        };
      }
    });
    await api.put('/user/card', { data: copyData });
    setCards(copyData);
    setLoading(false);
  };

  const handleDelete = async () => {
    const card = cardToExclude;
    setLoading(true);
    const copyData = [...cards];
    const elIndex = copyData.findIndex((el) => el.id === card.id);
    copyData.splice(elIndex, 1);
    await api.delete('/user/card', { data: card });
    setCards(copyData);
    setLoading(false);

    setModalOpen(false);
  };

  useEffect(() => {
    handleGetCards();
  }, []);

  return (
    <>
      {!addCard && (
        <>
          {/* <BackBar type="back" path="/perfil" shadow={true} text="Voltar" /> */}

          <HeaderCheckout
            title="Cartões"
            icon="creditCard"
            backAction={() => {
              history.push('/perfil');
            }}
          />
          <S.CarteiraContainer>
            <>
              {loading && <Loader />}
              {!loading && (
                <>
                  {cards?.map((el, index) => {
                    return (
                      <CreditCardComponent
                        key={el.id}
                        card={el}
                        icon="cancel"
                        onClick={() => {
                          setCardToExclude(el);
                          setModalOpen(true);
                        }}
                      />
                    );
                  })}
                  <S.CarteiraAddItem>
                    <S.AddButton onClick={() => setAddCard(true)}>
                      Adicionar Cartão
                    </S.AddButton>
                  </S.CarteiraAddItem>
                </>
              )}
            </>
          </S.CarteiraContainer>
        </>
      )}
      {addCard && (
        <CardPaymentForm setAddCard={setAddCard} getCards={handleGetCards} />
      )}

      <Modal
        icon="cancel"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        renderButtons={() => (
          <Button styleOfButton="primaryRed" handleClick={handleDelete}>
            Confirmar Exclusão
          </Button>
        )}
        type="confirmExclusion"
        bodyMessage="Clique em confirmar para excluir esse cartão"
      />
      <Footer showTop={true} />
    </>
  );
};

export default CadastroCarteira;
