import styled, { css } from 'styled-components'

const borders = {
  top: css`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `,
  bottom: css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
}

const sizes = {
  small: css`
    width: 80px;
    height: 38px;

    .react-select__control {
      padding: 0;
    }

    .react-select__option {
      padding: 7px;
    }
  `,
}

export const SelectContainer = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  font-size: ${({ theme }) => theme.typography.SIZES.md};
  font-family: ${({ theme }) => theme.typography.PRIMARY_FONT};
  color: ${({ theme }) => theme.colors.GREY_1};

  text-align: center;

  .react-select__control {
    border: none;
    border-radius: ${({ type }) => (type === 'round' ? '30px' : '10px')};
    transition: border-radius 0.1s;
    padding: 14px;

    background-color: ${({ theme }) => theme.colors.GREY_3};
    box-shadow: none;
  }

  .react-select__menu {
    margin: 0;
  }

  .react-select__control--is-focused {
    color: ${({ theme }) => theme.colors.GREY_1};
  }

  .react-select__option {
    color: ${({ theme }) => theme.colors.GREY_1};
    background-color: ${({ theme }) => theme.colors.GREY_2};
    padding: 14px;

    transition: background-color 0.2s;

    &:nth-child(2n) {
      background-color: ${({ theme }) => theme.colors.GREY_3};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.WHITE};
      background-color: ${({ theme }) => theme.colors.SECONDARY_COLOR};
    }
  }

  .react-select__control--menu-is-open {
    ${({ menuPlacement }) => (menuPlacement ? borders[menuPlacement] : '')}
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    display: block;

    input {
      position: absolute;
    }
  }

  .react-select-2-input {
    position: absolute;
  }

  .react-select__single-value {
    font-size: ${({ theme }) => theme.typography.SIZES.md};
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    grid-area: initial;
    color: ${({ theme }) => theme.colors.GREY_1};
  }

  ${({ size }) => sizes[size] || ''}
`
