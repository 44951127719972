import React from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';

import * as S from './styled';

import AnchorButton from 'components/AnchorButton';

const Done = ({ text, to, button, background }) => {
  return (
    <>
      <S.Container>
        <S.TitleContainerDone>
          <Title style={{ color: background === 'secondary' ? '#FF3D68' : '#00DFFF' }}>{text}</Title>
        </S.TitleContainerDone>
        {button && 
          <AnchorButton text={button} background={background ? background : 'primary'} disabled={false} path={to} />
        }
      </S.Container>
    </>
  );
};

export default Done;
