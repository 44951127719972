import React, { useState } from 'react';

import { handleCNPJ, handleLength } from 'utils/handleRegex';

import { ReactComponent as PlusIconBigger } from 'assets/common/plus-big-pink.svg';
import { ReactComponent as PlusIcon } from 'assets/common/plus.svg';

import * as S from './styled';

import Title from 'components/Title';

import { ReactComponent as DeleteIcon } from 'assets/common/close-red.svg';
import { ReactComponent as MinusIcon } from 'assets/common/minus.svg';
import { convertMoney } from 'utils/convertMoney';
import Button from 'components/Button';
import Loader from 'components/Loader';
import api from 'services/api';
import ProductPagamento from 'containers/ProductPagamento';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import HeaderCheckout from 'components/HeaderCheckout';
import IconComponent from 'components/V2/Icon';

const EmpresaProdutos = ({
  dados,
  setDados,
  handleChange,
  setSteps,
  setProductToEdit,
}) => {
  const handleRegister = async (e) => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setDados({
        ...dados,
        error: true,
        loading: false,
      });
    } else {
      try {
        setDados({
          ...dados,
          error: false,
          loading: false,
        });
        setSteps(4);
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { hour, date, name, banner } = dados;
    if (
      !handleLength(banner, 'bigger', 0) ||
      !handleLength(date, 'bigger', 6) ||
      !handleLength(hour, 'bigger', 4) ||
      !handleLength(name, 'bigger', 3)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <HeaderCheckout
        backAction={() => {
          setSteps(0);
        }}
        title="Cadastrar produtos"
        icon="packagePlus"
      />
      <S.CadastroContainer className="cadastro-inner-container">
        <S.AddProduto onClick={() => setSteps(2)}>
          <PlusIconBigger />
          <p>Adicionar Produtos</p>
        </S.AddProduto>
        {dados.products.map((el, index) => {
          return el.variations.map((product, indexEl) => {
            return (
              <S.ProductCarrinho key={`${index}-${indexEl}`}>
                <S.ProductCarrinhoContent>
                  <ProductPagamento
                    product={{
                      title: el.name,
                      photos: el.photos,
                      promotionalPrice: el.value,
                      originalPrice: el.discount,
                      selectedColor: product.color,
                      selectedSize: product.size,
                      selectedQuantity: 1,
                      hasVariation: product.color != '' || product.size != '',
                    }}
                    canEdit={false}
                  />
                  <S.RemoveButton
                    onClick={() => {
                      setProductToEdit(el._id);
                      setSteps(3);
                    }}
                  >
                    Editar
                    <IconComponent icon="pencilBackground" />
                  </S.RemoveButton>
                </S.ProductCarrinhoContent>
              </S.ProductCarrinho>
            );
          });
        })}
        {dados.products.length > 0 && (
          <S.DoneRegister>
            <Button
              handleClick={() => {
                handleRegister();
              }}
              style={{ background: ' #ff3d68' }}
            >
              Concluir
            </Button>
            {dados.loading && <Loader />}
          </S.DoneRegister>
        )}
      </S.CadastroContainer>
    </>
  );
};

export default EmpresaProdutos;
