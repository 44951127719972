import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styled';

const SearchBar = ({ disabled, value, type = 'text', placeholder, handleChange, name, pathname }) => {
  const history = useHistory();
  const input = useRef();

  const handleSearch = (e) => {
    if ((e.charCode === 13 || e.code === 'Enter') && value.length > 0) {
      history.push({
        pathname: '/',
        search: `?search=${value}`,
        state: { detail: pathname },
      });
      input.current.blur();
    }
  };
  return (
    <S.SearchBar
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => handleChange(e.target)}
      name={name}
      onKeyPress={(e) => handleSearch(e)}
      ref={input}
    />
  );
};

export default SearchBar;
