import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const InputContainer = styled.div`
  width: 279px;
  position: relative;

  div {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const Input = styled.input`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  max-width: 279px;
  padding: 19px 0px;
  text-align: center;
  text-align: -webkit-center;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  border: 2px solid ${({ error }) => (error ? C.INPUT_ERROR_COLOR : '#F4F4F4')};
`;
