import React from 'react';

import * as S from './styled';

const Textarea = ({ disabled, value, placeholder, error, handleChange, name, required }) => {
  return (
    <S.TextareaContainer>
      <S.Textarea
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target)}
        name={name}
        error={error}
      />
      {required && <div>*</div>}
    </S.TextareaContainer>
  );
};

export default Textarea;
