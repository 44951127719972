import styled from 'styled-components';
import { Text } from 'components/V2/Text/styled';
import { Button } from 'components/Button/styled';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 30px;
  width: 80%;
  margin: 0 auto;

  svg {
    margin-right: 10px;
    width: 24px;
    object-fit: cover;
  }
`;

export const ShopName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
`;

export const WrapProducts = styled.div`
  max-width: 400px;
  width: 100%;
  margin: auto;
`;

export const WrapInfoCard = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  svg {
    height: 50px;
    width: 100px;
  }
`;

export const WrapAll = styled.div`
  position: relative;
  height: 100vh;

  overflow-y: scroll;
`;

export const Content = styled.div`
  padding-bottom: 150px;
  position: relative;

  min-height: calc(100vh - 60px);
`;

export const Card = styled.div`
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  padding: 12px 21px;
  margin: auto;

  margin-bottom: 10px;

  background-color: ${({ theme }) => theme.colors.GREY_3};

  ${Text} {
    text-align: start;
  }

  svg {
    margin: 10px 0;
    width: 40px;
    object-fit: contain;
  }
`;

export const WrapImage = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const StepsToPay = styled.ol`
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  list-style: auto;
  color: #545454;

  padding-left: 40px;
  margin-bottom: 20px;

  li {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #545454;

    margin-bottom: 5px;
  }
`;

export const GreyBar = styled.div`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  margin: 0 auto 30px;
  text-align: center;

  overflow-wrap: break-word;

  h5 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;

export const WrapButton = styled.div`
  margin-bottom: 30px;

  ${Button} {
    margin: auto;
    width: 200px;
    height: 37px;
    font-size: 15px;
  }
`;

export const WrapPix = styled.div`
  img {
    margin: auto;
    width: 200px;
    object-fit: cover;
    display: block;
  }
`;
