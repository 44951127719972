import { Text } from 'components/V2/Text/styled'
import styled from 'styled-components'

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 25px 0px 50px;
  gap: 20px;
`

export const Concluir = styled.div`
  height: calc(100% - 197px);
  position: absolute;
  overflow: auto;
  width: 100%;
  padding-bottom: 45px;
`

export const PagamentoContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 30px;
  width: 80%;
  margin: 0 auto;

  svg {
    margin-right: 10px;
    width: 24px;
    object-fit: cover;
  }
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_3};
  width: 100%;
  max-width: 279px;
  margin: 10px auto;
  border-radius: 10px;
  padding: 10px;

  cursor: pointer;
`

export const WrapImage = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const WrapStoreInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${Text} {
    font-weight: 600;
  }
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapProduct = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding-left: 25px;
  margin-top: 5px;

  ${WrapImage} {
    height: 42px;
    width: 42px;
  }

  svg {
    height: 20px;
    object-fit: contain;
  }
`
