import React from 'react';
import ICalendarLink from 'react-icalendar-link';

import * as S from './styled';

import { ReactComponent as Gmail } from 'assets/social/gmail.svg';
import { ReactComponent as OutLook } from 'assets/social/outlook.svg';
import { ReactComponent as Yahoo } from 'assets/social/yahoo.svg';
import { ReactComponent as ICalendar } from 'assets/social/icalendar.svg';

export const CalendarItem = ({ selected, handleCreateAlarm }) => {
  const items = [
    {
      text: 'Google Calendar',
      icon: <Gmail />,
      url: ({ title, shop, scheduled, hour, _id, protoId }) => {
        let isodate = new Date(scheduled);
        const id = _id.toString();
        const name = title.replaceAll(' ', '+') + '+-+' + shop.name.replaceAll(' ', '+');
        const startHour = isodate.toISOString().replaceAll(/[-.:]/g, '');
        isodate.setUTCHours(isodate.getUTCHours() + 1);
        const endHour = isodate.toISOString().replaceAll(/[-.:]/g, '');
        return `https://www.google.com/calendar/render?action=TEMPLATE&text=${name}&dates=${startHour}00Z/${endHour}00Z&location=https%3A%2F%2Fapp.ugong.com.br/live/${shop.nameURL}/${protoId}&details=N%C3%A3o%20perca%20a%20live%20${name}!%20Acesse%20https%3A%2F%2Fapp.ugong.com.br/live/${shop.nameURL}/${protoId}%20e%20participe!&sf=true&output=xml`;
      },
    },
    {
      text: 'Outlook',
      icon: <OutLook />,
      url: ({ title, shop, scheduled, hour, _id, protoId }) => {
        let isodate = new Date(scheduled);
        const id = _id.toString();
        const name = title.replaceAll(' ', '+') + '+-+' + shop.name.replaceAll(' ', '+');
        const startHour = isodate.toISOString().replace('.000Z', ':00Z');
        isodate.setUTCHours(isodate.getUTCHours() + 1);
        const endHour = isodate.toISOString().replace('.000Z', ':00Z');
        return (
          'https://outlook.live.com/calendar/0/deeplink/compose?rru=addevent&path=%2Fcalendar%2Faction%2Fcompose&' +
          encodeURI(
            `startdt=${startHour}&enddt=${endHour}&subject=${name}&body=Não perca a live ${name}! Acesse https://app.ugong.com.br/live/${shop.nameURL}/${protoId} e participe!&location=https://app.ugong.com.br/live/${shop.nameURL}/${protoId}&allday=false`,
          )
        );
      },
    },
    {
      text: 'Yahoo!',
      icon: <Yahoo />,
      url: ({ title, shop, scheduled, hour, _id, protoId }) => {
        let isodate = new Date(scheduled);
        const id = _id.toString();
        const name = title.replaceAll(' ', '+') + '+-+' + shop.name.replaceAll(' ', '+');
        const startHour = isodate.toISOString().replaceAll(/[-.:]/g, '');
        isodate.setUTCHours(isodate.getUTCHours() + 1);
        const endHour = isodate.toISOString().replaceAll(/[-.:]/g, '');
        return (
          'https://calendar.yahoo.com/?v=60&' +
          encodeURI(
            `title=${name}&desc=Não perca a live ${name}! Acesse https://app.ugong.com.br/live/${shop.nameURL}/${protoId} e participe!&in_loc=https://app.ugong.com.br/live/${shop.nameURL}/${protoId}&st=${startHour}&dur=0100`,
          )
        );
      },
    },
  ];

  const event = {
    title: selected.title + ' - ' + selected.shop?.name,
    description: 'Live na Ugong',
    startTime: new Date(selected.scheduled).toISOString(),
    endTime: new Date(selected.scheduled).toISOString(),
    location: 'ugong.com.br',
  };

  return (
    <S.CalendarItemContainer>
      {items.map((el) => (
        <S.LinkCalendar key={items.indexOf(el)} onClick={() => handleCreateAlarm(selected)}>
          <a target="_blank" rel="nofollow noreferrer" href={el.url(selected)}>
            <div key={items.indexOf(el)}>{el.icon}</div>
            {el.text}
          </a>
        </S.LinkCalendar>
      ))}
      <S.LinkCalendar onClick={() => handleCreateAlarm(selected)}>
        <ICalendarLink event={event}>
          <div>
            <ICalendar />
          </div>
          ICalendar
        </ICalendarLink>
      </S.LinkCalendar>
    </S.CalendarItemContainer>
  );
};
