const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
const dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

export const handleDate = (date) => {
  let data = new Date(date);
  return ('0' + data.getDate()).slice(-2) + ' ' + meses[data.getMonth()];
};

export const handleDay = (date) => {
  let d = new Date(date);
  return dias[d.getDay()];
};

export const handleLiveDate = (item) => {
  const { onAir, outDated } = item;

  return onAir ? 'live' : !outDated ? 'day' : false;
};

export const handleFormatBR = (date) => {
  return new Date(date).toLocaleDateString('pt-br');
};
