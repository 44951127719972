import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const ErrorMessage = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  color: ${C.INPUT_ERROR_COLOR};
  margin: 12px 0px 0px;
`;
