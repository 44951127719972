import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const CadastroContainer = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  height: calc(100% - 140px);
  padding-bottom: 25px;
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  padding: 38px 0px;
`;

export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${({ background }) =>
      `linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%),url(${background})`}
    no-repeat;
  background-size: 100%;
  ${({ live }) =>
    live
      ? 'background: linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%);'
      : '#FFF'};
`;

export const FileInputContainer = styled.div`
  text-align: center;

  flex-direction: column;
  margin: 0 auto 26px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  height: 182px;
  border: 0.5px solid #d2d2d2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  width: 281px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .pinkArrow {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  .labeltext {
    font-weight: 600;
    font-size: 1rem;
    width: 60%;
    margin: 5px auto 0px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  padding-bottom: 70px;
  position: relative;

  input,
  select {
    width: 279px;
  }
`;
export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  padding: 50px 0px 24px;
  width: 70%;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;

  button {
    margin: 0 auto;
  }
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 15px;
`;

export const TermsContainer = styled.div`
  width: 279px;
  margin-bottom: 25px;
`;

export const CategoryList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 65px;
`;

export const CategoryItem = styled.li`
  width: 100%;
  background: #efefef;
  border-radius: 20px;
  text-align: center;
  padding: 12px 0px;
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-bottom: 7px;
  font-weight: 500;
  transition: all 0.4s ease;

  &.selected {
    background: #ffced9;
  }
`;

export const CategoriasContainer = styled.div`
  max-width: 279px;
  margin: 0px auto 100px;

  button {
    width: 100%;
  }
`;

export const SocialContainer = styled.div`
  margin: 0 auto;
  width: 279px;
  height: 600px;

  button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export const ContainerSlideItemTitle = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 70%;
  text-align: right;
`;

export const SlideItemTitle = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.32px;
`;

export const HalfInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;

  div {
    width: auto;
  }

  input,
  select {
    width: 100%;
  }
`;

export const TextareaContainer = styled.div`
  height: 137px;
  width: 100%;
`;

export const RealContainer = styled.div`
  position: relative;

  input {
    padding-right: 30px;
    padding-left: 30px;
  }

  > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 1.5rem;
  }
`;

export const SwiperContainer = styled.div`
  max-height: 182px;
  height: 182px;
  max-width: 100%;
  position: relative;
  width: 281px;
  margin: 0 auto 30px;
  cursor: pointer;

  @media (min-width: 600px) {
    max-width: 800px;
    height: 400px;
    max-height: 400px;
  }

  .swiper-wrapper {
    width: 281px;
    height: 182px;
    width: 100%;

    @media (min-width: 600px) {
      max-width: 800px;
      height: 400px;
      max-height: 400px;
    }
  }

  .swiper-pagination {
    width: auto;
    left: 10px;
    bottom: 10px;

    display: flex;
    align-items: center;
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ff3d68;
    opacity: 1;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
    width: 8px;
    height: 8px;
  }

  svg {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
  }
`;
