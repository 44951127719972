import BackBar from 'components/BackBar';
import CardPaymentForm from './CardPaymentForm';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from 'contexts/AuthContext';

import * as S from './styled';
import api from 'services/api';

import Loader from 'components/Loader';

import { ReactComponent as DeleteIcon } from 'assets/common/close-red.svg';
import Text from 'components/V2/Text';
import StepsLine from '../../StepsLine';
import { truncate } from 'utils/handleString';
import Button from 'components/V2/Button';
import { useShopContext } from 'contexts/ShopsContext';
import {
  removeFromLocalStorage,
  saveToLocalStorage,
} from 'utils/handleLocalStorage';
import IconComponent from 'components/V2/Icon';
import PixPaymentForm from './PIXPaymentForm';

const EmpresaCarteira = ({
  setCarteira,
  carteira,
  account,
  categorias,
  endereco,
  setSteps,
}) => {
  const [addPix, setAddPix] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const AuthContext = useAuthContext();
  const [user, setUser] = useState();
  const context = useAuthContext();
  const { addNewShop } = useShopContext();

  const handleGetCards = () => {
    //pendente o get do que já existe
    setTimeout(() => {
      setCards(carteira);
      setLoading(false);
    }, 1000);
  };

  /* const handleFavorite = (id) => {
    const copyData = cards.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          favorite: true,
        };
      } else {
        return {
          ...el,
          favorite: false,
        };
      }
    });

    setCards(copyData);
  }; */

  const handleDelete = (id) => {
    const copyData = [...cards];
    const elIndex = copyData.findIndex((el) => el.id === id);
    copyData.splice(elIndex, 1);

    // chamada deletar cartão
    setCards(copyData);
    setCarteira(copyData);
  };

  const handleRegister = async () => {
    setLoading(true);
    let body = {
      account: account,
      endereco: endereco,
      categorias: categorias,
      carteira: carteira?.filter((item) => !item.type),
      uri: window.location.href,
    };
    const response = await api.post('/shops/create', body);
    if (
      !response.data.content &&
      !response.data.content.shop &&
      !response.data.content.shop._id
    )
      throw false;
    removeFromLocalStorage('@Ugong/user');
    setUser({});
    saveToLocalStorage('@Ugong/user', response.data.content);
    setUser(response.data.content);
    addNewShop(response.data.content.shopInfo); /*DEBT*/ //não funciona se chamar daqui
    AuthContext.setUser(response.data.content);
    setLoading(false);
    setSteps(5);
  };

  useEffect(() => {
    setLoading(true);
    handleGetCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carteira, cards]);

  const renderTitle = () => {
    if (addPix) {
      return (
        <S.TitleContainer>
          <IconComponent icon="pix" />
          <Text type="title">Chave PIX</Text>
        </S.TitleContainer>
      );
    }

    return (
      <S.TitleContainer>
        <IconComponent icon="company" />
        <Text type="title">Conta Profissional</Text>
      </S.TitleContainer>
    );
  };

  return (
    <>
      <BackBar
        type="back"
        path="/empresa/cadastro"
        shadow={true}
        text="Voltar"
        onClick={() => {
          if (!addCard) {
            setAddCard(false);
            //setSteps(3); /*DEBT*/
            setSteps(2);
          } else {
            setAddCard(false);
          }
        }}
      />
      <>
        <S.CarteiraContainer>
          <StepsLine step={4} />
          <>
            {renderTitle()}
            <Text>
              Informe abaixo sua conta bancária da empresa para tranferência do
              valor de suas vendas.
            </Text>

            <S.CarteiraCardContainer>
              {loading && <Loader />}
              {!addCard && !loading && !addPix && (
                <>
                  {cards.map((el, index) => {
                    return (
                      <S.CarteiraCard key={index}>
                        {el.type === 'pix' ? (
                          <div className="textContainer">
                            <p>PIX {el.pix}</p>
                          </div>
                        ) : (
                          <div className="textContainer">
                            <p>{truncate(el.banco)}</p>
                            <p>
                              Ag {el.agencia} - CC {el.conta}
                            </p>
                          </div>
                        )}
                        <S.RemoveButton onClick={() => handleDelete(el.id)}>
                          Excluir
                          <DeleteIcon />
                        </S.RemoveButton>
                        {el.validation && <p className="label">Em aprovação</p>}
                      </S.CarteiraCard>
                    );
                  })}
                  <S.CarteiraAddItem>
                    <Button handleClick={() => setAddCard(true)} icon="add">
                      Adicionar Conta
                    </Button>
                  </S.CarteiraAddItem>
                  <S.CarteiraAddItem>
                    <Button handleClick={() => setAddPix(true)} icon="pix">
                      Adicionar PIX
                    </Button>
                  </S.CarteiraAddItem>
                </>
              )}
              {addCard && <Loader /> && (
                <CardPaymentForm
                  setAddCard={setAddCard}
                  getCards={handleGetCards}
                  setCarteira={setCarteira}
                  carteira={carteira}
                />
              )}
              {addPix && <Loader /> && (
                <PixPaymentForm
                  setAddPix={setAddPix}
                  setCarteira={setCarteira}
                  carteira={carteira}
                />
              )}
            </S.CarteiraCardContainer>
          </>
        </S.CarteiraContainer>
      </>
      {carteira.length > 0 && !loading && (
        <S.DoneRegister>
          <Button
            styleOfButton="primaryRed"
            icon="arrowNext"
            handleClick={handleRegister}
          >
            Concluir cadastro da loja
          </Button>
        </S.DoneRegister>
      )}
    </>
  );
};

export default EmpresaCarteira;
