import styled from 'styled-components';

export const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StepsLine = styled.div`
  width: 135px;
  height: 3px;
  background-color: ${({ active }) => (active ? '#FF3D68' : '#F4F4F4')};
`;
