import { css } from "styled-components";

export const PRIMARY_FONT = "'Work Sans', sans-serif";

export const calcRem = (value) => (value > 0 ? `${value / 16}rem` : "");

export const SIZES = {
  xl: calcRem(50),
  lg: calcRem(35),
  mdl: calcRem(27),
  md: calcRem(22),
  sml: calcRem(20),
  sm: calcRem(18),
  xs: calcRem(15),
};

export const TITLE_EXTRA_LARGE = css`
  & {
    font-size: ${SIZES.xl};
    font-weight: 800;
  }
`;

export const TITLE_LARGE = css`
  & {
    font-size: ${SIZES.lg};
    font-weight: 500;
  }
`;

export const TITLE_MEDIUM = css`
  & {
    font-size: ${SIZES.md};
    font-weight: 800;
  }
`;

export const BODY_TEXT_MEDIUM = css`
  & {
    font-size: ${SIZES.md};
  }
`;

export const BODY_TEXT_NORMAL = css`
  & {
    font-size: ${SIZES.sm};
    font-weight: 500;
  }
`;

export const BODY_TEXT_SMALL = css`
  & {
    font-size: ${SIZES.xs};
    font-weight: 500;
  }
`;

export const PLACEHOLDER = css`
  & {
    font-size: ${SIZES.md};
    font-weight: 400;
  }
`;

export const BUTTON_TEXT_EXTRA_LARGE = css`
  & {
    font-size: ${SIZES.mdl};
    font-weight: 700;
  }
`;
