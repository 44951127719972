import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Localizacao from './Steps/Localizacao';
import Categorias from './Steps/Categorias';
import Done from './Steps/Done';

const Conta = () => {
  const [step, setStep] = useState('');
  const params = useParams();

  useEffect(() => {
    setStep(params.step);
  }, [params]);

  return (
    <>
      {step === 'localizacao' && <Localizacao />}
      {step === 'categorias' && <Categorias />}
      {step === 'done' && <Done />}
    </>
  );
};

export default Conta;
