/* eslint-disable react/no-children-prop */
import Title from 'components/Title';
import BackBar from 'components/BackBar';
import React from 'react';

import * as S from './styled';

const Termos = () => {
  return (
    <>
      <BackBar type="back" path="/ajuda" shadow={true} text="Voltar" />
      <S.InstitucionalContent>
        <S.TitleContainer>
          <Title>Termos de Uso</Title>
        </S.TitleContainer>
        <S.Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod ab amet eligendi quibusdam et. Impedit ut
          quaerat accusantium? Fuga, nemo. Distinctio ab enim officia laborum, aut numquam explicabo amet placeat.
        </S.Text>
        <S.Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod ab amet eligendi quibusdam et. Impedit ut
          quaerat accusantium? Fuga, nemo. Distinctio ab enim officia laborum, aut numquam explicabo amet placeat.
        </S.Text>
        <S.Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod ab amet eligendi quibusdam et. Impedit ut
          quaerat accusantium? Fuga, nemo. Distinctio ab enim officia laborum, aut numquam explicabo amet placeat.
        </S.Text>
        <S.Text>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod ab amet eligendi quibusdam et. Impedit ut
          quaerat accusantium? Fuga, nemo. Distinctio ab enim officia laborum, aut numquam explicabo amet placeat.
        </S.Text>
      </S.InstitucionalContent>
    </>
  );
};

export default Termos;
