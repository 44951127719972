import { Link } from 'react-router-dom'
import styled from 'styled-components'

import * as C from 'constants/styles/colors'
import * as T from 'constants/styles/typography'

export const CadastroContainer = styled.div`
  height: calc(100% - 199px);
  position: absolute;
  overflow: auto;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding: 1em 1em 65px 1em;
`

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  margin: 38px 0px;
`

export const PerfilAvatar = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin: 0px auto 11px;
`

export const Avatar = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 84px 84px;
`

export const FileInputContainer = styled.div`
  margin-bottom: 26px;
  text-align: center;
`

export const FileInput = styled.input`
  display: none;
`

export const FileLabel = styled.label`
  display: inline-block;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  padding-bottom: 50px;
  position: relative;

  input,
  select {
    width: 279px;
  }
`
export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`

export const TitleContainer = styled.div`
  margin: 38px 0px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;

  button {
    margin: 0 auto;
  }
`

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 15px;
`

export const HalfInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  > div > div {
    max-width: 100%;
    width: 100%;

    input,
    select {
      max-width: 100%;
      width: 100%;
    }
  }
`

export const CVVContainer = styled.div`
  position: relative;

  p {
    position: absolute;
    bottom: -15px;
    font-size: 1rem;
    right: 0px;
    font-weight: 500;
  }
`

export const WrapTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  svg {
    width: 24px;
    object-fit: cover;
  }
`
