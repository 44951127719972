import styled, { css } from 'styled-components'

export const Outside = styled.div`
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.5);
`

export const Modal = styled.div`
  width: 300px;
  min-height: 337px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: 15px;

  border-radius: 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.3);
`

export const Button = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;

  height: 20px;
  width: 20px;

  cursor: pointer;

  svg {
    width: 100%;
  }
`

const iconsWithStroke = ['cancel']

const colorWithStroke = css`
  stroke: ${({ theme, color }) =>
    color === 'primary'
      ? theme.colors.PRIMARY_COLOR
      : theme.colors.SECONDARY_COLOR};
`

const colorWithNoStroke = css`
  fill: ${({ theme, color }) =>
    color === 'primary'
      ? theme.colors.PRIMARY_COLOR
      : theme.colors.SECONDARY_COLOR};
`
export const WrapIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;

  svg {
    width: 60px;
    object-fit: cover;

    path {
      ${({ icon }) =>
        iconsWithStroke.includes(icon) ? colorWithStroke : colorWithNoStroke}
    }
  }
`

export const WrapButton = styled.div`
  button:nth-child(2n) {
    margin-top: 10px;
  }
`
