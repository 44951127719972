import BackBar from 'components/BackBar';
import Button from 'components/Button';

import Text from 'components/V2/Text';
import Title from 'components/Title';
import React, { useEffect, useState } from 'react';

import * as S from './styled';
import api from 'services/api';

import { useHistory } from 'react-router-dom';

import ProductLine from 'components/ProductLine';
import { convertMoney } from 'utils/convertMoney';
import Loader from 'components/Loader';
import HeaderCheckout from 'components/HeaderCheckout';
import IconComponent from 'components/V2/Icon';
import Container from 'components/V2/Container';
import { status } from './status';

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const types = {
    retirar: {
      text: 'Retirada na loja',
    },
    receber: {
      text: 'Entrega no cliente',
    },
  };

  const renderStatus = (product) => {
    return status[product.status] || '';
  };

  const getFirstProduct = (product) => {
    return product?.items?.length >= 1 ? product?.items[0] : null;
  };

  const renderPhoto = (product) => {
    const item = getFirstProduct(product);

    if (!item) return '';

    if (item?.photos?.length) {
      return item?.photos[0];
    }

    return '';
  };

  useEffect(async () => {
    let response = await api.get('/orders/read');
    setTimeout(() => {
      setPedidos(response.data.content);
      setLoading(false);
    }, 1000);
  }, []);

  const redirect = (item) => {
    history.push('/pedidos/detalhes', {
      item,
    });
  };

  return (
    <>
      {/* <BackBar shadow={true} text="Voltar" type="back" path={'/perfil'} /> */}
      <HeaderCheckout
        title={'Meus Pedidos'}
        backAction={() => {
          history.push('/perfil');
        }}
      />
      <S.Concluir>
        <Container>
          <S.PagamentoContent>
            {loading && <Loader />}
            {!loading &&
              pedidos &&
              pedidos.map((el, index) => {
                return (
                  <S.Card key={el._id} onClick={() => redirect(el)}>
                    <S.CardHeader>
                      <S.WrapStoreInfo>
                        <S.WrapImage>
                          <img src={el.shop.logo} alt="" />
                        </S.WrapImage>
                        <Text type="small">{el.shop.name}</Text>
                      </S.WrapStoreInfo>
                      {renderStatus(el)}
                    </S.CardHeader>

                    <S.CardBody>
                      <S.WrapProduct>
                        <S.WrapImage>
                          <img src={renderPhoto(el)} alt="" />
                        </S.WrapImage>

                        <Text type="small">{getFirstProduct(el)?.title}</Text>
                      </S.WrapProduct>

                      <IconComponent icon="selectRight" />
                    </S.CardBody>
                  </S.Card>
                );
              })}
          </S.PagamentoContent>
        </Container>
      </S.Concluir>
      <S.DoneRegister>
        <Button handleClick={() => history.push('/')}>
          Voltar para a home
        </Button>
      </S.DoneRegister>
    </>
  );
};

export default Pedidos;
