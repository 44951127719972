import React, { useEffect, useState } from 'react';

import BackBar from 'components/BackBar';
import Button from 'components/V2/Button';

import Text from 'components/Text';
import Title from 'components/Title';
import { ReactComponent as StoreIcon } from 'assets/common/store.svg';

import * as StyleCart from '../../Carrinho/styled';
import * as S from './styled';

import { useCarrinhoContext } from 'contexts/CarrinhoContext';

import { useHistory } from 'react-router-dom';
import CartResume from 'components/CartResume';
import HeaderCheckout from 'components/HeaderCheckout';
import ClipBoardButton from 'components/Button/clipBoardButton';

const PagamentoConcluirPix = ({ pixData }) => {
  const { cleanCartLocal, carrinho } = useCarrinhoContext();
  const history = useHistory();

  const store = carrinho?.length ? carrinho[0]?.shop : {};

  return (
    <>
      <HeaderCheckout
        title={'Carrinho'}
        icon={'cartOutline'}
        backAction={() => {
          cleanCartLocal();
          history.push('/');
        }}
      />
      <StyleCart.Carrinho>
        <CartResume />

        <StyleCart.WrapProducts>
          <StyleCart.ShopDetails>
            <StyleCart.WrapShopName>
              <StoreIcon />
              <StyleCart.ShopName>Loja: {store.name}</StyleCart.ShopName>
            </StyleCart.WrapShopName>
            <StyleCart.ShopItems></StyleCart.ShopItems>
          </StyleCart.ShopDetails>
        </StyleCart.WrapProducts>
      </StyleCart.Carrinho>

      <S.Concluir>
        <S.PagamentoContent>
          <S.TextContainer>
            <Text as="p">
              <span>Entraremos em contato por e-mail.</span>
            </Text>
          </S.TextContainer>

          <S.StepsToPay>
            <li>Acesse seu internet banking ou app de pagamentos.</li>
            <li>Escolha pagar via Pix.</li>
          </S.StepsToPay>

          <S.TitleContainer>Copie este código para pagar</S.TitleContainer>

          <S.GreyBar>{pixData.copyPast}</S.GreyBar>

          <S.WrapButton>
            <ClipBoardButton text={pixData.copyPast}>
              Copiar chave PIX
            </ClipBoardButton>
          </S.WrapButton>
        </S.PagamentoContent>

        <S.StepsToPay>Ou efetue a leitura do QRCode abaixo </S.StepsToPay>

        <S.WrapImage>
          <img src={pixData.qrCode} alt="" />
        </S.WrapImage>
      </S.Concluir>

      <S.Zindex>
        <S.DoneRegister>
          <Button
            handleClick={() => {
              cleanCartLocal();
              history.push('/');
            }}
          >
            Voltar para a home
          </Button>
        </S.DoneRegister>
      </S.Zindex>
    </>
  );
};

export default PagamentoConcluirPix;
