import styled from 'styled-components';

export const TextContainer = styled.div`
  line-height: 22px;

  div {
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  margin: 0px 0px 17px;
`;

export const Description = styled.p`
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #a9a9a9;
  margin: 20px 0px 30px;
`;

export const TextareaContainer = styled.div`
  height: 137px;
  margin-top: 20px;
`;
