import styled from 'styled-components';

export const SwiperContainer = styled.div`
  max-height: 267px;
  height: 267px;
  max-width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
  }

  .swiper-wrapper {
    max-height: 267px;
    height: 267px;
    width: 100%;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    width: auto;
    left: 25px;
    bottom: 10px;

    display: flex;
    align-items: center;
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background-color: white;
    opacity: 1;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
    width: 8px;
    height: 8px;
  }
`;

export const ProdutoPrice = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1.6rem;
  font-weight: 600;
  right: 55px;
  bottom: -19px;
  z-index: 1;
`;
