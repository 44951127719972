import styled from 'styled-components';

export const CarteiraContainer = styled.div`
  height: calc(100% - 140px);
  overflow: auto;
  width: 300px;
  margin: auto;
  padding: 1em 1em 65px 1em;

  button {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  margin: 33px 0px;
`;

export const CarteiraAddItem = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;
  height: 113px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarteiraCard = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;

  margin-bottom: 27px;
  padding: 30px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  p {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 1.3rem;
  }
`;

export const AddButton = styled.div`
  background: #00dfff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  width: 204px;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;

  svg {
    margin-left: 5px;
  }
`;
