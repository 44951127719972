import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styled';

import Footer from 'components/Footer';
import Title from 'components/Title';
import BackBar from 'components/BackBar';
import AnchorButton from 'components/AnchorButton';
import Contato from './Subpages/Contato';
import Termos from './Subpages/Termos';
import Privacidade from './Subpages/Privacidade';
import Trocas from './Subpages/Trocas';

const Conta = () => {
  const [theme, setTheme] = useState('');
  const params = useParams();

  useEffect(() => {
    if (params.theme) {
      setTheme(params.theme);
    } else {
      setTheme('');
    }
  }, [params]);

  return (
    <>
      {theme === '' && (
        <>
          <BackBar type="back" path="/perfil" shadow={true} text="Voltar" />
          <S.TitleContainer>
            <Title>Ajuda</Title>
          </S.TitleContainer>
          <S.ButtonsContainer>
            <AnchorButton
              text="Contatos"
              background="primary"
              disabled={false}
              path="/ajuda/contatos"
            />
            <AnchorButton
              text="Termos de Uso"
              background="primary"
              disabled={false}
              path="https://ugong.com.br/wp-content/uploads/2022/04/Termos-e-Condic%CC%A7o%CC%83es-Gerais-de-Uso-UGONG.pdf"
            />
            <AnchorButton
              text="Política de Privacidade"
              background="primary"
              disabled={false}
              path="https://ugong.com.br/wp-content/uploads/2022/04/Ugong-politica-de-privacidade-e-cookies_PDF.pdf"
            />
            <AnchorButton
              text="Política de trocas"
              background="primary"
              disabled={false}
              path="/ajuda/trocas"
            />
          </S.ButtonsContainer>
        </>
      )}
      {theme === 'contatos' && <Contato />}
      {theme === 'termos' && <Termos />}
      {theme === 'privacidade' && <Privacidade />}
      {theme === 'trocas' && <Trocas />}
      <Footer showTop={true} />
    </>
  );
};

export default Conta;
