import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const CadastroContainer = styled.div`
  padding: 1em 1em 45px 1em;
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  padding: 38px 0px;
`;

export const PerfilAvatar = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin: 0px auto 11px;
`;

export const Avatar = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 84px 84px;
  border: 1px solid #adadad;
`;

export const Logo = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 124px 124px;
  border: 1px solid #adadad;
`;

export const FileInputContainer = styled.div`
  margin-bottom: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  display: inline-block;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  padding-bottom: 46px;
  position: relative;

  input,
  select {
    width: 279px;
  }
`;
export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  padding: 50px 0px 24px;
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;

  button {
    margin: 0 auto;
  }
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 15px;
`;

export const TermsContainer = styled.div`
  width: 279px;
  margin-bottom: 25px;

  p {
    display: inline;
    text-align: center
  }
`;

export const CategoryList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 65px;

  display: flex;
  flex-direction: column;
  gap: 10px
`;

export const CategoryItem = styled.li`
  width: 100%;
  background: #efefef;
  border-radius: 20px;
  text-align: center;
  padding: 12px 0px;
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-bottom: 7px;
  font-weight: 500;
  transition: all 0.4s ease;

  &.selected {
    background: #ffced9;
  }
`;

export const CategoriasContainer = styled.div`
  max-width: 279px;
  margin: 0px auto 100px;

  button {
    width: 100%;
  }
`;

export const LinkExternal = styled.a`
  
`;

export const SocialContainer = styled.div`
  margin: 0 auto;
  width: 279px;
  height: 600px;

  button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;
