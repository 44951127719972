import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const Text = styled(`${({ as }) => as}`)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`;
