import React, { useEffect, useState } from 'react';
import Button from 'components/V2/Button';
import Text from 'components/Text';

import * as S from '../../Carrinho/styled';

import { ReactComponent as StoreIcon } from 'assets/common/store.svg';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import ProductCarrinho from 'containers/ProductPagamento';
import { useHistory } from 'react-router-dom';
import CartResume from 'components/CartResume';
import HeaderCheckout from 'components/HeaderCheckout';

const Carrinho = ({ setSelectedPaymentMethod, setStep, goBack }) => {
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const { carrinho, getMacro } = useCarrinhoContext();
  const history = useHistory();

  useEffect(() => {
    setProducts(carrinho);

    if (carrinho.length) {
      setStore(carrinho[0].shop);
    }
  }, [carrinho, getMacro]);

  const renderProducts = (shopProducts) => {
    return shopProducts.map((el, index) => {
      return (
        <ProductCarrinho
          key={`${el._id}${el.selectedColor}${el.selectedSize}`}
          product={el}
          index={index}
        />
      );
    });
  };

  return (
    <>
      <HeaderCheckout
        title={'Revise seu pedido'}
        icon={'cartOutline'}
        backAction={goBack}
      />

      {products?.length <= 0 && (
        <S.CarrinhoVazio>
          <Text as="p">Seu carrinho está vazio</Text>
        </S.CarrinhoVazio>
      )}
      {products && products.length > 0 && (
        <S.Carrinho>
          <CartResume />

          <S.WrapProducts>
            <S.ShopDetails>
              <S.WrapShopName>
                <StoreIcon />
                <S.ShopName>Loja: {store.name}</S.ShopName>
              </S.WrapShopName>
              <S.ShopItems>Itens</S.ShopItems>
              {renderProducts(products)}
            </S.ShopDetails>
          </S.WrapProducts>
        </S.Carrinho>
      )}
      {products && products.length > 0 && (
        <S.DoneRegister>
          <Button
            disabled={!products && !products.length > 0}
            handleClick={() => {
              setSelectedPaymentMethod('credit_card');
              setStep('carteira');
            }}
            icon="creditCard"
          >
            Pagar com cartão
          </Button>
          <Button
            disabled={!products && !products.length > 0}
            handleClick={() => {
              setSelectedPaymentMethod('pix');
              setStep('');
            }}
            icon="pix"
          >
            Pagar com PIX
          </Button>
        </S.DoneRegister>
      )}
    </>
  );
};

export default Carrinho;
