import React, { useEffect, useState } from "react";

import * as S from "./styled";

import { ReactComponent as Dropdown } from "assets/common/dropdown.svg";

import ProductSlider from "containers/ProductListSlider";
import Icon from "components/V2/Icon";
import LiveChat from "containers/LiveChat";
import { useProducts } from "contexts/ProductsContext";
import Container from "components/V2/Container";

const BottomMenu = ({
  item,
  setDenunciar,
  live,
  sentMessages,
  setCarrinho,
}) => {
  const [showProducts, setShowProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const { productIsSetted, setSelectedProduct, setShowAllInfo } = useProducts();

  useEffect(() => {
    setProducts(item.productsDetails);

    return () => {
      setSelectedProduct(null);
      setShowAllInfo(false);
    };
  }, [setSelectedProduct, setShowAllInfo]);

  const renderHideButton = () => {
    if (productIsSetted) {
      return (
        <S.OcultarButton onClick={() => setSelectedProduct({})}>
          <Icon icon="arrowLeft" />
          <p>Voltar</p>
        </S.OcultarButton>
      );
    }

    return (
      <S.OcultarButton onClick={() => setShowProducts(false)}>
        <p>Ocultar</p>
        <Icon icon="selectBottom" />
      </S.OcultarButton>
    );
  };

  return (
    <S.BottomMenuContainer>
      <S.BottomMenu showProducts={showProducts}>
        <LiveChat
          sentMessages={sentMessages}
          live={live}
          className="videoChat"
          showProducts={showProducts ? "1" : "0"}
        />
        {!showProducts && (
          <>
            <S.TextContainer>
              <small onClick={() => setDenunciar(true)}>Denunciar</small>
              <small onClick={() => setShowProducts(true)}>Ver produtos</small>
            </S.TextContainer>
            <S.UpperButton
              showProducts={showProducts ? "1" : "0"}
              onClick={() => setShowProducts(true)}
            >
              <Dropdown />
            </S.UpperButton>
          </>
        )}
        <S.ProductsContainer showProducts={showProducts}>
          <Container>{renderHideButton()}</Container>
          <ProductSlider
            list={products}
            showLabel="day"
            setCarrinho={setCarrinho}
          />
        </S.ProductsContainer>
      </S.BottomMenu>
    </S.BottomMenuContainer>
  );
};

export default BottomMenu;
