import AnchorButton from 'components/AnchorButton';
import React, { useState, useEffect, useRef, useMemo, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './styled';

import { ReactComponent as HeartIcon } from 'assets/live/heart.svg';
import { ReactComponent as ShareIcon } from 'assets/live/share.svg';
import { ReactComponent as EyeIcon } from 'assets/live/eye.svg';
import { ReactComponent as CloseIcon } from 'assets/live/close.svg';
import { ReactComponent as HeartsIcon } from 'assets/live/hearts.svg';

import ReactDOMServer from 'react-dom/server';

import LiveLabel from 'components/LiveLabel';
import Modal from 'components/Modal';
import BottomMenu from './BottomMenu';
import Title from 'components/Title';
import Share from '../Share';
import LiveVideoPlayer from 'components/LiveVideoPlayer';

const NotLogged = ({ item }) => {

  const [live, setLive] = useState({});
  const location = useLocation();
  const history = useHistory();
  const [share, setShare] = useState(false);
  const fatherHearts = useRef();
  const [sentMessages, setSentMessages] = useState([]);

  useEffect(() => {
    setLive(item);
  }, [item]);

  const handleLike = () => {

    const father = fatherHearts.current;

    const element = ReactDOMServer.renderToStaticMarkup(
      <S.Hearts>
        <HeartsIcon />
      </S.Hearts>,
    );

    const children = document.querySelectorAll('.hearts');

    let htmlObject = document.createElement('div');
    htmlObject.classList.add('hearts');
    htmlObject.innerHTML = element;

    if (children.length <= 3) {
      father.append(htmlObject);
    } else {
      father.append(htmlObject);
      const oldElement = children[0];

      oldElement.remove();
    }

    setLive({
      ...live,
      likes: +live.likes + 1,
    });
  };

  return (
    live && (
      <S.NotLogged className="noselect">
        <S.Banner>
          <LiveVideoPlayer URLs={live.videoURLs} />
          <S.BannerHeader>
            <S.BannerHeaderTop>
              <div>
                <S.StoreName>{live.shop?.name + ' - ' + live.title}</S.StoreName>
              </div>
              {live.onAir && (
                <S.LabelsContainer>
                  <LiveLabel
                    text="Live!"
                    style={{ position: 'static' }}
                    background="pink"
                    textSize="1.6rem"
                    padding="4px 8px"
                  />
                  <S.ViewsLabel>
                    <p>{live.views || '0'}</p>
                    <EyeIcon />
                  </S.ViewsLabel>
                </S.LabelsContainer>
              )}
              <div onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
                <CloseIcon />
              </div>
            </S.BannerHeaderTop>
            <S.StoreLogo logo={live.shop?.logo}>
              {live.shop?.logo ? '' : live.shop?.name?.charAt(0) + live.shop?.name?.charAt(1)}
            </S.StoreLogo>
          </S.BannerHeader>
          <S.BannerActions>
            <S.HeartsContainer ref={fatherHearts}>
              <HeartIcon onClick={() => handleLike()} style={{ cursor: 'pointer' }} />
            </S.HeartsContainer>
            <S.LikesText>{live.likes || '0'}</S.LikesText>
            <div onClick={() => setShare(true)} style={{ cursor: 'pointer' }}>
              <ShareIcon />
            </div>
          </S.BannerActions>
          <S.BottomContainer>
            <BottomMenu live={live} sentMessages={sentMessages} item={item} />
          </S.BottomContainer>
        </S.Banner>
        <S.ButtonContainer>
          <AnchorButton
            text="Clique para participar"
            background="primary"
            path={{ pathname: '/login', state: { prevPath: location.pathname } }}
          />
        </S.ButtonContainer>
        {share && (
          <Modal onClick={() => setShare(false)}>
            <Title as="p">Compartilhe</Title>
            <Share selected={live} />
          </Modal>
        )}
      </S.NotLogged>
    )
  );
};

export default NotLogged;
