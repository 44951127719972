import React from 'react'

import * as S from './styled'

// eslint-disable-next-line react/display-name
const Input = React.forwardRef((props, ref) => {
  const {
    disabled,
    value,
    type = 'text',
    placeholder,
    error,
    handleChange,
    onChange,
    name,
    required,
    ...rest
  } = props

  return (
    <S.InputContainer>
      <S.Input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => (handleChange ? handleChange(e.target) : onChange(e))}
        name={name}
        error={error}
        ref={ref}
        {...rest}
      />
      {required && <div>*</div>}
    </S.InputContainer>
  )
})

export default Input
