import BackBar from 'components/BackBar';
import Button from 'components/V2/Button';
import Done from 'components/Done';
import Footer from 'components/Footer';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmpresaDados from './Steps/Dados';
import EmpresaProdutos from './Steps/Produto';
import EmpresaProdutoDetalhes from './Steps/ProdutoDetalhes';
import StepsLine from './StepsLine';
import api from 'services/api';
import * as S from './styled';
import EditarProduto from './Steps/EditarProduto';
import HeaderCheckout from 'components/HeaderCheckout';
import Text from 'components/V2/Text';
import IconComponent from 'components/V2/Icon';

const EmpresaAdicionarEvento = () => {
  const [copied, setCopied] = useState(false);
  const [productToEdit, setProductToEdit] = useState('');
  const history = useHistory();
  const [account, setAccount] = useState({
    banner: '',
    name: '',
    ugongInstalacoes: false,
    date: '',
    hour: '',
    products: [],
    loading: false,
    error: false,
  });

  const [steps, setSteps] = useState(0);

  useEffect(() => {
    const getEvent = async () => {
      if(account?.eventId){
        if(!account?.eventNameURL && !account.eventProtoId){
          const response = await api.get('/events/getStuff', {
            params: { _id: account.eventId }
          });
          setAccount({
            ...account,
            eventNameURL: response.data.content.shop.nameURL,
            eventProtoId: response.data.content.protoId
          })
        }
      }
    }
    getEvent();    
  }, [account])

  const handleRedirectToLive = () => {
    history.push(`/live/${account.eventNameURL}/${account.eventProtoId}`);
  };

  const handleShare = async () => {
    const url = `https://ugong.com.br/live/${account.eventNameURL}/${account.eventProtoId}`;

    if (navigator.share) {
      navigator
        .share({
          title: 'Ugong',
          text: 'Venha assistir a minha live',
          url: url,
        })
        .catch((error) => console.log('Error sharing', error));
    } else {
      const textField = document.createElement('textarea');
      textField.innerText = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      alert('Link copiado!');
      textField.remove();

      setCopied(true);
    }
  };

  return (
    <>
      {/* {steps !== 4 && (
        <BackBar
          type="back"
          path={steps === 0 || steps === 4 ? '/' : '/empresa/adicionar-evento'}
          shadow={true}
          text="Voltar"
          onClick={() => setSteps((el) => el - 1)}
        />
      )} */}
      <S.CadastroEmpresa>
        <StepsLine step={steps === 0 ? steps : 1} />
        {steps === 0 && (
          <EmpresaDados
            dados={account}
            handleChange={({ name, value }) => {
              setAccount({
                ...account,
                [name]: value,
              });
            }}
            setDados={setAccount}
            setSteps={setSteps}
          />
        )}
        {steps === 1 && (
          <EmpresaProdutos
            dados={account}
            handleChange={({ name, value }) => {
              setAccount({
                ...account,
                [name]: value,
              });
            }}
            setDados={setAccount}
            setSteps={setSteps}
            setProductToEdit={setProductToEdit}
          />
        )}
        {steps === 2 && (
          <EmpresaProdutoDetalhes
            dados={account}
            handleChange={({ name, value }) => {
              setAccount({
                ...account,
                [name]: value,
              });
            }}
            setDados={setAccount}
            setSteps={setSteps}
            productToEdit={productToEdit}
          />
        )}

        {steps === 3 && (
          <EditarProduto
            setStep={setSteps}
            productId={productToEdit}
            dados={account}
            setDados={setAccount}
          />
        )}
        {steps === 4 && (
          <>
            <HeaderCheckout
              backAction={() => {
                history.push('/');
              }}
            />
            <S.DoneContainer>
              <S.WrapText>
                <IconComponent icon="calendarCheck" />
                <Text type="bodyEnphasis">Evento cadastrado com sucesso</Text>
              </S.WrapText>

              <div>
                <Button handleClick={() => handleRedirectToLive()} icon="cam">
                  Acesse a live
                </Button>
                <Button
                  handleClick={() => handleShare()}
                  icon="shareMore"
                  styleOfButton="outlineSecondary"
                >
                  {copied ? 'Link copiado!' : 'Clique para compartilhar o link'}
                </Button>
              </div>
            </S.DoneContainer>
          </>
        )}
      </S.CadastroEmpresa>
      <Footer showTop={true} />
    </>
  );
};

export default EmpresaAdicionarEvento;
