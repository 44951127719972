import React from 'react';
import HeaderCheckout from 'components/HeaderCheckout';

import * as S from './styled';
import Container from 'components/V2/Container';
import { useProducts } from 'contexts/ProductsContext';
import Text from 'components/V2/Text';
import SelectForm from 'components/V2/Select';
import Button from 'components/V2/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Schema } from './validation';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';

const ChangeVariation = () => {
  const {
    selectedProduct: product,
    setSelectedProduct: setProduct,
    setShowChangeVariation,
  } = useProducts();

  const { replaceItem } = useCarrinhoContext();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    setValue('hasVariation', product.hasVariation);
    setValue('quantity', {
      label: `Quantidade: ${product.selectedQuantity}`,
      value: product.selectedQuantity,
    });
    setValue('color', {
      label: `Cor: ${product.selectedColor}`,
      value: product.selectedColor,
    });
    setValue('size', {
      label: `Tamanho: ${product.selectedSize}`,
      value: product.selectedSize,
    });
  }, [product, setValue]);

  const renderCoin = (value) => {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const photo = product?.photos?.length ? product?.photos[0] : '';

  const color = watch('color');
  const size = watch('size');

  useEffect(() => {
    setValue('quantity', {
      label: `Quantidade: 1`,
      value: 1,
    });
  }, [color, size]);

  const onSubmit = (data) => {
    let newItem = {
      ...product,
      selectedQuantity: data?.quantity?.value,
    };

    if (newItem.hasVariation) {
      if (data?.color?.value) {
        newItem.selectedColor = data?.color?.value;
      }
      if (data?.size?.value) {
        newItem.selectedSize = data?.size?.value;
      }
    }

    replaceItem(product.index, newItem);
    setShowChangeVariation(false);
  };

  const renderQuantityOptions = () => {
    let maxQuantity = 0;

    if (product.hasVariation && !product?.variations?.length) {
      return [];
    }

    if (!product.hasVariation && product?.variations?.length) {
      maxQuantity = product?.variations[0].quantity;
    }

    let variations = product?.variations;
    if (size?.value) {
      variations = variations.filter((el) => el.size === size?.value);
    }

    if (color?.value) {
      variations = variations.filter((el) => el.color === color?.value);
    }

    if (!variations?.length) {
      return [];
    }

    const item = variations[0];
    const options = [];

    if (item) {
      maxQuantity = item.quantity;
    }

    for (let index = 1; index <= maxQuantity; index++) {
      options.push({
        label: `Quantidade: ${index}`,
        value: index,
      });
    }

    return options;
  };

  const renderColorOptions = () => {
    if (product.hasVariation && !product?.variations?.length) {
      return [];
    }

    if (!product.hasVariation) {
      return [];
    }

    let item = product?.variations;

    if (size?.value) {
      item = item.filter((el) => el.size === size?.value);
    }

    item = item
      .map((item) => item.color)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
      .map((item) => ({
        label: `Cor: ${item}`,
        value: item,
      }));

    return item;
  };

  const renderSizeOptions = () => {
    if (product.hasVariation && !product?.variations?.length) {
      return [];
    }

    if (!product.hasVariation) {
      return [];
    }

    let item = product?.variations;

    if (color?.value) {
      item = item.filter((el) => el.color === color?.value);
    }

    item = item
      .map((item) => item.size)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
      .map((item) => ({
        label: `Tamanho: ${item}`,
        value: item,
      }));
    return item;
  };

  return (
    <S.ProdutoContainer>
      <HeaderCheckout
        title="Alterar produto"
        icon="change"
        backAction={() => {
          setShowChangeVariation(false);
        }}
      />

      <Container>
        <S.WrapContent>
          <S.WrapPhoto>
            <img src={photo} alt="" />
          </S.WrapPhoto>
          <S.WrapText>
            <Text>{product?.title}</Text>
            <Text type="bodyEnphasis">
              {renderCoin(product?.promotionalPrice || product?.originalPrice)}
            </Text>
          </S.WrapText>
        </S.WrapContent>

        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="hasVariation"
            render={({ field }) => <input type="hidden" {...field} />}
          />

          <Controller
            control={control}
            name="quantity"
            render={({ field }) => (
              <SelectForm
                {...field}
                placeholder="Quantidade"
                error={errors.quantity}
                options={renderQuantityOptions()}
              />
            )}
          />

          {product.hasVariation && (
            <Controller
              control={control}
              name="color"
              render={({ field }) => (
                <SelectForm
                  {...field}
                  placeholder="Cor"
                  error={errors.color}
                  options={renderColorOptions()}
                />
              )}
            />
          )}

          {product.hasVariation && (
            <Controller
              control={control}
              name="size"
              render={({ field }) => (
                <SelectForm
                  {...field}
                  placeholder="Tamanho"
                  error={errors.size}
                  options={renderSizeOptions()}
                />
              )}
            />
          )}

          <Button type="submit" icon="save">
            Salvar edição
          </Button>
        </S.Form>
      </Container>
    </S.ProdutoContainer>
  );
};

export default ChangeVariation;
