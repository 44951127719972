import styled, { keyframes } from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

import banner from '../banner.png';

export const Streamer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 0.1fr;
`;

export const Banner = styled.div`
  background: black;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BannerHeader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 19px 0px;
`;

export const StoreName = styled.p`
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
`;

export const BannerHeaderTop = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerActions = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  max-width: 69px;
  min-width: 69px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.54);
  border-radius: 25px 0px 0px 25px;

  padding: 25px 23px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LikesText = styled.p`
  color: white;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: 1.5rem;
  margin: 5px 0px 14px;
`;

export const ButtonContainer = styled.div`
  background-color: #3f3f3f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  input {
    background-color: #292929;
    padding: 12px 14px;
    border-radius: 25px;
    color: white;
    margin-right: 9px;
    font-size: 15px;
    width: 295px;
    max-width: 295px;
    margin-right: 9px;

    &::placeholder {
      color: #aaaaaa;
      font-weight: 500;
    }
  }
`;

export const StoreLogo = styled.div`
  background: ${({ logo }) => (logo ? `url(${logo})` : 'black')} no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #adadad;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  font-size: 2.4rem;
  font-weight: 200;
`;

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewsLabel = styled.div`
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  margin: 0px 20px 0px 9px;

  p {
    color: #8a8a8a;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 4px;
  }

  svg {
    position: relative;
    top: -1px;
  }
`;

export const BagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 14px;

  p {
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
    margin-top: 5px;
  }
`;

export const HeartsContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const fadeIn = keyframes`
   0% {
    opacity: 0;
    top: -35px;
  }

  50% {
    opacity: 1;
  }

   100% {
    opacity: 0;
    top: -65px;
  }
`;

export const Hearts = styled.div`
  position: absolute;
  top: -35px;
  left: -12px;
  z-index: -1;
  animation: ${fadeIn} 2.5s ease-out forwards;
`;

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
`;

export const PlayButton = styled.div`
  z-index: 1000;
  position: absolute;
  top: 45%;
  left: 27%;
  margin-top: -50px;
  margin-left: -50px;
  background-color: ${C.PRIMARY_COLOR};
  padding: 19px 0px;
  width: 74.4%;
  max-width: 279px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  ${T.BUTTON_TEXT_EXTRA_LARGE};
  color: white;
  display: block;
  text-align: center;
  cursor: pointer;
  opacity: ${1};
  pointer-events: ${'auto'};
`;
