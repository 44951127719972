import HeaderCheckout from 'components/HeaderCheckout';
import CadastroCompleto from 'components/Usuario';
import Stepper from 'components/V2/Stepper';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CadastroEndereco from '../Endereco';
import api from 'services/api';
import { useEffect } from 'react';
import AddressForm from 'containers/AddressForm';
import Button from 'components/V2/Button';

import * as S from './styled';

export const CadastroComponent = ({ handleFinishProcess }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [address, setAddress] = useState([]);
  const [showAddressForm, setShowAddressForm] = useState(false);

  const finishInformation = () => {
    setCurrentStep(2);
  };

  const handleGetAddress = async () => {
    const response = await api.get('/user/getAddresses');
    setAddress(response?.data?.content?.length);
  };

  useEffect(() => {
    handleGetAddress();
  }, []);

  const renderAddress = () => {
    if (address === 0 || showAddressForm) {
      return (
        <AddressForm
          setAddress={() => setAddress(1)}
          setShowAddressForm={setShowAddressForm}
        />
      );
    }

    return (
      <S.WrapAddress>
        <CadastroEndereco
          changeQtdAddress={(value) => setAddress(value)}
          setShowAddressForm={setShowAddressForm}
        />
        <Button icon="linkBack" handleClick={handleFinishProcess}>
          Voltar para a compra
        </Button>
      </S.WrapAddress>
    );
  };

  return (
    <>
      <Stepper steps={2} currentStep={currentStep} />
      {currentStep === 1 && <CadastroCompleto setDone={finishInformation} />}
      {currentStep === 2 && renderAddress()}
    </>
  );
};

const Cadastro = () => {
  const history = useHistory();

  return (
    <S.Content>
      <HeaderCheckout
        title={''}
        backAction={() => {
          history.push('/perfil');
        }}
        backText="Voltar"
      />
      <CadastroComponent handleFinishProcess={() => history.push('/')} />
    </S.Content>
  );
};

export default Cadastro;
