import styled from 'styled-components';

export const Header = styled.div`
  padding: 15px 0px 7px;
  height: 62px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 40px 40px;
`;
