import styled from 'styled-components';
import * as C from 'constants/styles/colors';

export const Wrapper = styled.div`
    height: calc(100% - 141px);
    position: absolute;
    overflow: auto;
    width: 100%;
`;

export const EditContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
`;

export const ItemHandler = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 10px;
    gap: 20px;
    border-radius: 25px;
`;

export const Item = styled.div`
    display: flex;
    justify-content: flex-end;
    // get image from props
    background: url(${props => props.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 25px;
    width: 300px;
    height: 200px;
    padding: 10px;

    .item-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: white;

            &:hover {
                cursor: pointer;
            }
        }

        .item-edit {
            background: ${C.SECONDARY_COLOR};
        }

        .item-delete {
            background: ${C.PRIMARY_COLOR};
            font-size: 20px;
        }
    }

    .item-image {
        width: 300px;
        height: 300px;
        border-radius: 25px;
        object-fit: cover;
        object-position: center;
    }
`;

export const Title = styled.h1`
    font-size: 30px;
    font-weight: 500;
    color: ${C.PRIMARY_COLOR};
`;
