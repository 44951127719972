import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const AlarmButton = styled.button`
  background: ${C.SECONDARY_COLOR};
  padding: 8px 0px;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  p {
    margin-left: 9px;
  }

  &.created-alarm {
    background-color: ${C.PRIMARY_COLOR};
  }
`;
