import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from 'utils/scrollToTop';

import Login from 'pages/Login';
import Sobre from 'pages/Sobre';
import Menu from 'pages/Menu';
import Ajuda from 'pages/Ajuda';
import Cadastrar from 'pages/Cadastrar';
import Verificacao from 'pages/Verificacao';
import Conta from 'pages/Conta';
import Home from 'pages/Home';
import RestrictedRoute from './RestrictedRoute';
import Perfil from 'pages/Perfil';
import CadastroCompleto from 'pages/Cadastro/Completo';
import RecoverPassword from 'pages/Cadastro/ChangePassword';
import CadastroEndereco from 'pages/Cadastro/Endereco';
import CadastroCarteira from 'pages/Cadastro/Carteira';
import { EmpresaCadastro } from 'pages/Empresa/Cadastro';
import Live from 'pages/Live';
import Carrinho from 'pages/Carrinho';
import Pagamento from 'pages/Pagamento';
import Pedidos from 'pages/Pedidos';
import DetalhesPedidos from 'pages/Pedidos/Detalhes';
import EmpresaAdicionarEvento from 'pages/Empresa/AdicionarEvento';
import LoginMagic from 'pages/LoginMagic';
import Ugong from 'pages/Ugong';
import EditarLive from 'pages/EditarLive';
import EditarProdutos from 'pages/EditarLive/Produtos';
import EditarProduto from 'pages/EditarLive/Produtos/EditarProduto';
import AdicionarProduto from 'pages/EditarLive/Produtos/AdicionarProduto';

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/logging-in/:magic?" component={Ugong} />
        <Route exact path="/logging-in" component={Ugong} />
        <Route exact path="/" component={Home} />
        <Route exact path="/sobre" component={Sobre} />
        <Route exact path="/ajuda" component={Ajuda} />
        <Route exact path="/ajuda/:theme" component={Ajuda} />
        <Route exact path="/agenda" component={Home} />
        <Route exact path="/live/:shop/:protoId" component={Live} />
        <Route exact path="/live/:shop/:protoId/watch" component={Live} />
        <RestrictedRoute exact path="/login" component={LoginMagic} />
        <RestrictedRoute exact path="/menu" component={Menu} />
        <RestrictedRoute exact path="/cadastrar" component={Cadastrar} />
        <PrivateRoute exact path="/cadastrar/verificacao/:type/:id" component={Verificacao} />
        <PrivateRoute exact path="/cadastro/completo" component={CadastroCompleto} />
        <PrivateRoute exact path="/cadastro/alterar-senha" component={RecoverPassword} />
        <PrivateRoute exact path="/cadastro/enderecos" component={CadastroEndereco} />
        <PrivateRoute exact path="/cadastro/carteira" component={CadastroCarteira} />
        <PrivateRoute exact path="/conta/:step" component={Conta} />
        <PrivateRoute exact path="/perfil" component={Perfil} />
        <PrivateRoute exact path="/empresa/cadastro" component={EmpresaCadastro} />
        <PrivateRoute exact path="/empresa/cadastro/:id" component={EmpresaCadastro} />
        <PrivateRoute exact path="/carrinho" component={Carrinho} />
        <PrivateRoute exact path="/pagamento" component={Pagamento} />
        <PrivateRoute exact path="/pedidos/detalhes" component={DetalhesPedidos} />
        <PrivateRoute exact path="/pedidos" component={Pedidos} />
        <PrivateRoute exact path="/relatorios" component={Home} />
        <PrivateRoute exact path="/empresa/adicionar-evento" component={EmpresaAdicionarEvento} />
        <PrivateRoute exact path="/edit-event/:id" component={EditarLive} />
        <PrivateRoute exact path="/edit-event/:id/products" component={EditarProdutos} />
        <PrivateRoute exact path="/edit-event/:eventId/product/edit/:id" component={EditarProduto} />
        <PrivateRoute exact path="/edit-event/:eventId/product/add" component={AdicionarProduto} />
        <Route path="*" component={Home} />
      </Switch>
    </Router>
  );
};

export default Routes;
