import React from 'react';
import * as S from './styled';

import { ReactComponent as BackIcon } from '../../assets/common/back.svg';
import { ReactComponent as CancelIcon } from '../../assets/common/cancel.svg';
import { ReactComponent as CarrinhoIcon } from '../../assets/common/carrinho.svg';

import { Link } from 'react-router-dom';

const BackBar = ({ type, path, shadow, text, onClick, carrinho, pedidos }) => {
  return (
    <S.BackBar shadow={shadow}>
      <Link to={path} onClick={onClick}>
        <S.LinkContainer>
          {type === 'back' ? <BackIcon /> : <CancelIcon />}
          <S.Text>{text ? text : 'Voltar'}</S.Text>
        </S.LinkContainer>
      </Link>
      {carrinho && (
        <S.Cart to="/carrinho">
          <p>Carrinho</p>
          <CarrinhoIcon />
        </S.Cart>
      )}
      {pedidos && (
        <S.Cart to="/pedidos">
          <p>Meus pedidos</p>
        </S.Cart>
      )}
    </S.BackBar>
  );
};

export default BackBar;
