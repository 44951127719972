import React, { useEffect, useState } from 'react'
import {
  loadFromLocalstorage,
  saveToLocalStorage,
  removeFromLocalStorage,
} from 'utils/handleLocalStorage'
import { useAuthContext } from 'contexts/AuthContext'

import AddressForm from 'containers/AddressForm'
import BackBar from 'components/BackBar'
import Footer from 'components/Footer'
import Title from 'components/Title'
import Loader from 'components/Loader'

import * as S from './styled'
import { maskNumber } from 'utils/maskNumber'

import api from 'services/api'

import { ReactComponent as StarIcon } from 'assets/common/star.svg'
import { ReactComponent as DeleteIcon } from 'assets/common/close-red.svg'
import IconComponent from 'components/V2/Icon'
import Text from 'components/V2/Text'

const CadastroEndereco = ({ changeQtdAddress, setShowAddressForm }) => {
  const AuthContext = useAuthContext()
  const context = useAuthContext()
  const [addAddress, setAddAddress] = useState(false)
  const [loading, setLoading] = useState(true)
  const [address, setAddress] = useState([])

  const handleGetAddress = async () => {
    setLoading(true)
    const response = await api.get('/user/getAddresses')
    setTimeout(() => {
      setAddress(response.data.content)
      setLoading(false)
    }, 1000)
  }

  const handleFavorite = async (fav) => {
    setLoading(true)
    const copyData = await address?.map((el) => {
      if (
        el.zipCode === fav.zipCode &&
        el.street === fav.street &&
        el.city === fav.city &&
        el.state === fav.state &&
        el.country === fav.country
      ) {
        return {
          ...el,
          favorite: true,
        }
      } else {
        return {
          ...el,
          favorite: false,
        }
      }
    })
    await api.post(
      '/user/setFavoriteAddress',
      copyData.filter((x) => x.favorite === true)[0]
    )
    setAddress(copyData)
    setLoading(false)
  }

  const handleDelete = async (remove) => {
    setLoading(true)
    const copyData = [...address]
    const elIndex = copyData.findIndex(
      (el) =>
        el.zipCode === remove.zipCode &&
        el.street === remove.street &&
        el.city === remove.city &&
        el.state === remove.state &&
        el.country === remove.country
    )
    removeFromLocalStorage('@Ugong/user')
    const response = await api.post('/user/deleteAddress', copyData[elIndex])
    saveToLocalStorage('@Ugong/user', response.data.content)
    AuthContext.setUser((el) => {
      return {
        ...el,
        ...response.data.content,
      }
    })
    copyData.splice(elIndex, 1)
    setAddress(copyData)
    setLoading(false)
    if (changeQtdAddress) {
      changeQtdAddress(copyData.length)
    }
  }

  useEffect(() => {
    handleGetAddress()
  }, [])

  return (
    <>
      {!addAddress && (
        <>
          <S.AddressContainer>
            <S.TitleContainer>
              <IconComponent icon="location" />
              <Text type="bodyEnphasis">Endereços</Text>
            </S.TitleContainer>
            {loading && <Loader />}
            {!loading && (
              <>
                {address?.map((el, index) => {
                  return (
                    <S.AddressCard key={index}>
                      <p>
                        {el.street}, {el.number} - {el.city} - {el.state}
                      </p>

                      <S.ButtonFav
                        onClick={() => handleFavorite(el)}
                        favorite={el.favorite}
                        title="Favoritar"
                      >
                        <IconComponent icon="check" />
                      </S.ButtonFav>

                      <S.ButtonEdit
                        onClick={() => handleFavorite(el)}
                        title="Editar"
                      >
                        <IconComponent icon="pencil" />
                      </S.ButtonEdit>
                      <S.RemoveButton
                        onClick={() => handleDelete(el)}
                        title="Remover"
                      >
                        <IconComponent icon="cancel" />
                      </S.RemoveButton>
                    </S.AddressCard>
                  )
                })}
                <S.AddressAddItem>
                  <S.AddButton onClick={() => setShowAddressForm(true)}>
                    Adicionar Endereço
                  </S.AddButton>
                </S.AddressAddItem>
              </>
            )}
          </S.AddressContainer>
        </>
      )}

      <Footer showTop={true} />
    </>
  )
}

export default CadastroEndereco
