import React, { useEffect, useState } from 'react';

import * as S from './styled';

import { ReactComponent as DeleteIcon } from 'assets/common/remove.svg';
import { convertMoney } from 'utils/convertMoney';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import InputNumber from 'components/V2/InputNumber';
import Text from 'components/V2/Text';
import { useProducts } from 'contexts/ProductsContext';

const ProductCarrinho = ({ product, index }) => {
  const { upgradeQuantity, removeFromCarrinho } = useCarrinhoContext();
  const [selectedQuantity, setSelectedQuantity] = useState(
    product.selectedQuantity || 1
  );

  const { setSelectedProduct, setShowChangeVariation } = useProducts();

  useEffect(() => {
    if (selectedQuantity === 0) {
      removeFromCarrinho(index);
    } else {
      upgradeQuantity(index, selectedQuantity);
    }
  }, [selectedQuantity, index]);

  useEffect(() => {
    setSelectedQuantity(product.selectedQuantity || 1);
  }, [product.selectedQuantity]);

  const renderVariation = () => {
    if (!product.hasVariation) return '';
    let text = '';

    if (product.selectedColor) {
      text += `Cor: ${product.selectedColor}, `;
    }
    if (product.selectedSize) {
      text += `Tamanho: ${product.selectedSize}, `;
    }
    if (product.selectedQuantity) {
      text += `Quantidade: ${product.selectedQuantity}`;
    }

    return text;
  };

  const getPrice = () => {
    if (product?.totalPrice) {
      return product?.totalPrice;
    }

    const price = product.promotionalPrice || product.originalPrice;
    const total = parseFloat(price) * product.selectedQuantity;

    return total;
  };

  return (
    <S.ProductCarrinho>
      <S.ProductCarrinhoContent>
        <S.ProductCarrinhoData>
          <div>
            <S.ProductCarrinhoIcon background={product.photos[0]} />
          </div>
          <div>
            <S.ProductName>{product.title}</S.ProductName>
            <S.WrapVariation>
              <Text type="small">{renderVariation()}</Text>
            </S.WrapVariation>

            <S.ChangeDetails
              onClick={() => {
                setSelectedProduct({
                  ...product,
                  index,
                });
                setShowChangeVariation(true);
              }}
            >
              Alterar
            </S.ChangeDetails>

            <S.CarrinhoQuantity available={product.hasStock ? 1 : 0}>
              {product.hasStock
                ? `Restam ${product.stockAmount} un.`
                : 'Indisponível'}
            </S.CarrinhoQuantity>
          </div>
        </S.ProductCarrinhoData>
      </S.ProductCarrinhoContent>
      <S.RemoveButton onClick={() => removeFromCarrinho(index)}>
        Excluir
        <DeleteIcon />
      </S.RemoveButton>
      <S.ProductCarrinhoQuantity>
        <S.WrapInputNumber>
          <Text type="label">Quantidade</Text>
          <InputNumber
            height="28px"
            maxQuantity={product.stockAmount}
            setValue={setSelectedQuantity}
            value={selectedQuantity}
          />
        </S.WrapInputNumber>
        <S.Price>{convertMoney(getPrice())}</S.Price>
      </S.ProductCarrinhoQuantity>
    </S.ProductCarrinho>
  );
};

export default ProductCarrinho;
