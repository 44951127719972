import React, { useEffect, useState } from 'react';

import * as S from '../ProductCarrinho/styled';

import { ReactComponent as DeleteIcon } from 'assets/common/remove.svg';
import { convertMoney } from 'utils/convertMoney';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import Text from 'components/V2/Text';
import { useProducts } from 'contexts/ProductsContext';

const ProductPagamento = ({ product, index, canEdit = true }) => {
  const { upgradeQuantity, removeFromCarrinho } = useCarrinhoContext();
  const [selectedQuantity, setSelectedQuantity] = useState(
    product.selectedQuantity
  );

  const { setSelectedProduct, setShowChangeVariation } = useProducts();

  useEffect(() => {
    if (canEdit) {
      if (selectedQuantity === 0) {
        removeFromCarrinho(index);
      } else {
        upgradeQuantity(index, selectedQuantity);
      }
    }
  }, [index, selectedQuantity]);

  const renderVariation = () => {
    if (!product.hasVariation) return '';
    let text = '';
    if (product.selectedColor) {
      text += `Cor: ${product.selectedColor}, `;
    }
    if (product.selectedSize) {
      text += `Tamanho: ${product.selectedSize}, `;
    }
    if (product.selectedQuantity) {
      text += `Quantidade: ${product.selectedQuantity}`;
    }
    return text;
  };

  const getPrice = () => {
    if (product?.totalPrice) {
      return product?.totalPrice;
    }

    const promotionalPrice = parseFloat(product.promotionalPrice);
    const originalPrice = parseFloat(product.originalPrice);

    const price = isNaN(promotionalPrice) ? originalPrice : promotionalPrice;
    const total = parseFloat(price) * product.selectedQuantity;

    return total;
  };
  return (
    <S.ProductCarrinho>
      <S.ProductCarrinhoContent>
        <S.ProductCarrinhoData>
          <div>
            <S.ProductCarrinhoIcon
              background={product?.photos?.length ? product?.photos[0] : ''}
            />
          </div>
          <div>
            <S.ProductName>{product.title}</S.ProductName>
            <S.WrapVariation>
              <Text type="label">{renderVariation()}</Text>
            </S.WrapVariation>
          </div>
        </S.ProductCarrinhoData>
      </S.ProductCarrinhoContent>
      {canEdit && (
        <S.RemoveButton onClick={() => setSelectedQuantity(0)}>
          Excluir
          <DeleteIcon />
        </S.RemoveButton>
      )}
      <S.ProductCarrinhoQuantity>
        <div>
          {canEdit && (
            <S.ChangeDetails
              onClick={() => {
                setSelectedProduct({
                  ...product,
                  index,
                });
                setShowChangeVariation(true);
              }}
            >
              Alterar
            </S.ChangeDetails>
          )}
        </div>

        <S.Price>{convertMoney(getPrice())}</S.Price>
      </S.ProductCarrinhoQuantity>
    </S.ProductCarrinho>
  );
};

export default ProductPagamento;
