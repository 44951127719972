import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { handleCNPJ, handleLength } from 'utils/handleRegex';

import * as S from './styled';
import api from 'services/api';

import Input from 'components/Input';
import Button from 'components/V2/Button';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import InputWithMask from 'components/InputMask';
import SelectGrey from 'components/SelectGrey';

import { truncate } from 'utils/handleString';

const PixPaymentForm = ({ setAddPix, setCarteira, carteira }) => {
  const [tribute, setTribute] = useState(false);
  const [dados, setDados] = useState({
    pix: '',
    type: 'pix',
    validation: true,
    favorite: true,
    loading: false,
    error: false,
  });

  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setDados({
        ...dados,
        error: true,
      });
    } else {
      //const response = await api.post('/shops/bankAccount', dados);
      try {
        setDados({
          ...dados,
          error: false,
        });
        setCarteira([...carteira, dados]);
        setAddPix(false);
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleChange = ({ name, value }) => {
    setDados({
      ...dados,
      [name]: value,
    });
  };

  const handleError = () => {
    const { pix } = dados;
    return !handleLength(pix, 'bigger', 0);
  };

  return (
    <>
      <S.InputsContainer>
        <S.Form>
          <Input
            type="text"
            placeholder="PIX"
            value={dados.pix}
            handleChange={handleChange}
            error={dados.error}
            name="pix"
            required={true}
          />

          {dados.error && (
            <S.ErrorMessageContainer>
              <ErrorMessage>Digite dados válidos</ErrorMessage>
            </S.ErrorMessageContainer>
          )}
        </S.Form>

        <S.ButtonContainer>
          {!dados.loading && (
            <Button
              icon="arrowNext"
              styleOfButton="primaryRed"
              disabled={dados.pix.length <= 0}
              handleClick={handleRegister}
            >
              Continuar
            </Button>
          )}
          {dados.loading && <Loader />}
        </S.ButtonContainer>
      </S.InputsContainer>
    </>
  );
};

export default PixPaymentForm;
