import { Button } from 'components/Button/styled';
import styled from 'styled-components';

export const DoneRegister = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 25px 0px 50px;
  gap: 20px;
`;

export const Zindex = styled.div`
  position: relative;
  z-index: 9999999999;
`;

export const Concluir = styled.div`
  overflow: scroll;
  height: calc(100vh - 100px);
  margin-top: 100px;
  padding-bottom: 300px;

  position: relative;
  z-index: 999999;
`;

export const PagamentoContent = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
`;

export const TextContainer = styled.div`
  margin-bottom: 20px;
  line-height: 2.5rem;
`;

export const TitleContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;

  color: #545454;

  margin-bottom: 20px;
`;

export const StepsToPay = styled.ol`
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  list-style: auto;
  color: #545454;

  padding-left: 40px;
  margin-bottom: 20px;

  li {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #545454;

    margin-bottom: 5px;
  }
`;

export const WrapButton = styled.div`
  margin-bottom: 30px;

  ${Button} {
    margin: auto;
    width: 200px;
    height: 37px;
    font-size: 15px;
  }
`;

export const WrapImage = styled.div`
  img {
    margin: auto;
    width: 200px;
    object-fit: cover;
    display: block;
  }
`;

export const GreyBar = styled.div`
  background: #f4f4f4;
  color: #8a8a8a;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  margin: 0 auto 30px;
  text-align: center;

  overflow-wrap: break-word;

  h5 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;
