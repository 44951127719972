import React, { useEffect, useState } from 'react';
import Text from 'components/Text';

import * as S from './styled';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import { convertMoney } from 'utils/convertMoney';

const CartHeader = () => {
  const { carrinho, getMacro } = useCarrinhoContext();

  const [subtotal, setSubtotal] = useState('');
  const [quantity, setQuantity] = useState('');

  useEffect(() => {
    const dataMacro = getMacro();

    setQuantity(dataMacro.quantity);
    setSubtotal(dataMacro.subtotal);
  }, [carrinho, getMacro]);

  return (
    <>
      <S.CarrinhoHeader>
        <S.CarrinhoQuantity>
          {quantity} {quantity > 1 ? 'itens' : 'item'}
        </S.CarrinhoQuantity>
        <S.CarrinhoSubtotal>
          <Text as="div">Subtotal</Text>
          <Text as="p">{convertMoney(subtotal)}</Text>
        </S.CarrinhoSubtotal>
      </S.CarrinhoHeader>
    </>
  );
};

export default CartHeader;
