import React, { useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Input from 'components/V2/Input';
import Button from 'components/V2/Button';
import * as S from './styled';
import ErrorMessage from 'components/ErrorMessage';
import InputWithMask from 'components/InputMask';
import { useAuthContext } from 'contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { handleLength } from 'utils/handleRegex';
import Loader from 'components/Loader';
import Text from 'components/V2/Text';
import { CondicoesUso, Privacidade } from 'components/Terms';
import IconComponent from 'components/V2/Icon';
import Container from 'components/V2/Container';
import api from 'services/api';

const Login = () => {
  const AuthContext = useAuthContext();
  const history = useHistory();
  const [toggle, setToggle] = useState('email');

  const [terms, setTerms] = useState({
    privacidade: false,
    uso: false,
  });
  const [login, setLogin] = useState({
    name: '',
    email: '',
    phone: '',
    error: false,
    loading: false,
  });

  const handleChange = ({ name, value }) => {
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    setLogin({
      ...login,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setLogin({
        ...login,
        error: true,
      });
    } else {
      try {
        await AuthContext.login({
          login: login[toggle],
          name: login.name,
          type: toggle,
        });

        setLogin({
          ...login,
          error: false,
        });
      } catch (err) {
        setLogin({
          ...login,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleError = () => {
    const { email, phone } = login;

    if (
      !handleLength(email, 'bigger', 3) &&
      !handleLength(phone, 'bigger', 3)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handleToggle = () => {
    if (toggle === 'email') {
      setToggle('phone');
      setLogin({
        ...login,
        email: '',
        name: '',
        error: false,
      });
    } else {
      setToggle('email');
      setLogin({
        ...login,
        phone: '',
        error: false,
      });
    }
  };

  return (
    <>
      <S.Login>
        <div>
          <BackBar type="back" path="/menu" shadow={true} text="Voltar" />
          <Container>
            <S.TitleContainer>
              <Text type="title">Acesse a Ugong</Text>
            </S.TitleContainer>
            <S.InputsContainer>
              <S.Form onSubmit={onSubmit}>
                {toggle === 'phone' && (
                  <InputWithMask
                    handleChange={handleChange}
                    placeholder="Insira o telefone"
                    type="text"
                    value={login.phone}
                    error={login.error}
                    name="phone"
                    mask={'+55 (99) 99999-9999'}
                  />
                )}
                {toggle === 'email' && (
                  <>
                    <Input
                      placeholder="Insira o nome"
                      type="text"
                      value={login.name}
                      handleChange={handleChange}
                      error={login.error}
                      name="name"
                    />
                    <Input
                      placeholder="Insira o e-mail"
                      type="email"
                      value={login.email}
                      handleChange={handleChange}
                      error={login.error}
                      name="email"
                    />
                  </>
                )}
              </S.Form>
              {login.error && (
                <ErrorMessage>
                  {toggle === 'email'
                    ? 'Email está incorreto'
                    : 'Telefone está incorreto.'}
                </ErrorMessage>
              )}
              <S.ButtonContainer>
                {!login.loading && (
                  <Button
                    icon="arrowRight"
                    disabled={
                      (login.email.length <= 0 || login.name.length < 2) && login.phone.length <= 5 
                    }
                    handleClick={handleLogin}
                  >
                    Continuar
                  </Button>
                )}
                {login.loading && <Loader />}
              </S.ButtonContainer>
              <S.WrapAnchor>
                <div>
                  <IconComponent icon="safe" />
                </div>
                <S.Anchor>
                  <S.Text>
                    Ao continuar, você concorda com a{' '}
                    <span
                      onClick={() => setTerms({ ...terms, privacidade: true })}
                    >
                      Politica de Privacidade
                    </span>{' '}
                    e as
                    <span onClick={() => setTerms({ ...terms, uso: true })}>
                      {' '}
                      Condições de Uso
                    </span>{' '}
                    da Ugong.
                  </S.Text>
                </S.Anchor>
              </S.WrapAnchor>
            </S.InputsContainer>
          </Container>
        </div>
      </S.Login>

      {terms.privacidade && (
        <Privacidade
          onClick={() => setTerms({ ...terms, privacidade: false })}
        />
      )}
      {terms.uso && (
        <CondicoesUso onClick={() => setTerms({ ...terms, uso: false })} />
      )}
      <Footer showTop={true} />
    </>
  );
};

export default Login;
