import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  loadFromLocalstorage,
  saveToLocalStorage,
} from 'utils/handleLocalStorage';
import api from 'services/api';

export const CarrinhoContext = createContext();

export const CarrinhoProvider = ({ children }) => {
  const [storeSelected, setStoreSelected] = useState({});
  const [carrinho, setCarrinho] = useState([]);
  const [carrinhoMacro, setMacroCarrinho] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // chamada carrinho

  useEffect(() => {
    const carrinhoData = getCarrinho();

    setCarrinho(carrinhoData);
  }, []);

  useEffect(() => {
    const subtotal = carrinho.reduce((a, b) => a + getPrice(b), 0); //originalPrice ou promotionalPrice
    const quantity = carrinho.reduce((a, b) => a + +b.selectedQuantity, 0);

    setMacroCarrinho({
      subtotal,
      quantity,
    });
  }, [carrinho]);

  const getPrice = (product) => {
    if (product?.totalPrice) {
      return product?.totalPrice;
    }

    const price = product.promotionalPrice || product.originalPrice;
    const total = parseFloat(price) * product.selectedQuantity;

    return total;
  };

  const getCarrinho = () => {
    const carrinhoData = loadFromLocalstorage('@Ugong/carrinho');

    return carrinhoData;
  };

  const replaceItem = async (index, product) => {
    const newCarrinho = [...carrinho];

    newCarrinho[index] = product;

    const response = await api.post('/carts/update', newCarrinho);
    setCarrinho(newCarrinho);
    saveToLocalStorage('@Ugong/carrinho', newCarrinho);
  };

  const upgradeQuantity = async (index, newQuantity) => {
    const newCarrinho = [...carrinho];
    const item = newCarrinho[index];

    if (newQuantity === item.selectedQuantity) {
      return;
    }

    item.selectedQuantity = newQuantity;

    newCarrinho[index] = item;

    const response = await api.post('/carts/update', newCarrinho);
    setCarrinho(newCarrinho);
    saveToLocalStorage('@Ugong/carrinho', newCarrinho);
  };

  const getMacro = () => {
    return carrinhoMacro;
  };

  const getVariation = (item, size, color) => {
    if (!item.hasVariation) {
      return true;
    }

    return item.size === size && item.color === color;
  };

  const getQuantityFromProduct = (product) => {
    if (!product.hasVariation) {
      return product.variations[0];
    }

    return product.variations.find((item) =>
      getVariation(item, product.selectedSize, product.selectedColor)
    );
  };

  const variationExistsInCart = (product) => {
    if (!product.hasVariation) {
      return carrinho.find((item) => item._id === product?._id) !== undefined;
    }

    let productsInCart = carrinho.filter((item) => item._id === product?._id);
    if (!productsInCart?.length) {
      return false;
    }

    if (product?.selectedColor) {
      productsInCart = productsInCart.filter(
        (item) => item.selectedColor === product.selectedColor
      );
    }

    if (product?.selectedSize) {
      productsInCart = productsInCart.filter(
        (item) => item.selectedSize === product.selectedSize
      );
    }

    return productsInCart?.length;
  };

  const addToCarrinho = async (product) => {
    let newCarrinho = carrinho || [];

    if (variationExistsInCart(product)) {
      return;
    }

    newCarrinho.push(product);

    const url = carrinho.length == 0 ? 'create' : 'update';
    await api.post(`/carts/${url}`, newCarrinho);

    setStoreSelected(product.shop._id);
    saveToLocalStorage('@Ugong/carrinho', newCarrinho);
    setCarrinho(newCarrinho);
  };

  const removeFromCarrinho = async (index) => {
    const newCarrinho = [...carrinho];
    newCarrinho.splice(index, 1);

    saveToLocalStorage('@Ugong/carrinho', newCarrinho);
    const response = await api.post('/carts/update', newCarrinho);
    setCarrinho(newCarrinho);
  };

  const cleanCart = async (shopId) => {
    const itemsFiltered = carrinho.filter((item) => item.shop._id === shopId);

    saveToLocalStorage('@Ugong/carrinho', itemsFiltered);
    setCarrinho(itemsFiltered);
    await api.post('/carts/update', itemsFiltered);
  };

  const cleanCartLocal = async (shopId) => {
    saveToLocalStorage('@Ugong/carrinho', []);
    setCarrinho([]);
  };

  const store = {
    isLoading,
    carrinho,
    getCarrinho,
    removeFromCarrinho,
    addToCarrinho,
    getMacro,
    upgradeQuantity,
    cleanCart,
    cleanCartLocal,
    replaceItem,
    cartStore: storeSelected,
  };

  return (
    <CarrinhoContext.Provider value={store}>
      {children}
    </CarrinhoContext.Provider>
  );
};

export const useCarrinhoContext = () => {
  const context = useContext(CarrinhoContext);
  if (context === undefined) {
    throw new Error(
      'useCarrinhoContext must be used within a CarrinhoProvider'
    );
  }
  return context;
};
