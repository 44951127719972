import styled from 'styled-components';

export const TextContainer = styled.div`
  line-height: 22px;
  margin-bottom: 25px;
`;

export const TitleContainer = styled.div`
  margin: 0px 0px 20px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

export const SuccessContainer = styled.div`
  width: calc(100% - 76px);
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  position: absolute;

  a {
    width: 100%;
  }
`;
