import React from 'react'
import * as S from './styled'

const ColorSelect = ({
  selectedColor,
  colors,
  setColor
}) => {
  return (
    <S.Wrap>
      {colors && colors.map(color => (
        <S.ProdutoColorsItem color={color} key={color} onClick={() => setColor(color)} selected={selectedColor === color} />
      ))}
    </S.Wrap>
  )
}

export default ColorSelect