import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto 264px;
  width: 74.4%;

  button {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 279px;
  margin-bottom: 30px;
`;

export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;
  width: 50%;
  min-width: 188px;

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  margin: 38px auto 20px;
  width: 75%;
`;

export const TitleContainerDone = styled.div`
  margin: 100px auto 50px;
  width: 55%;

  p {
    line-height: 35px;
  }
`;

export const LoaderContainer = styled.div`
  margin-top: 25px;
  height: 41px;
`;

export const CheckSent = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin: ${({ margin }) => margin};
  color: ${C.GREEN_CHECK};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const CategoryList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 65px;
`;

export const CategoryItem = styled.li`
  width: 100%;
  background: #efefef;
  border-radius: 20px;
  text-align: center;
  padding: 12px 0px;
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-bottom: 7px;
  font-weight: 500;
  transition: all 0.4s ease;

  &.selected {
    background: #97f2ff;
  }
`;
