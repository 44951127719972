import React from 'react';

import * as S from './styled';

const ProductLine = ({ el }) => {
  return (
    <S.ProductItem>
      <S.ProductItemContainer>
        <S.ProductItemText>
          <h2>{el.title}</h2>
          <p>{el.shop.name}</p>
        </S.ProductItemText>
        <S.ProductItemIcon background={el.photos[0]}>
          <div>{+el.selectedQuantity < 10 ? '0' + el.selectedQuantity : +el.selectedQuantity}</div>
        </S.ProductItemIcon>
      </S.ProductItemContainer>
    </S.ProductItem>
  );
};

export default ProductLine;
