import styled from 'styled-components';

export const SwiperContainer = styled.div`
  ${({ live }) =>
    live
      ? 'background: linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%);'
      : '#FFF'};
  padding: 12px 0px 34px 20px;
  overflow: hidden;
`;

export const SwiperTitle = styled.p`
  margin-bottom: 13px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #807f7f;
`;
