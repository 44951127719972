import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const LiveContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: -webkit-fill-available;
`;

export const Live = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`;

export const ContainerFixed = styled.div`
  background: white;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: scroll;
`;

export const PlayButton = styled.div`
  background-color: ${C.PRIMARY_COLOR};
  padding: 19px 0px;
  width: 74.4%;
  max-width: 279px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  ${T.BUTTON_TEXT_EXTRA_LARGE};
  color: white;
  display: block;
  text-align: center;
  cursor: pointer;
  opacity: ${1};
  pointer-events: ${'auto'};
`;
