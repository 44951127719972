import React from 'react';

import IconComponent from '../Icon';
import * as S from './styled';

const InputNumber = ({
  maxQuantity,
  setValue,
  value,
  height,
  width,
  minQuantity = 0,
}) => {
  return (
    <S.Container height={height} width={width}>
      <S.Button
        onClick={() => {
          setValue((value) => {
            if (minQuantity) {
              if (value - 1 >= minQuantity) return value - 1;
              return value;
            }

            if (value - 1 >= 0) {
              return value - 1;
            }
            return value;
          });
        }}
      >
        <IconComponent icon="minor" />
      </S.Button>
      <S.Text>{value?.toString()?.padStart(2, '0')}</S.Text>
      <S.Button
        onClick={() => {
          setValue((value) => {
            if (value + 1 <= maxQuantity) {
              return value + 1;
            }
            return value;
          });
        }}
      >
        <IconComponent icon="sum" />
      </S.Button>
    </S.Container>
  );
};

export default InputNumber;
