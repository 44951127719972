import styled from 'styled-components';

export const TextContainer = styled.div`
  margin: 30px auto 20px;
  width: 80%;
  line-height: 22px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 40px;

  button {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  position: relative;

  div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`;
