import BackBar from 'components/BackBar';
import Button from 'components/V2/Button';
import Footer from 'components/Footer';
import Text from 'components/Text';
import Title from 'components/Title';
import React, { useEffect, useState } from 'react';

import * as S from './styled';

import { ReactComponent as StoreIcon } from 'assets/common/store.svg';
import { ReactComponent as BagIcon } from 'assets/common/cart.svg';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import { convertMoney } from 'utils/convertMoney';
import ProductCarrinho from 'containers/ProductCarrinho';
import { useHistory } from 'react-router-dom';
import CartResume from 'components/CartResume';
import HeaderCheckout from 'components/HeaderCheckout';
import { useLiveContext } from 'contexts/LivesContext';

const Carrinho = ({ setCarrinho, setPagamento, live }) => {
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const { carrinho, getMacro, cleanCart, cartStore } = useCarrinhoContext();
  const { liveDetails } = useLiveContext();
  const history = useHistory();

  useEffect(() => {
    cleanCart(liveDetails?.shop?._id);
  }, [liveDetails]);

  useEffect(() => {
    setProducts(carrinho);

    if (carrinho.length) {
      setStore(carrinho[0].shop);
    }
  }, [carrinho, getMacro]);

  const renderProducts = (shopProducts) => {
    return shopProducts.map((el, index) => {
      return (
        <ProductCarrinho
          key={`${el._id}${el.selectedColor}${el.selectedSize}`}
          product={el}
          index={index}
        />
      );
    });
  };
  return (
    <>
      <HeaderCheckout
        title={'Carrinho'}
        icon={'cartOutline'}
        backAction={() => {
          if (setCarrinho) {
            setCarrinho(false);
          } else {
            history.push('/perfil');
          }
        }}
      />

      {products?.length <= 0 && (
        <S.CarrinhoVazio>
          <Text as="p">Seu carrinho está vazio</Text>
          {live && (
            <Button handleClick={() => setCarrinho(false)}>
              Voltar para a Live
            </Button>
          )}
          {!live && (
            <Button handleClick={() => history.push('/')}>
              Ir para o início
            </Button>
          )}
        </S.CarrinhoVazio>
      )}
      {products && products.length > 0 && (
        <S.Carrinho>
          <CartResume />

          <S.WrapProducts>
            <S.ShopDetails>
              <S.WrapShopName>
                <StoreIcon />
                <S.ShopName>Loja: {store.name}</S.ShopName>
              </S.WrapShopName>
              <S.ShopItems>Itens</S.ShopItems>
              {renderProducts(products)}
            </S.ShopDetails>
          </S.WrapProducts>
        </S.Carrinho>
      )}
      {products && products.length > 0 && (
        <S.DoneRegister>
          <Button
            disabled={!products && !products.length > 0}
            handleClick={() => {
              if (live) {
                setCarrinho(false);
                setPagamento(true);
              } else {
                history.push('/pagamento');
              }
            }}
            icon="arrowNext"
          >
            Finalizar Compra
          </Button>

          {live && (
            <Button
              icon="linkBack"
              styleOfButton="outlineSecondary"
              handleClick={() => {
                setCarrinho(false);
              }}
            >
              Voltar para a Live
            </Button>
          )}
        </S.DoneRegister>
      )}
    </>
  );
};

export default Carrinho;
