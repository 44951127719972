/* eslint-disable no-useless-escape */
export const handleEmail = (e) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(e).toLowerCase());
};

export const handlePassword = (e) => {};

export const handleLength = (e, func, length) => {
  const bigger = String(e).length > Number(length);
  const less = String(e).length < Number(length);
  return func === 'bigger' ? bigger : less;
};

export const isPhone = (phone) => {

  /// matches with:
  ///    {2 digits DDD + 1 space + 9 digits, where the first has to be '9'}
  ///
  /// e.g   99 999999999
  /// e.g   11 997996944
  /// e.g   13 912311234
  ///
  const regex = /([0-9]{2})\s9[0-9]{8}/;

  // remove all array itens from the phone string
  const toRemove = ['+55 ', '(', ')', '-'];
  const cleaned = toRemove.reduce((word, char) => word.replace(char, ''), phone || '');

  return regex.test(cleaned);
}

export const handlePhone = (e) => {
  //eslint-disable-next-line
  const re = /([0-9]{2,3})?([0-9]{2})([0-9]{4,5})([0-9]{4})/g;

  return re.test(e) && handleLength(e, 'bigger', 10);
};

export const handleCPF = (e) => {
  const re =
    /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;

  return re.test(String(e));
};

export const handleBirthDay = (e) => {
  const re = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g;
  const year = new Date().getFullYear();
  const bornYear = new Date(e).getFullYear();

  if (year - bornYear < 18) {
    return false;
  }

  return re.test(String(e));
};

export const handleIsString = (e) => {
  const re = /^[a-zA-Z ]*$/g;
  return re.test(String(e));
};

export const handleCNPJ = (e) => {
  const re = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

  return re.test(String(e));
};
