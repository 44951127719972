import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const Title = styled(`${({ as }) => as}`)`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`;
