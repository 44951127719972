import React, { useEffect, useState } from 'react';

import BackBar from 'components/BackBar';
import Button from 'components/Button';
import Text from 'components/V2/Text';
import CartResume from 'components/CartResume';
import Loader from 'components/Loader';

import { ReactComponent as BagIcon } from 'assets/common/cart.svg';

import * as S from './styled';

import PagamentoCarteira from './Carteira';

import PagamentoEndereco from './Endereco';
import PagamentoConcluir from './Concluir';
import PagamentoConcluirPix from './ConcluirPix';
import api from 'services/api';
import { useAuthContext } from 'contexts/AuthContext';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';

import SelectGrey from 'components/SelectGrey';
import CompleteProfile from 'components/CompleteProfile';
import HeaderCheckout from 'components/HeaderCheckout';
import IconComponent from 'components/V2/Icon';
import Revisao from './Revisao';

const Pagamento = ({ setCarrinho, setPagamento, live, product }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [pixData, setPixData] = useState({
    copyPast: '',
    qrCode: '',
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const { user } = useAuthContext();
  const { carrinho } = useCarrinhoContext();
  const [paymentMonthly, setPaymentMonthly] = useState('');

  const [error, setError] = useState({
    address: false,
    payment: false,
    paymentMonthly: false,
  });

  const [shouldOpenCompleteModal, setShouldOpenCompleteModal] = useState(
    user?.type === 'viewer'
  );

  const [isLoading, setIsLoading] = useState(false);

  let total = null;
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BRL',
  });
  total = carrinho.reduce(function (a, b) {
    return (
      a +
      Math.round(
        b['promotionalPrice']
          ? b['promotionalPrice'] * parseInt(b['selectedQuantity'])
          : b['originalPrice'] * parseInt(b['selectedQuantity'])
      )
    );
  }, 0);
  total = formatter
    .format(total)
    .replace(/\./g, '?')
    .replace(/,/g, '.')
    .replace(/\?/g, ',');

  const [step, setStep] = useState('revisao');

  const handleRegister = async () => {
    let errors = {};

    if (!selectedAddress) {
      errors.address = true;
    }

    if (!selectedPayment && selectedPaymentMethod === 'credit_card') {
      errors.payment = true;
    }

    if (selectedPaymentMethod === 'credit_card' && !paymentMonthly) {
      errors.paymentMonthly = true;
    }

    if (Object.keys(errors)?.length) {
      setError(errors);
      return;
    }

    setIsLoading(true);

    try {
      const body = {
        // live: live, // I don't know why was sending the live object
        address: selectedAddress,
        payment: selectedPayment,
        cart: carrinho?.map((item) => ({
          ...item,
          amount: item.selectedQuantity,
        })),
        data: {
          cpf: user?.doc,
          name: user?.fullName,
          storeName: '',
          parcela:
            selectedPaymentMethod === 'credit_card'
              ? paymentMonthly
              : 'à vista',
        },
        selectedPayment: selectedPaymentMethod,
        isDelivery: false,
      };

      const { data } = await api.post('/orders/create', body);

      if (selectedPaymentMethod === 'pix') {
        const charges = data.content.gatewayOrder.charges;

        if (charges.length == 0) {
          throw new Error('Ocorreu um erro no gateway');
        }

        const transactions = charges[0].last_transaction;
        setStep('pix-pay');
        setPixData({
          copyPast: transactions.qr_code,
          qrCode: transactions.qr_code_url,
        });
      } else {
        setStep('done');
      }
    } catch (err) {
      console.error(err);
      alert(`Pagamento não aprovado.`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPaymentMethods = () => {
    if (selectedPaymentMethod === 'pix') {
      return (
        <S.WrapLogo>
          <IconComponent icon="pixLogo" />
        </S.WrapLogo>
      );
    }

    if (!selectedPayment?.last_four_digits) {
      return <S.PaymentText>Selecione o cartão de crédito</S.PaymentText>;
    }

    return (
      <>
        <S.WrapLogo>
          <IconComponent icon={selectedPayment?.brand?.toLowerCase()} />
        </S.WrapLogo>
        <S.PaymentText>
          {selectedPayment?.brand +
            ' ... **** **** ' +
            selectedPayment?.last_four_digits}
        </S.PaymentText>
      </>
    );
  };

  return (
    <>
      <HeaderCheckout
        title={'Revise seu pedido'}
        icon={'cartOutline'}
        backAction={() => {
          // setCarrinho(true);
          // setPagamento(false);
          setStep('revisao');
        }}
      />
      <CartResume />
      <S.PagamentoContent>
        <S.AddContainer>
          <div>
            <Text type="bodyEnphasis">Seu produto será enviado para:</Text>
            <S.ContentButton
              onClick={() => setStep('endereco')}
              error={error?.address}
            >
              {!selectedAddress?.street ? (
                <Button>Adicionar Endereço</Button>
              ) : (
                <>
                  <S.PaymentText>
                    {selectedAddress.street}, {selectedAddress.number} -{' '}
                    {selectedAddress.city} - {selectedAddress.state}
                  </S.PaymentText>
                </>
              )}
            </S.ContentButton>
          </div>

          <div>
            <Text type="bodyEnphasis">Forma de pagamento:</Text>
            <S.ContentButton
              onClick={() => {
                if (selectedPaymentMethod !== 'pix') {
                  setStep('carteira');
                }
              }}
              error={error?.payment}
            >
              {!selectedPaymentMethod ? (
                <Button>Selecionar pagamento</Button>
              ) : (
                <div className="paymentAndAddress">
                  {renderPaymentMethods()}
                </div>
              )}
            </S.ContentButton>
          </div>

          {selectedPaymentMethod === 'pix' && (
            <S.List>
              <li>
                <S.PaymentText>
                  Após a finalização do pedido, um QR code e chave Pix será
                  gerado e você poderá pagar com Pix do seu Banco{' '}
                </S.PaymentText>
              </li>
            </S.List>
          )}
          {selectedPaymentMethod === 'credit_card' && (
            <SelectGrey
              name="parcela"
              placeholder="Selecione as parcelas aqui"
              optionList={[
                { parcela: total + ' à vista' },
                { parcela: total + ' em 2 parcelas' },
                { parcela: total + ' em 3 parcelas' },
                { parcela: total + ' em 4 parcelas' },
                { parcela: total + ' em 5 parcelas' },
                { parcela: total + ' em 6 parcelas' },
                { parcela: total + ' em 7 parcelas' },
                { parcela: total + ' em 8 parcelas' },
                { parcela: total + ' em 9 parcelas' },
                { parcela: total + ' em 10 parcelas' },
              ]}
              type="text"
              value={paymentMonthly}
              handleChange={({ value }) => setPaymentMonthly(value)}
              error={error?.paymentMonthly}
            />
          )}
        </S.AddContainer>
      </S.PagamentoContent>

      <S.DoneRegister>
        {isLoading && <Loader />}
        {!isLoading && (
          <Button
            // disabled={
            //   !(data && data.parcela) ||
            //   ((menuToggle === 'receber' && (!selectedAddress || !selectedPayment)) ||
            //   (menuToggle === 'retirar' && !selectedPayment))
            // }
            handleClick={() => handleRegister()}
          >
            Confirmar compra
          </Button>
        )}
      </S.DoneRegister>

      {step === 'revisao' && (
        <S.ContainerFixed>
          <Revisao
            setStep={setStep}
            setSelectedPayment={setSelectedPayment}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            goBack={() => {
              setCarrinho(true);
              setPagamento(false);
            }}
          />
        </S.ContainerFixed>
      )}

      {step === 'carteira' && (
        <S.ContainerFixed>
          <PagamentoCarteira
            live={live}
            setStep={setStep}
            setSelectedPayment={setSelectedPayment}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
          />
        </S.ContainerFixed>
      )}
      {step === 'endereco' && (
        <S.ContainerFixed>
          <PagamentoEndereco
            live={live}
            setStep={setStep}
            setSelectedAddress={setSelectedAddress}
          />
        </S.ContainerFixed>
      )}
      {step === 'pix-pay' && (
        <S.ContainerFixed>
          <PagamentoConcluirPix pixData={pixData} />
        </S.ContainerFixed>
      )}
      {step === 'done' && (
        <S.ContainerFixed>
          <PagamentoConcluir
            live={live}
            selectedAddress={selectedAddress}
            product={product}
            // type={menuToggle}
            setStep={setStep}
          />
        </S.ContainerFixed>
      )}

      {shouldOpenCompleteModal && (
        <CompleteProfile
          setPagamento={setPagamento}
          closeModal={setShouldOpenCompleteModal}
        />
      )}
    </>
  );
};

export default Pagamento;
