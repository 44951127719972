import styled from 'styled-components';

export const SlideItem = styled.div`
  position: relative;
  cursor: pointer;
`;

export const SlideCircle = styled.div`
  width: 93px;
  height: 93px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.35) 95.83%),
    ${({ thumb }) => (thumb ? `url(${thumb})` : 'grey')} no-repeat;
  border: 0.5px solid #c1c1c1;
  background-size: 100% 93px;
  position: relative;
  margin: 0 auto;
`;

export const ContainerSlideItemTitle = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const SlideItemTitle = styled.p`
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 1.3rem;
`;
export const SlideItemType = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0.6;
`;
