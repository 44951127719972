export const capitalize = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const truncate = (string) => {
  if (string.length > 30) {
    return string.substring(0, 30) + '...';
  }
  return string;
};

export const truncateWithout = (string, number) => {
  if (string.length > number) {
    return string.substring(0, number);
  }
  return string;
};
