import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';

import * as S from './styled';

import api from 'services/api';
import data from './data.json';
import Title from 'components/Title';
import LiveLabel from 'components/LiveLabel';
import { handleDate } from 'utils/handleDate';

import { ReactComponent as Clock } from 'assets/common/clock.svg';
import AlarmButton from 'components/AlarmButton';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { CalendarItem } from './CalendarItem';

const HomeAgenda = () => {
  const [lives, setLives] = useState({
    loading: true,
    livesList: [],
  });
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState({});

  const organizeLives = async () => {
    let arr = [{}];

    let result = await api.get('/events/getScheduled');
    result = result.data.content;
    result.forEach((el) => {
      if (!arr[0][el.scheduled]) {
        arr = [
          {
            ...arr[0],
            [el.scheduled]: [el],
          },
        ];
      } else {
        arr[0][el.scheduled] = [...arr[0][el.scheduled], el];

        const organizeHour = arr[0][el.scheduled].sort((a, b) => {
          const scheduledA = new Date(a.scheduled).getHours();
          const scheduledB = new Date(b.scheduled).getHours();
          return scheduledA - scheduledB;
        });

        arr[0][el.scheduled] = organizeHour;
      }
    });
    return arr;
  };

  const ListLives = () => {
    const { livesList } = lives;
    const arrLives = [];
    const size = Object.keys(livesList[0]).length;
    if (size <= 0) {
      return <Title>Nenhuma live agendada para os próximos dias</Title>;
    } else {
      livesList.forEach((el) => {
        const keys = Object.keys(el);

        for (let i = 0; i < keys.length; i++) {
          arrLives.push(
            <S.AgendaContainer key={keys[i]}>
              <S.LiveLabelContainer>
                <LiveLabel text={handleDate(keys[i])} />
              </S.LiveLabelContainer>
              <S.AgendaItemContainer>
                {el[keys[i]].map((item) => {
                  let d = new Date(item.scheduled);
                  let minutes = ('0' + d.getMinutes()).slice(-2);
                  const hour = ('0' + d.getHours()).slice(-2) + 'h' + (minutes == '00' ? '' : minutes);

                  return (
                    <S.AgendaItem key={item._id}>
                      <S.HourContainer>
                        <Clock />
                        <S.Hour>{hour}</S.Hour>
                      </S.HourContainer>
                      <S.ContentContainer>
                        <S.AgendaLiveTitleContainer>
                          <S.AgendaLiveLogo logo={item.shop.logo} />
                          <S.AgendaLiveTitle>{item.shop.name}</S.AgendaLiveTitle>
                        </S.AgendaLiveTitleContainer>
                        <AlarmButton handleClick={() => handleSelect(item)} id={`alarm-${item.id}`} />
                      </S.ContentContainer>
                    </S.AgendaItem>
                  );
                })}
              </S.AgendaItemContainer>
            </S.AgendaContainer>,
          );
        }
      });
    }

    return arrLives;
  };

  const handleSelect = async (el) => {
    setOpenModal(true);
    setSelected(el);
    try {
      // pode criar aqui a rota de agenda
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateAlarm = (item) => {
    const button = document.getElementById(`alarm-${item.id}`);
    // chamada de criação de alarme
    button.classList.add('created-alarm');
    const p = button.lastChild;

    p.innerHTML = 'Alarme criado';
  };

  useEffect(() => {
    setTimeout(async () => {
      setLives({
        loading: false,
        livesList: await organizeLives(),
      });
    }, 1500);
  }, []);

  return (
    <S.HomeContainer>
      {lives.loading && <Loader />}
      {!lives.loading && lives.livesList?.length <= 0 && (
        <S.NotFound>
          <Title>Busca não encontrada</Title>
        </S.NotFound>
      )}
      {!lives.loading && lives.livesList?.length > 0 && (
        <S.Found>
          <S.ListaLives>{ListLives()}</S.ListaLives>
        </S.Found>
      )}
      {openModal && (
        <Modal onClick={() => setOpenModal(false)}>
          <Title as="div">
            <Clock />
            <p style={{ marginLeft: 10 }}>Alerta</p>
          </Title>
          <Text style={{ marginTop: 20 }}>Adicione o evento em sua agenda para lembrar da hora da live.</Text>
          <CalendarItem selected={selected} handleCreateAlarm={handleCreateAlarm} />
        </Modal>
      )}
    </S.HomeContainer>
  );
};

export default HomeAgenda;
