import * as S from './styled';

import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/V2/Button';
import Text from 'components/V2/Text';
import HeaderCheckout from 'components/HeaderCheckout';
import { CadastroComponent } from 'pages/Cadastro/Completo';
import { useState } from 'react';

const CompleteProfile = ({ setPagamento, closeModal }) => {
  const [getStart, setGetStart] = useState(false);

  return (
    <S.Page>
      <HeaderCheckout
        title={''}
        backAction={() => {
          setPagamento(false);
        }}
        backText="Voltar"
      />

      {!getStart && (
        <>
          <S.WrapBody>
            <Text type="bodyEnphasis">Boas-vindas!</Text>

            <Text type="bodyEnphasis">
              Faltam poucos passos para finalizar sua compra!
            </Text>

            <Text type="bodyEnphasis">Termine de preencher seus dados! </Text>
          </S.WrapBody>

          <Button
            style={{ marginTop: 20 }}
            handleClick={() => setGetStart(true)}
            // handleClick={() => history.push('/cadastro/completo')}
            icon="userGo"
          >
            Continuar
          </Button>
        </>
      )}

      {getStart && (
        <S.WrapStepper>
          <CadastroComponent handleFinishProcess={() => closeModal(false)} />
        </S.WrapStepper>
      )}
    </S.Page>
  );
};

export default CompleteProfile;
