import React, { useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Button from 'components/Button';

import * as S from './styled';
import SocialLogin from 'containers/SocialLogin';
import ErrorMessage from 'components/ErrorMessage';
import RecoverPassword from 'containers/RecoverPassword';
import { handleEmail, handleLength, handlePhone } from 'utils/handleRegex';
import { useHistory } from 'react-router-dom';
import { CondicoesUso, Privacidade } from 'components/Terms';

const mock = {
  email: 'lucasliko@hotmail.com',
};

const Cadastrar = () => {
  const [register, setRegister] = useState({
    emailOrPhone: '',
    name: '',
    password: '',
    error: false,
    loading: false,
  });
  const [recoverPassword, setRecoverPassword] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const history = useHistory();
  const [terms, setTerms] = useState({
    privacidade: false,
    uso: false,
  });

  const handleChange = ({ name, value }) => {
    setRegister({
      ...register,
      [name]: value,
    });
  };

  const handleRegister = () => {
    if (handleError()) {
      const isEmail = handleEmail(register.emailOrPhone);

      history.push(`/cadastrar/verificacao/${isEmail ? 'email' : 'phone'}/12456789`);
    }
  };

  const handleError = () => {
    if (handleValidation()) {
      setRegister({
        ...register,
        error: true,
      });
      return false;
    } else {
      setRegister({
        ...register,
        error: false,
      });
      return true;
    }
  };

  const handleValidation = () => {
    const pass = handleLength(register.password, 'bigger', 8);
    const name = handleLength(register.name, 'bigger', 3);
    const isEmail = handleEmail(register.emailOrPhone);
    const isPhone = handlePhone(register.emailOrPhone);

    if (pass && name && (isEmail || isPhone)) {
      setEmailOrPhone(isEmail ? 'email' : 'phone');
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <BackBar type="back" path="/menu" shadow={true} text="Voltar" />
      <S.TitleContainer>
        <Title>Criar Conta</Title>
      </S.TitleContainer>
      <S.InputsContainer>
        <S.Form>
          <Input
            placeholder="Nome"
            type="text"
            value={register.nome}
            handleChange={handleChange}
            error={register.error}
            name="name"
          />
          <Input
            placeholder="Celular ou E-mail"
            type="text"
            value={register.emailOrPhone}
            handleChange={handleChange}
            error={register.error}
            name="emailOrPhone"
          />
          <Input
            placeholder="Senha 8 dígitos"
            type="password"
            value={register.password}
            handleChange={handleChange}
            error={register.error}
            name="password"
          />
          {register.error && <ErrorMessage className="error">Digite dados válidos</ErrorMessage>}
          <S.Text>
            Ao continuar, você concorda com a{' '}
            <span onClick={() => setTerms({ ...terms, privacidade: true })}>Politica de privacidade</span> e as
            <span onClick={() => setTerms({ ...terms, uso: true })}> Condições de Uso</span> da Ugong.
          </S.Text>
        </S.Form>

        <Button
          disabled={register.emailOrPhone.length <= 0 || register.password.length <= 0 || register.name.length <= 0}
          handleClick={handleRegister}
        >
          Continuar
        </Button>
        <S.Anchor margin="12px 0px 47px" to="/login">
          Já tem conta? <span>Faça login</span>
        </S.Anchor>
        {/* <SocialLogin /> */}
      </S.InputsContainer>
      {}
      {terms.privacidade && <Privacidade onClick={() => setTerms({ ...terms, privacidade: false })} />}
      {terms.uso && <CondicoesUso onClick={() => setTerms({ ...terms, uso: false })} />}
      <Footer showTop={true} />
    </>
  );
};

export default Cadastrar;
