import styled from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 60px;
`;

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;

  svg {
    width: 30px;
  }
`;

export const WrapTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 30px;
    object-fit: cover;
  }
`;

export const Spacer = styled.div`
  height: 30px;
  width: 30px;
  display: block;
`;

export const HeaderTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
`;

export const Content = styled.div`
  width: calc(100% - 60px);
  margin: auto;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1000px) {
    width: 1000px;
  }
`;
