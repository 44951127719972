import React from 'react';
import * as S from './styled';

const Button = ({ disabled, children, background, handleClick, style, notScroll }) => {
  const onHandleClick = (e) => {
    e.preventDefault();
    handleClick();

    if (!notScroll) window.scrollTo(0, 0);
  };

  return (
    <S.Button style={style} disabled={disabled} background={background} onClick={(e) => onHandleClick(e)}>
      {children}
    </S.Button>
  );
};

export default Button;
