import SlideItem from 'components/SlideItem';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

import * as S from './styled';

const LiveSlide = ({ list, title, live, showLabel }) => {
  return (
    <S.SwiperContainer live={live}>
      <S.SwiperTitle>{title}</S.SwiperTitle>
      <Swiper spaceBetween={9} slidesPerView="auto" style={{ overflow: 'visible', paddingRight: 20 }}>
        {list?.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ width: 156 }}>
              <SlideItem item={item} showLabel={showLabel} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </S.SwiperContainer>
  );
};

export default LiveSlide;
