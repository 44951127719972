import styled from 'styled-components'

export const ProdutoContainer = styled.div`
  background: white;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: scroll;
`

export const WrapPhoto = styled.div`
  height: 150px;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const WrapContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 300px;
  margin: auto;
  margin-bottom: 30px;
`

export const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    text-align: left;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 300px;
  margin: auto;
`