import React, { useEffect, useState } from 'react';

import AddressForm from 'containers/AddressForm';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Title from 'components/Title';
import Loader from 'components/Loader';

import * as S from './styled';
import { maskNumber } from 'utils/maskNumber';

import api from 'services/api';

import { ReactComponent as StarIcon } from 'assets/common/star.svg';
import { ReactComponent as DeleteIcon } from 'assets/common/close-red.svg';
import HeaderCheckout from 'components/HeaderCheckout';
import IconComponent from 'components/V2/Icon';

const PagamentoEndereco = ({ live, setStep, setSelectedAddress }) => {
  const [addAddress, setAddAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState([]);

  const handleGetAddress = async () => {
    setLoading(true);
    const response = await api.get('/user/getAddresses');
    setTimeout(() => {
      setAddress(response.data.content);
      setLoading(false);
    }, 1000);
  };

  const handleFavorite = (id) => {
    const copyData = address.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          favorite: true,
        };
      } else {
        return {
          ...el,
          favorite: false,
        };
      }
    });

    setAddress(copyData);
  };

  const handleDelete = (id) => {
    const copyData = [...address];
    const elIndex = copyData.findIndex((el) => el.id === id);
    copyData.splice(elIndex, 1);

    // chamada deletar cartão
    setAddress(copyData);
  };

  useEffect(() => {
    handleGetAddress();
  }, []);

  return (
    <>
      <HeaderCheckout
        title={'Endereços'}
        backAction={() => {
          setStep('');
        }}
      />
      {!addAddress && (
        <>
          <S.AddressContainer>
            {loading && <Loader />}
            {!loading && (
              <>
                {address.map((el, index) => {
                  return (
                    <S.AddressCard
                      key={index}
                      onClick={() => {
                        setSelectedAddress(el);
                        setStep('');
                      }}
                    >
                      <p>
                        {el.street}, {el.number} - {el.city} - {el.state}
                      </p>
                      <S.Arrow>
                        <S.WrapSvg>
                          <IconComponent icon="selectRight" />
                        </S.WrapSvg>
                      </S.Arrow>
                    </S.AddressCard>
                  );
                })}
                <S.AddressAddItem>
                  <S.AddButton onClick={() => setAddAddress(true)}>
                    Adicionar Endereço
                  </S.AddButton>
                </S.AddressAddItem>
              </>
            )}
          </S.AddressContainer>
        </>
      )}
      {addAddress && (
        <AddressForm
          setAddress={setAddAddress}
          getAddress={handleGetAddress}
          closeForm={() => setAddAddress(false)}
        />
      )}
      <Footer showTop={true} />
    </>
  );
};

export default PagamentoEndereco;
