import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`;

export const ProdutoColorsItem = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid;
  background-color: ${({ color }) => color};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  border-width: ${({ selected }) => (selected ? "4px" : "2px")};
  border-color: ${({ selected, theme }) =>
    selected ? theme.colors.SECONDARY_COLOR : theme.colors.WHITE};

  transition: border 0.3s;
  will-change: border;
`;
