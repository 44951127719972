import styled from 'styled-components'
import { Text } from 'components/V2/Text/styled'

export const ProductCarrinho = styled.div`
  background: #f5f5f5;
  min-height: 120px;
  width: 100%;
  padding: 21px 19px 15px 13px;
  margin-bottom: 6px;
  border-radius: 20px;

  position: relative;
`

export const ProductCarrinhoContent = styled.div`
  max-width: 300px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ProductCarrinhoIcon = styled.div`
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 70px 70px;
  margin-right: 10px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
`

export const ProductName = styled.p`
  color: #595959;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-top: 10px;
  white-space: break-spaces;
`

export const ChangeDetails = styled.button`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${({ theme }) => theme.colors.SECONDARY_COLOR};

  /* position: absolute;
  bottom: 0;
  left: 80px; */
  padding: 12px 0;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 20px;

  cursor: pointer;
`

export const Price = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #fff;
  background-color: #00dfff;

  /* position: absolute;
  bottom: 0;
  right: 0; */
  padding: 12px 20px;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 20px;
`

export const ProductCarrinhoData = styled.div`
  width: 100%;
  display: flex;

  padding-bottom: 50px;
`

export const WrapInputNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
`

export const RemoveButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #545454;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`

export const ProductCarrinhoQuantity = styled.div`
  /* display: grid;
  grid-template-columns: 83px 105px; */
  /* gap: 7px; */

  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 20px;

  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-top: 10px;
`

export const CarrinhoToggleQuantity = styled.div`
  width: 83px;
  text-align: center;
  padding: 7px 0px;
  background: white;
  border-radius: 25px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;

  height: 28px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  svg {
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
  }

  .removeItem {
    svg {
      transform: rotate(45deg);
      position: relative;
      left: -1px;
    }
    svg g circle {
      fill: #ff0000;
    }
  }
`

export const CarrinhoQuantity = styled.div`
  width: 105px;
  text-align: center;
  padding: 7px 0px;
  background: ${({ theme }) => theme.colors.PRIMARY_COLOR};
  border-radius: 25px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
  height: 28px;

  /* margin-top: 10px; */
`

export const WrapVariation = styled.div`
  ${Text} {
    margin-top: 5px;
    text-align: start;
  }
`
