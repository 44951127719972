import React, { useState, useEffect, useRef } from 'react';
import api from 'services/api';

import * as S from './styled';

const LiveChat = (props) => {
  const [comments, setComments] = useState([]);
  const chat = useRef();

  useEffect(() => {
    const callMessages = async () => {
      const { data } = await api.get('/messages/receive', { params: { event: props.live._id } });

      setComments(data.reverse());

      if (chat.current) {
        chat.current.scrollTop = chat.current.scrollHeight;
      }
    };

    callMessages();

    const interval = setInterval(callMessages, 4000);
    return () => clearInterval(interval);
  }, [props.live._id]);

  useEffect(() => {
    if (props.sentMessages.length > 0) {
      setComments((_) => props.sentMessages.reverse());

      setTimeout(() => {
        chat.current.scrollTop = chat.current.scrollHeight;
      }, 500);
    }
  }, [props.sentMessages]);

  return (
    <S.ChatContainer {...props} ref={chat}>
      {comments &&
        comments.map((el) => {
          return (
            <S.Message key={el._id}>
              {el.avatar && <img src={el.avatar} alt="" />}
              <div>
                <span>{el.from}</span>
                {el.text}
              </div>
            </S.Message>
          );
        })}
    </S.ChatContainer>
  );
};

export default LiveChat;
