import React from 'react';
import * as S from './styled';

import { ReactComponent as Alert } from 'assets/common/alert.svg';

const AlarmButton = ({ disabled, background, handleClick, id }) => {
  return (
    <S.AlarmButton id={id} disabled={disabled} background={background} onClick={() => handleClick()}>
      <Alert />
      <p>Criar Alerta</p>
    </S.AlarmButton>
  );
};

export default AlarmButton;
