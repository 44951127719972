import ShopperSlideItem from 'components/SlideItem';
import SlideItem from 'components/SlideItem';
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import * as S from './styled';
import api from 'services/api';
import { ReactComponent as PinkAdd } from 'assets/common/plus-pink.svg';
import { useHistory } from 'react-router-dom';
import Modal from 'components/V2/Modal';
import Title from 'components/Title';
import Button from 'components/V2/Button';
import ButtonClipBoard, {
  copyToClipboard,
} from 'components/Button/clipBoardButton.jsx';

const ShopperSlider = ({ list, title, live, showLabel }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState({
    step: 'main',
    isOpen: false,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [id, setId] = useState();
  const [event, setEvent] = useState({});

  useEffect(() => {
    const getEvent = async () => {
      if (id) {
        if(!event?.shop?.nameURL && !event.protoId){
          const response = await api.get('/events/getStuff', {
            params: { _id: id },
          })
          setEvent(response.data.content)  
        }
      }
    }
    getEvent()
  }, [id])

  const handleOpenModal = (id) => {
    setShowModal({ step: 'main', isOpen: true });
    setId(id);
  };

  const handleDeleteLive = async (id) => {
    try {
      // deletar a live, o id da live está no parametro
      await api.post('/events/delete', { _id: id });
      setShowModal({ step: 'main', isOpen: false });
      window.location.reload(true); /* DEBT */
    } catch (err) {
      console.error(err);
    }
  };

  const handleStartStream = async (id) => {
    try {
      history.push(`/live/${event.shop.nameURL}/${event.protoId}/watch`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditEvent = async (id) => {
    try {
      history.push(`/edit-event/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const renderMainModal = () => {
    return (
      <S.WrapButtons>
        <Button icon="play" handleClick={() => handleStartStream(id)}>
          Iniciar a transmissão
        </Button>
        <Button
          styleOfButton="outlineSecondary"
          icon="copy"
          handleClick={() =>
            copyToClipboard(`${window.location.hostname}/live/${event.shop.nameURL}/${event.protoId}`)
          }
        >
          Copiar Link
        </Button>
        <Button
          handleClick={() => handleEditEvent(id)}
          styleOfButton="outlineSecondary"
          icon="fileEdit"
        >
          Editar a Live
        </Button>

        <S.WrapCancelButton>
          <Button
            handleClick={() =>
              setShowModal({
                step: 'closeTransmission',
                isOpen: true,
              })
            }
            styleOfButton="outlinePrimary"
            icon="cancel"
          >
            Deletar
          </Button>
        </S.WrapCancelButton>
      </S.WrapButtons>
    );
  };

  return (
    <>
      <S.SwiperContainer live={live}>
        <S.SwiperTitle>{title}</S.SwiperTitle>
        <Swiper
          spaceBetween={9}
          slidesPerView="auto"
          style={{ overflow: 'visible', paddingRight: 20 }}
        >
          <SwiperSlide
            style={{ width: 156 }}
            onClick={() => history.push('/empresa/adicionar-evento')}
          >
            <ShopperSlideItem>
              <S.AddContainer>
                <PinkAdd />
                Adicionar
              </S.AddContainer>
            </ShopperSlideItem>
          </SwiperSlide>
          {list.map((item, index) => {
            return (
              <SwiperSlide key={index} style={{ width: 156 }}>
                <ShopperSlideItem
                  item={item}
                  handleOpenModal={handleOpenModal}
                  type="shopper"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </S.SwiperContainer>

      <Modal
        icon="transmission"
        title="Inicie a sua Live agora!"
        isOpen={showModal.isOpen && showModal.step === 'main'}
        setIsOpen={() => setShowModal({ step: 'main', isOpen: false })}
        renderButtons={() => renderMainModal()}
      />

      <Modal
        icon="transmissionClose"
        title="Tem certeza que deseja deletar a Live?"
        isOpen={showModal.isOpen && showModal.step === 'closeTransmission'}
        setIsOpen={() => setShowModal({ step: 'main', isOpen: false })}
        renderButtons={() => (
          <S.WrapCancelButton>
            <Button
              handleClick={() => {
                handleDeleteLive(id);

                setShowModal({
                  step: 'main',
                  isOpen: false,
                });
              }}
              styleOfButton="outlinePrimary"
              icon="cancel"
            >
              Deletar
            </Button>
          </S.WrapCancelButton>
        )}
      />
    </>
  );
};

export default ShopperSlider;
