import styled from 'styled-components';

export const TextContainer = styled.div`
  line-height: 22px;

  div {
    text-align: center;
  }
`;

export const WrapStepper = styled.div`
  min-height: calc(100% - 60px);
`;

export const Page = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  overflow-y: scroll;

  background-color: ${({ theme }) => theme.colors.WHITE};

  button {
    margin: auto;
    margin-top: 70px;
  }
`;

export const WrapBody = styled.div`
  padding-top: 50px;
`;

export const TitleContainer = styled.div`
  margin: 0px 0px 17px;
`;

export const Description = styled.p`
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #a9a9a9;
  margin: 20px 0px 30px;
`;
