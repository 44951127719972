import React, { useEffect, useState } from 'react';

import * as S from './styled';

import { ReactComponent as Dropdown } from 'assets/common/dropdown.svg';

import { Link } from 'react-router-dom';

import ProductSlider from 'containers/ProductListSlider';
import LiveChat from 'containers/LiveChat';

const BottomMenu = ({ item, setDenunciar, setProduct, live, sentMessages }) => {
  useEffect(() => {}, []);

  return (
    <S.BottomMenuContainer>
      <S.BottomMenu>
        <LiveChat sentMessages={sentMessages} live={item} className="videoChat" showProducts={'0'} />
      </S.BottomMenu>
    </S.BottomMenuContainer>
  );
};

export default BottomMenu;
