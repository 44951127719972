import React from 'react';
import * as S from './styled';

const LiveLabel = ({ style, top, right, textSize, text, background, padding }) => {
  return (
    <S.LiveLabel textSize={textSize} top={top} right={right} background={background} padding={padding} style={style}>
      <p>{text}</p>
    </S.LiveLabel>
  );
};

export default LiveLabel;
