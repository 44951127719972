import React, { createContext, useContext, useState, useEffect } from 'react';
import api from 'services/api';

export const ShopsContext = createContext();

export const ShopsProvider = ({ children }) => {
  const [shopInfo, setShopInfo] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);

  const getShopsInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('/shops/read');

      setShopInfo(data.content);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const updateShopsInfo = () => {};
  const removeShop = () => {};

  const addNewShop = (store) => {
    setShopInfo([store]);
  };

  useEffect(() => {
    getShopsInfo();
  }, []);

  const store = {
    isLoading,
    shopInfo,
    addNewShop,
    getShopsInfo,
  };

  return (
    <ShopsContext.Provider value={store}>{children}</ShopsContext.Provider>
  );
};

export const useShopContext = () => {
  const context = useContext(ShopsContext);
  if (context === undefined) {
    throw new Error('useShopContext must be used within a ShopsProvider');
  }
  return context;
};
