import styled from 'styled-components';

 // height is 100% minus the sum of all other elements'
 // fixed heights, such as the Header, HomeMenu and Footer
export const HomeContainer = styled.div`
  height: calc(100% - 197px);
  position: absolute;
  overflow: auto;
  width: 100%;
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 130px;

  p {
    width: 154px;
  }
`;

export const Found = styled.div`
  margin-top: 24px;
`;

export const ListaLives = styled.div`
  margin-top: 26px;
`;

export const AgendaContainer = styled.div`
  margin: 0 auto 50px;
`;

export const LiveLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  div {
    position: static;
    font-size: 1.8rem;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
  }
`;

export const AgendaItemContainer = styled.div`
  display: grid;
  gap: 22px;
  padding-right: 15px;
`;

export const AgendaItem = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  min-height: 119px;
  min-width: 100%;
`;

export const HourContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Hour = styled.div`
  margin-top: 10px;
  color: #00dfff;
  font-weight: 500;
  font-size: 1.5rem;
`;

export const ContentContainer = styled.div`
  background: #efefef;
  border-radius: 20px;
  min-height: 119px;
  padding: 15px 25px;
`;

export const AgendaLiveTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

export const AgendaLiveTitle = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
`;

export const AgendaLiveLogo = styled.div`
  background: ${({ logo }) => (logo ? `url(${logo})` : 'black')} no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  border: 1px solid #adadad;
  margin-right: 21px;
`;
