import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import * as S from './styled';
import NotLogged from './NotLogged';
import Logged from './Logged';
import Streamer from './Streamer';
import LiveCarrinho from 'pages/Carrinho';
import { useParams, useHistory } from 'react-router-dom';
import Pagamento from 'pages/Pagamento';
import api from 'services/api';
import { useProducts } from 'contexts/ProductsContext';
import Produto from 'pages/Produto';
import { useLiveContext } from 'contexts/LivesContext';
import ChangeVariation from 'pages/ChangeVariation';

const Live = () => {
  const [loading, setLoading] = useState(true);
  const [live, setLive] = useState({});
  const [carrinho, setCarrinho] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [pagamento, setPagamento] = useState(false);
  const {
    selectedProduct: product,
    setSelectedProduct: setProduct,
    showAllInfo,
    productIsSetted,
    showChangeVariation,
  } = useProducts();
  const { setLiveDetails } = useLiveContext();

  const { isLogin } = useAuthContext();
  const params = useParams();
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);

    if (window.location.href.includes('watch')) {
      history.push(`/live/${params.shop}/${params.protoId}`);
    }

    setLoggedUser(isLogin());

    let response = await api.get('/events/getStuff', {
      params: { nameURL: params.shop, protoId: params.protoId },
    });
    setLive(response.data.content);

    localStorage.setItem('@Ugong/last_live', `/live/${params.shop}/${params.protoId}`);

    setLoading(false);
    setLiveDetails(response.data.content);
  }, [params]);
  return (
    <S.LiveContainer>
      {loading && <Loader />}
      {!loading && live && (
        <S.Live>
          {!loggedUser?._id ? (
            <NotLogged item={live} />
          ) : loggedUser?.type == 'seller' &&
            loggedUser?.shops.includes(live.shop._id) /*DEBT*/ ? (
            <Streamer
              item={live}
              setCarrinho={setCarrinho}
              setProduct={setProduct}
            />
          ) : (
            <Logged item={live} setCarrinho={setCarrinho} />
          )}
        </S.Live>
      )}
      {carrinho && loggedUser && (
        <S.ContainerFixed>
          <LiveCarrinho
            setCarrinho={setCarrinho}
            product={product}
            live={live}
            setPagamento={setPagamento}
          />
        </S.ContainerFixed>
      )}

      {productIsSetted && showAllInfo && (
        <Produto setCarrinho={setCarrinho} carrinho={carrinho} />
      )}

      {pagamento && loggedUser && (
        <S.ContainerFixed>
          <Pagamento
            setPagamento={setPagamento}
            setCarrinho={setCarrinho}
            live={live}
            product={product}
          />
        </S.ContainerFixed>
      )}

      {productIsSetted && showChangeVariation && (
        <ChangeVariation setCarrinho={setCarrinho} carrinho={carrinho} />
      )}
    </S.LiveContainer>
  );
};

export default Live;
