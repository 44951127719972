export const loadFromLocalstorage = (item) => {
  try {
    const serializedState = localStorage.getItem(item);

    if (serializedState === null) {
      return [];
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return [];
  }
};

export const saveToLocalStorage = (item, state) => {
  try {
    const serializedState = JSON.stringify(state);
    return localStorage.setItem(item, serializedState);
  } catch (err) {
    return [];
  }
};

export const removeFromLocalStorage = (item) => {
  try {
    localStorage.removeItem(item);
    const preloginUsername = localStorage.getItem('preloginUsername');
    localStorage.clear();
    localStorage.setItem('preloginUsername', preloginUsername);
    return;
  } catch (err) {
    return [];
  }
};
