import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 0px 40px;

  svg {
    margin-right: 10px;
  }
`;


export const CarrinhoHeader = styled.div`
  width: 300px;
  margin: 0 auto 15px;
  display: flex;
  justify-content: space-between;
`;

export const CarrinhoQuantity = styled.div`
  width: 100px;
  text-align: center;
  padding: 7px 0px;
  background: #00dfff;
  border-radius: 25px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
`;

export const CarrinhoSubtotal = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  > div {
    font-size: 1.1rem;
    font-weight: 600;
    margin-right: 22px;
    color: #545454;
  }

  > p {
    font-size: 2rem;
    font-weight: 600;
    color: #545454;
  }
`;