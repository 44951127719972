import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';
import icons from '../Icon/files';
import IconComponent from '../Icon';

const Button = ({
  disabled,
  children,
  handleClick,
  styleOfButton = 'primary',
  type = 'button',
  icon,
}) => {
  const onHandleClick = (e) => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <S.Button
      type={type}
      styleOfButton={styleOfButton}
      disabled={disabled}
      onClick={(e) => onHandleClick(e)}
    >
      <IconComponent icon={icon} />
      {children}
    </S.Button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any.isRequired,
  handleClick: PropTypes.func.isRequired,
  styleOfButton: PropTypes.oneOf([
    'primary',
    'primaryRed',
    'secondary',
    'outline',
    'outlinePrimary',
    'outlineSecondary',
  ]),
  icon: PropTypes.oneOf(Object.keys(icons)),
};
export default Button;
