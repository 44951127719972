import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Text = ({ children, type = 'body' }) => {
  return <S.Text type={type}>{children}</S.Text>
}

Text.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf([
    'small',
    'cardTitle',
    'label',
    'body',
    'bodyEnphasis',
    'title',
  ]),
}
export default Text
