import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const CadastroContainer = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 25px;
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 800;
  text-align: center;
  color: ${C.SECONDARY_COLOR};
  padding: 38px 0px;
`;

export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${({ background }) =>
      `linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%),url(${background})`}
    no-repeat;
  background-size: 100%;
  ${({ live }) =>
    live
      ? 'background: linear-gradient(0deg, rgba(217, 217, 217, 0.32) -7.05%, rgba(248, 248, 248, 0) 16.18%);'
      : '#FFF'};
`;

export const Logo = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 50%;
  background: ${({ background }) => `url(${background})`} no-repeat;
  background-size: 124px 124px;
  border: 1px solid #adadad;
`;

export const FileInputContainer = styled.div`
  text-align: center;

  flex-direction: column;
  margin: 0 auto 26px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  height: 182px;
  border: 0.5px solid #d2d2d2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  width: 281px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .pinkArrow {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  .labeltext {
    font-weight: 600;
    font-size: 1rem;
    width: 60%;
    margin: 5px auto 0px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 279px;
  padding-bottom: 46px;
  position: relative;

  input,
  select {
    width: 279px;
  }
`;
export const Anchor = styled(Link)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  color: ${C.TEXT_PRIMARY_COLOR};

  span {
    font-weight: 500;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin: ${({ margin }) => margin};
  cursor: pointer;

  span {
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  padding: 50px 0px 24px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;

  button {
    margin: 0 auto;
  }
`;

export const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 15px;
`;

export const TermsContainer = styled.div`
  width: 279px;
  margin-bottom: 25px;
`;

export const CategoryList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 25px;
  margin-bottom: 65px;
`;

export const CategoryItem = styled.li`
  width: 100%;
  background: #efefef;
  border-radius: 20px;
  text-align: center;
  padding: 12px 0px;
  font-size: 1.5rem;
  color: #8a8a8a;
  margin-bottom: 7px;
  font-weight: 500;
  transition: all 0.4s ease;

  &.selected {
    background: #ffced9;
  }
`;

export const CategoriasContainer = styled.div`
  max-width: 279px;
  margin: 0px auto 100px;

  button {
    width: 100%;
  }
`;

export const SocialContainer = styled.div`
  margin: 0 auto;
  width: 279px;
  height: 600px;

  button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;

export const TextContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-bottom: 25px;
`;

export const ContainerSlideItemTitle = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 70%;
  text-align: right;
`;

export const SlideItemTitle = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.32px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  p {
    margin-left: 24px;
    font-size: 1.5rem;
    width: 55%;
  }
`;

export const Toggle = styled.div`
  height: 37px;
  min-width: 76px;
  border-radius: 50px;
  position: relative;
  margin: 20px 0px;

  ${({ active }) => {
    if (active) {
      return css`
        background: rgba(255, 61, 104, 0.32);
      `;
    } else {
      return css`
        background: #f4f4f4;
      `;
    }
  }}

  div {
    position: absolute;
    height: 37px;
    width: 37px;
    transition: right 1s ease, left 1s ease;
    ${({ active }) => {
      if (active) {
        return css`
          right: 0px;
          background: #ff3d68;
        `;
      } else {
        return css`
          left: 0px;
          background: #dddcdc;
        `;
      }
    }}
    border-radius: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
`;

export const HalfInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;

  div {
    width: auto;
  }

  input {
    width: 100%;
  }
`;
