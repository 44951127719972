import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 255px;
  max-height: 160px;
  min-height: 100px;
  overflow-y: scroll;
  padding-top: 50px;
  -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Message = styled.div`
  color: white;
  line-height: 16px;
  background: rgba(128, 128, 128, 0.8);
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-size: 12px;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 9px;
    align-self: flex-start;
  }

  span {
    font-weight: 600;
    margin-right: 9px;
  }
`;
