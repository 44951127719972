/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import * as S from './styled';

import { ReactComponent as UgongIcon } from 'assets/logos/white.svg';
import Loader from 'components/Loader';
import { useAuthContext } from 'contexts/AuthContext';
import api from 'services/api';
import { useHistory, useParams } from 'react-router-dom';
import { saveToLocalStorage } from 'utils/handleLocalStorage';
import Cookies from 'universal-cookie';
import { useShopContext } from 'contexts/ShopsContext';

const Ugong = () => {
  const { handleMagicLink, setUser, getPreloginUsername } = useAuthContext();
  const { getShopsInfo } = useShopContext();
  const history = useHistory();
  const params = useParams();

  const query = new URLSearchParams(window.location.search);

  const handleLogin = async () => {
    let expiresDate = new Date();
    let time = expiresDate.getTime();
    let expireTime = time + 90 * 86400000;
    expiresDate.setTime(expireTime);
    const credential = await handleMagicLink();
    const auth = credential ? credential : params.magic;

    const cookies = new Cookies();
    cookies.set('UgongAuth', auth, { path: '/', expires: expiresDate });

    try {
      const login = await api.post('/login', { preloginUsername: getPreloginUsername() });
      await setUser(login.data);
      getShopsInfo();
      saveToLocalStorage('@Ugong/user', login.data);
      history.push(
        query.get('last_live') === 'null' ? '/' : query.get('last_live')
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <S.UgongPage>
      <div className="logo">
        <UgongIcon />
      </div>
      <Loader white />
    </S.UgongPage>
  );
};

export default Ugong;
