import React, { useEffect, useState } from 'react'

import * as S from './styled'

import ProductImageSlider from 'containers/ProductImageSlider'
import SelectGrey from 'components/SelectGrey'
import Button from 'components/V2/Button'
import { useCarrinhoContext } from 'contexts/CarrinhoContext'
import { useProducts } from 'contexts/ProductsContext'
import Modal from 'components/V2/Modal'

const Produto = ({ setCarrinho }) => {
  const [produto, setProduto] = useState(null)
  const [colors, setColors] = useState(null)
  const [sizes, setSizes] = useState(null)
  const [maxQuantity, setMaxQuantity] = useState(null)
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedSize, setSelectedSize] = useState('')
  const [selectedQuantity, setSelectedQuantity] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { addToCarrinho } = useCarrinhoContext()
  const {
    selectedProduct: product,
    setSelectedProduct: setProduct,
    setShowAllInfo,
  } = useProducts()

  useEffect(() => {
    const filterSize = product.variations.filter((el) => el.quantity > 0)
    const quantityArr = []

    for (let i = 1; i <= product.stockAmount; i++) {
      quantityArr.push({
        number: i,
      })
    }
    setProduto(product)

    // de-duplicate colors
    const productColors = [
      ...new Set(product.variations.map((variation) => variation.color)),
    ]

    setColors(productColors)
    setSizes(filterSize)
    if (quantityArr.length > 0) setSelectedQuantity(1)
    setMaxQuantity(quantityArr)
  }, [product])

  const handleAddCarrinho = async () => {
    await addToCarrinho({
      ...produto,
      selectedColor,
      selectedQuantity,
      selectedSize,
    })
    setIsOpenModal(true)
  }

  const closePage = () => {
    setProduct(null)
    setShowAllInfo(false)
  }

  return (
    produto && (
      <S.ProdutoContainer>
        <S.ProdutoContent>
          <ProductImageSlider list={produto} setProduct={closePage} />
          <S.TitleContainer>
            <S.ProdutoTitle>{produto.title}</S.ProdutoTitle>
          </S.TitleContainer>
          <S.TextContainer>
            <p className="title">Descrição</p>
            <p className="text">{produto.description}</p>
          </S.TextContainer>
          {/* Filter all colors and check if the string length is greater than 1 */}
          {colors.filter((color) => color.length > 1).length > 0 && (
            <S.ProdutoWhiteContainer>
              <p className="title">Cores</p>
              <S.ProdutoColors>
                {colors.map((el, index) => {
                  if (el.length > 1) {
                    return (
                      <S.ProdutoColorsItem
                        key={index}
                        className={selectedColor === el ? 'selected' : ''}
                        color={el}
                        onClick={() => setSelectedColor(el)}
                      />
                    )
                  }
                })}
              </S.ProdutoColors>
            </S.ProdutoWhiteContainer>
          )}
          <S.ProdutoWhiteContainerHalf>
            {sizes.length > 1 && (
              <div>
                <p className="title">Tamanhos</p>
                <SelectGrey
                  value={selectedSize}
                  handleChange={(e) => setSelectedSize(e.value)}
                  name="size"
                  optionList={sizes}
                  hide={true}
                />
              </div>
            )}
            <div>
              <p className="title">Quantidade</p>
              <SelectGrey
                value={selectedQuantity}
                handleChange={(e) => setSelectedQuantity(e.value)}
                name="number"
                optionList={maxQuantity}
                hide={true}
              />
            </div>
          </S.ProdutoWhiteContainerHalf>
          <S.TextContainer>
            <p className="title">Informações técnicas</p>
            <p className="text">{produto.technical}</p>
          </S.TextContainer>
        </S.ProdutoContent>
        <S.DoneRegister>
          <Button
            disabled={
              selectedQuantity === '' ||
              selectedQuantity === '' ||
              colors.filter((color) => color.length > 1).length > 0
                ? selectedColor === ''
                : false
            }
            handleClick={handleAddCarrinho}
          >
            Adicionar ao carrinho
          </Button>

          <Button handleClick={closePage}>Voltar para a Live</Button>
        </S.DoneRegister>
        <Modal
          title="Você adicionou um produto ao carrinho"
          renderButtons={() => (
            <>
              <Button
                icon="linkBack"
                handleClick={() => {
                  closePage()
                }}
              >
                Voltar para a Live
              </Button>
              <S.WrapButtonSecondary>
                <Button
                  icon="arrowBack"
                  styleOfButton="secondary"
                  handleClick={() => {
                    closePage()
                    setCarrinho(produto)
                  }}
                >
                  Finalizar compra
                </Button>
              </S.WrapButtonSecondary>
            </>
          )}
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
        />
      </S.ProdutoContainer>
    )
  )
}

export default Produto
