import React from 'react'
import Select from 'react-select'

import * as S from './styled'

// eslint-disable-next-line react/display-name
const SelectForm = React.forwardRef(
  (
    {
      options,
      value,
      onChange,
      menuPlacement = 'bottom',
      type = 'square',
      width = '270px',
      size = 'medium',
      ...rest
    },
    ref
  ) => {
    return (
      <S.SelectContainer
        menuPlacement={menuPlacement}
        type={type}
        width={width}
        size={size}
      >
        <Select
          inputRef={ref}
          menuPlacement={menuPlacement}
          onChange={onChange}
          value={value}
          options={options}
          isSearchable={false}
          className="react-select-container"
          classNamePrefix="react-select"
          {...rest}
        />
      </S.SelectContainer>
    )
  }
)

export default SelectForm
