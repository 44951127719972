import React, { useEffect, useState } from 'react';

import Title from 'components/Title';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Select from 'components/Select';

import * as S from './styled';
import { useHistory, useParams } from 'react-router-dom';

import { statesAndCities } from 'constants/data/Localizacao';
import OutOfReach from './OutOfReach';

const Localizacao = () => {
  const [data, setData] = useState({
    state: '',
    city: '',
  });

  const [outOfReach, setOutOfReach] = useState(false);
  const history = useHistory();

  const handleNext = () => {
    const { state, city } = data;

    if (state && city) {
      history.push('/conta/categorias');
    }
  };

  const handleLogoff = () => {};

  return (
    <>
      <BackBar type="back" path="/cadastrar" shadow={true} text="Voltar" onClick={() => handleLogoff()} />

      <S.Container>
        <S.Form>
          <S.TitleContainer>
            <Title>Seu estado</Title>
          </S.TitleContainer>
          <Select
            placeholder="Escolha o Estado"
            type="number"
            value={data.state}
            handleChange={(e) => {
              if (e.value === '' || e.value === undefined || e.value !== data.state) {
                setData({ city: '', state: e.value });
              } else {
                setData({ ...data, state: e.value });
              }
            }}
            name="state"
            optionList={statesAndCities}
          />
          <S.TitleContainer>
            <Title>Seu cidade</Title>
          </S.TitleContainer>
          <Select
            disabled={data.state === undefined || data.state === ''}
            placeholder="Escolha a cidade"
            type="number"
            value={data.city}
            handleChange={(e) => setData({ ...data, city: e.value })}
            name="city"
            optionList={statesAndCities.filter((el) => el.state === data.state)[0]?.cities}
          />
        </S.Form>
        <Button
          disabled={data.state === '' || data.state === undefined || data.city === '' || data.city === undefined}
          handleClick={handleNext}
        >
          Continuar
        </Button>
        <S.Text margin="12px 0px 43px" onClick={() => setOutOfReach(true)}>
          Não achou? <span>Clique aqui.</span>
        </S.Text>
      </S.Container>
      {outOfReach && <OutOfReach onClick={() => setOutOfReach(false)} />}

      <Footer showTop={true} />
    </>
  );
};

export default Localizacao;
