import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';

import {
  loadFromLocalstorage,
  saveToLocalStorage,
  removeFromLocalStorage,
} from 'utils/handleLocalStorage';

import * as S from './styled';
import { ReactComponent as UserIcon } from 'assets/user/bigAvatar.svg';
import { yupResolver } from '@hookform/resolvers/yup';

import Footer from 'components/Footer';
import Input from 'components/V2/Input';
import Button from 'components/V2/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/V2/Text';
import InputWithMask from 'components/InputMask';
import Loader from 'components/Loader';
import api from 'services/api';
import { useHistory } from 'react-router-dom';
import IconComponent from 'components/V2/Icon';
import { Controller, useForm } from 'react-hook-form';
import Select from 'components/V2/Select';
import { Schema } from './validation';
import Modal from 'components/V2/Modal';

const CadastroCompleto = ({ setDone }) => {
  const AuthContext = useAuthContext();
  const [user, setUser] = useState();
  const context = useAuthContext();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const [preview, setPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalSettins, setModalSettins] = useState({
    isOpen: false,
    type: 'error',
    message: '',
  });

  const onSubmit = async (data) => {
    removeFromLocalStorage('@Ugong/user');
    setUser({});

    const body = {
      ...data,
      gender: data.gender.label,
      avatar: preview,
    };

    setIsLoading(true);

    try {
      const response = await api.post('/user/update', body);
      AuthContext.setUser((el) => {
        return {
          ...el,
          ...response.data.content,
        };
      });

      saveToLocalStorage('@Ugong/user', response.data.content);
      setUser(response.data.content);
      setPreview(response.data.content.avatar);
      setDone();
    } catch (error) {
      setModalSettins({
        isOpen: true,
        type: 'error',
        message: 'Erro ao enviar os dados',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateFile = (e) => {
    // Assuming only image
    let file = e.target.files[0];

    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setPreview(reader.result);
      };
    }
  };

  useEffect(() => {
    if (context?.user) {
      reset({
        fullName: context?.user?.fullName || '',
        doc: context?.user?.doc || '',
        phone: context?.user?.phone || '',
        birthDate: context?.user?.birthDate || '',
        gender: {
          label: context?.user?.gender || '',
        },
      });
    }

    setPreview(context.user?.avatar);
  }, [context, reset, setValue]);

  return (
    <S.Content>
      <S.CadastroContainer>
        <S.TitleContainer>
          <IconComponent icon="userEdit" />
          <Text type="title">Completar cadastro</Text>
        </S.TitleContainer>

        <S.FileInputContainer>
          <S.FileLabel htmlFor="input-avatar">
            <S.PerfilAvatar>
              {!preview ? (
                <UserIcon />
              ) : (
                <S.Avatar src="" alt="" background={preview ? preview : ''} />
              )}
            </S.PerfilAvatar>
            <Text>{!user?.avatar ? 'Adicionar foto' : 'Alterar foto'}</Text>
          </S.FileLabel>
          <S.FileInput
            type="file"
            id="input-avatar"
            accept="image/png, image/jpeg"
            onChange={(e) => handleUpdateFile(e)}
          />
        </S.FileInputContainer>
        <S.InputsContainer>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('fullName')}
              placeholder="Nome Completo"
              type="text"
              error={errors.fullName}
              required={true}
            />
            <InputWithMask
              {...register('doc')}
              mask="999.999.999-99"
              placeholder="CPF"
              error={errors.doc}
              required={true}
            />
            <InputWithMask
              {...register('phone')}
              mask="+55 (99) 99999-9999"
              placeholder="Celular"
              error={errors?.phone}
              required={true}
            />
            <InputWithMask
              {...register('birthDate')}
              mask="99/99/9999"
              placeholder="Data de Nascimento"
              error={errors.birthDate}
              required={true}
            />
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Gênero"
                  error={errors.gender}
                  options={[
                    { label: 'Masculino' },
                    { label: 'Feminino' },
                    { label: 'Prefiro não informar' },
                  ]}
                />
              )}
            />
            {register.error && (
              <S.ErrorMessageContainer>
                <ErrorMessage>Digite dados válidos</ErrorMessage>
              </S.ErrorMessageContainer>
            )}
            <S.ButtonContainer>
              <Button
                disabled={isLoading || !Object.keys(errors)}
                icon="arrowNext"
                type="submit"
              >
                Continuar
              </Button>
              {register.loading && <Loader />}
            </S.ButtonContainer>{' '}
          </S.Form>
        </S.InputsContainer>
      </S.CadastroContainer>

      <Modal
        isOpen={modalSettins.isOpen}
        setIsOpen={(state) =>
          setModalSettins((value) => ({ ...value, isOpen: state }))
        }
        type={modalSettins.type}
        icon={modalSettins.type === 'error' ? 'cancel' : 'check'}
        title={modalSettins.message}
        renderButtons={() => (
          <Button
            onClick={() =>
              setModalSettins((value) => ({ ...value, isOpen: false }))
            }
          >
            Tentar novamente
          </Button>
        )}
      />
      <Footer showTop={true} />
    </S.Content>
  );
};

export default CadastroCompleto;
