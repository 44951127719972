const viewer = [
  {
    text: 'Completar cadastro',
    pathName: '/cadastro/completo',
    icon: 'userEdit',
  },
  {
    text: 'Ajuda',
    pathName: '/ajuda',
    icon: 'question',
  },
]

const shopper = [
  {
    text: 'Alterar cadastro',
    pathName: '/cadastro/completo',
    icon: 'userEdit',
  },
  /*{
    text: 'Alterar senha',
    pathName: '/cadastro/alterar-senha',
  },*/
  {
    text: 'Carteira',
    pathName: '/cadastro/carteira',
    icon: 'creditCard',
  },
  // {
  //   text: "Endereços",
  //   pathName: "/cadastro/enderecos",
  // },
  {
    text: 'Ajuda',
    pathName: '/ajuda',
    icon: 'question',
  },
]

const seller = [
  {
    text: 'Alterar cadastro',
    pathName: '/cadastro/completo',
    icon: 'userEdit',
  },
  {
    text: 'Ajuda',
    pathName: '/ajuda',
    icon: 'question',
  },
]

export const getMenuByUser = (userGroup) => {
  if(userGroup === "viewer") {
    return viewer
  }

  if(userGroup === "shopper") {
    return shopper
  }

  return seller
}