import styled from "styled-components";

import { Text } from "components/V2/Text/styled";

export const InputContainer = styled.div`
  width: 279px;
  position: relative;

  div {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const Label = styled(Text).attrs({
  as: "label",
  type: "label",
})`
  display: block;
  margin-bottom: 10px;
  text-align: left;
`;

export const Input = styled(`${({ as }) => as}`)`
  ${({ as }) => (as === "textarea" ? "min-height: 137px" : "")};
  width: 100%;
  max-width: 279px;

  background: ${({ theme }) => theme.colors.GREY_3};
  color: ${({ theme }) => theme.colors.GREY_1};

  border-radius: 10px;
  padding: 19px 0px;

  text-align: center;
  text-align: -webkit-center;
  font-size: ${({ theme }) => theme.typography.xs};

  border: 2px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.INPUT_ERROR_COLOR : theme.colors.GREY_3};
  outline-color: ${({ theme }) => theme.colors.GREY_3};

  transition: border-color 0.3s, outline-color 0.3s;

  &:focus-visible {
    outline-color: ${({ theme }) => theme.colors.GREY_1};
  }
`;
