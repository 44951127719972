import styled, { css } from 'styled-components'

const primaryRed = css`
  background-color: ${({ theme }) => theme.colors.PRIMARY_COLOR};
  border: 2px solid transparent;
  
  font-weight: 800;
`

const primary = css`
  background-color: ${({ theme }) => theme.colors.SECONDARY_COLOR};
  border: 2px solid transparent;

  font-weight: 800;
`

const secondary = css`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 2px solid ${({ theme }) => theme.colors.SECONDARY_COLOR};
  color: ${({ theme }) => theme.colors.SECONDARY_COLOR};

  font-weight: 800;
`

const outline = css`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 2px solid ${({ theme }) => theme.colors.GREY_1};
  color: ${({ theme }) => theme.colors.GREY_1};

  font-weight: 800;
`

const outlinePrimary = css`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 2px solid ${({ theme }) => theme.colors.PRIMARY_COLOR};
  color: ${({ theme }) => theme.colors.PRIMARY_COLOR};

  font-weight: 800;
`

const outlineSecondary = css`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 2px solid ${({ theme }) => theme.colors.SECONDARY_COLOR};
  color: ${({ theme }) => theme.colors.SECONDARY_COLOR};

  font-weight: 800;
`

const types = {
  primary,
  primaryRed,
  secondary,
  outline,
  outlinePrimary,
  outlineSecondary
}

const svgColor = {
  primary: css`
    ${({ theme }) => theme.colors.WHITE}
  `,
  primaryRed: css`
    ${({ theme }) => theme.colors.WHITE}
  `,
  secondary: css`
    ${({ theme }) => theme.colors.SECONDARY_COLOR}
  `,
  outline: css`
    ${({ theme }) => theme.colors.GREY_1}
  `,
  outlineSecondary: css`
    ${({ theme }) => theme.colors.SECONDARY_COLOR}
  `,
}

export const Button = styled.button`
  padding: 19px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 50px;
  color: white;

  width: 279px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ styleOfButton }) =>
    styleOfButton && types[styleOfButton] ? types[styleOfButton] : ''}

  transition: background-color 0.3s;

  svg {
    height: 20px;
    width: 20px;
    object-fit: cover;

    margin-right: 5px;

    path {
      fill: ${({ styleOfButton }) => svgColor[styleOfButton]};
    }
  }
`
