import styled from 'styled-components';

export const ShareContainer = styled.div`
  margin: 43px auto 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;

  button {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    div {
      width: 37px;
      margin-right: 23px;
    }
  }
`;
