import React, { useEffect, useRef, useState } from 'react';
import SlideCircleProduct from 'components/SlideCircleProduct';
import { isMobile } from 'react-device-detect';

import 'swiper/swiper.min.css';

import * as S from './styled';
import { useProducts } from 'contexts/ProductsContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import ColorSelect from 'components/ColorSelect';
import { truncateWithout } from 'utils/handleString';
import useVariations from 'hooks/useVariations';
import SelectForm from 'components/V2/Select';
import InputNumber from 'components/V2/InputNumber';
import Button from 'components/V2/Button';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import Text from 'components/V2/Text';
import IconComponent from 'components/V2/Icon';
import Modal from 'components/V2/Modal';
import { useMemo } from 'react';

const PADDING_RIGHT = 20;
const SLIDE_WIDTH = 62;
const ProductSlider = ({ list, live, setCarrinho }) => {
  const swiperRef = useRef(null);
  const [colorSelected, setColorSelected] = useState('');
  const [sizeSelected, setSizeSelected] = useState({});
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    productIsSetted,
    selectedProduct,
    setShowAllInfo,
    setSelectedProduct,
  } = useProducts();
  const { addToCarrinho } = useCarrinhoContext();

  const { colorsAvaliable, sizesAvaliable, maxQuantity } = useVariations({
    variations: selectedProduct?.variations || [],
    colorSelected,
    sizeSelected: sizeSelected?.value,
    hasVariations: selectedProduct?.hasVariation,
  });

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(slideIndex);
    }
  }, [slideIndex]);

  useEffect(() => {
    setColorSelected('');
    setSizeSelected({});
    setSelectedQuantity(1);
  }, [selectedProduct, slideIndex]);

  useEffect(() => {
    setSizeSelected({});
  }, [colorSelected]);

  const handleSlideChange = (swiper) => {
    setSlideIndex(swiper.activeIndex);
    setColorSelected('');
  };

  const shouldTranslate = () => {
    if (!productIsSetted) return false;

    if (slideIndex === 0) return false;

    const positionAvaliable = list.length - 3;
    return slideIndex >= positionAvaliable;
  };

  const calculateWidthToTranslate = useMemo(() => {
    const currentWidth =
      swiperRef?.current?.firstChild?.childNodes[0]?.offsetWidth || SLIDE_WIDTH;

    const cardWidth = currentWidth + PADDING_RIGHT;

    let currentIndex = slideIndex;
    let offset = currentIndex % 4;

    const rest = list.length % 4;
    const rangeToSum = list.length - 1 - rest;

    if (currentIndex > rangeToSum && list.length > 4) {
      offset += 1;
    }

    return cardWidth * -1 * offset;
  }, [slideIndex, swiperRef]);

  const renderCoin = (value) => {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const renderCost = () => {
    if (selectedProduct?.promotionalPrice) {
      return (
        <S.WrapCost>
          <S.WrapContentCost>
            <S.CostPrevious>
              {renderCoin(selectedProduct?.originalPrice)}
            </S.CostPrevious>
          </S.WrapContentCost>
          <S.WrapContentCost>
            <S.Cost>{renderCoin(selectedProduct?.promotionalPrice)}</S.Cost>
          </S.WrapContentCost>
        </S.WrapCost>
      );
    }

    return (
      <S.WrapCost>
        <S.WrapContentCost>
          <S.Cost>{renderCoin(selectedProduct?.originalPrice)}</S.Cost>
        </S.WrapContentCost>
      </S.WrapCost>
    );
  };

  const handleAddCarrinho = async () => {
    await addToCarrinho({
      ...selectedProduct,
      selectedColor: colorSelected,
      selectedQuantity,
      selectedSize: sizeSelected?.value,
    });
    setIsOpenModal(true);
  };

  const shouldDisableButton = () => {
    if (!selectedProduct.hasVariation && selectedQuantity !== 0) return false;

    if (!colorsAvaliable?.length && sizeSelected.value) return false;

    if (!sizesAvaliable?.length && colorSelected.value) return false;

    return !colorSelected || selectedQuantity === 0 || !sizeSelected.value;
  };
  const renderShortInfo = () => {
    if (!productIsSetted) return null;

    return (
      <S.ContainerSlideItemTitle>
        <S.SlideFloatTitle>
          {truncateWithout(selectedProduct?.title, 18)}
        </S.SlideFloatTitle>

        <S.WrapDescription>
          <S.WrapColumn>
            <S.SlideItemDescription>
              {truncateWithout(selectedProduct?.description, 200)}
              {selectedProduct?.description?.length > 200 ? '...' : ''}
            </S.SlideItemDescription>

            <S.Link onClick={() => setShowAllInfo(true)}>
              Ver mais detalhes
            </S.Link>

            <ColorSelect
              selectedColor={colorSelected}
              colors={colorsAvaliable}
              setColor={(value) => setColorSelected(value)}
            />
          </S.WrapColumn>
          <S.WrapCost>
            <S.Pill>Restam {selectedProduct?.stockAmount} un.</S.Pill>

            {renderCost()}
          </S.WrapCost>
        </S.WrapDescription>

        <S.WrapDetails>
          <S.WrapForm>
            <Text>Quantidade</Text>

            <InputNumber
              height="38px"
              width="87px"
              minQuantity={1}
              maxQuantity={maxQuantity}
              value={selectedQuantity}
              setValue={setSelectedQuantity}
            />
          </S.WrapForm>

          {selectedProduct.hasVariation && (
            <S.WrapForm>
              <Text>Tamanho</Text>

              <SelectForm
                value={sizeSelected}
                onChange={(e) => setSizeSelected(e)}
                options={sizesAvaliable.map((item) => ({
                  value: item,
                  label: item,
                }))}
                menuPlacement="top"
                type="round"
                size="small"
              />
            </S.WrapForm>
          )}

          <S.WrapButton>
            <Button
              style={{
                width: '105px',
                height: '28px',
              }}
              handleClick={() => handleAddCarrinho()}
              disabled={shouldDisableButton()}
              icon="cartOutline"
            >
              Adicionar
            </Button>
          </S.WrapButton>
        </S.WrapDetails>
      </S.ContainerSlideItemTitle>
    );
  };

  return (
    <S.SwiperContainer live={live} hasProductSelect={productIsSetted}>
      <S.WrapSwiper
        isProductLastOne={shouldTranslate()}
        productPosition={calculateWidthToTranslate}
      >
        <Swiper
          ref={swiperRef}
          spaceBetween={18}
          slidesPerView={4}
          centeredSlides={false}
          onSlideChange={handleSlideChange}
          style={{ overflow: 'visible', paddingRight: PADDING_RIGHT }}
        >
          {list.map((item, index) => {
            return (
              <SwiperSlide key={index} style={{ width: SLIDE_WIDTH }}>
                <SlideCircleProduct
                  item={item}
                  changeSlide={() => handleSlideChange({ activeIndex: index })}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </S.WrapSwiper>
      {renderShortInfo()}

      <Modal
        title="Você adicionou um produto ao carrinho"
        renderButtons={() => (
          <>
            <Button
              icon="linkBack"
              handleClick={() => {
                setIsOpenModal(false);
              }}
            >
              Voltar para a Live
            </Button>
            <S.WrapButtonSecondary>
              <Button
                icon="arrowBack"
                styleOfButton="secondary"
                handleClick={() => {
                  setIsOpenModal(false);
                  setSelectedProduct(null);
                  setCarrinho(selectedProduct);
                }}
              >
                Finalizar compra
              </Button>
            </S.WrapButtonSecondary>
          </>
        )}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
    </S.SwiperContainer>
  );
};

export default ProductSlider;
