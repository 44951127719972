import BackBar from 'components/BackBar';
import LiveLabel from 'components/LiveLabel';
import React from 'react';

import * as S from 'pages/Sobre/styled';

import { ReactComponent as PinkLogo } from '../../assets/logos/pink.svg';

import { ReactComponent as HeartIcon } from '../../assets/about/heart.svg';
import { ReactComponent as TalkIcon } from '../../assets/about/talk.svg';
import { ReactComponent as BagLogo } from '../../assets/about/bag.svg';
import Footer from 'components/Footer';

const Sobre = () => {
  return (
    <div>
      <BackBar path="/" type="back" shadow={true} />
      <S.LogoContainer>
        <PinkLogo />
      </S.LogoContainer>
      <S.ImageContainer>
        <img src="/girl.png" alt="Garota escutando música com fone" />
        <LiveLabel text="Live!" background="pink" textSize="24px" top="18px" right="20px" />
      </S.ImageContainer>
      <S.BlueContainer>
        <h2>Somos a nova era do comércio digital</h2>
        <p>
          Entretenimento, dinâmica e interação vão transformar a maneira de comprar e vender online. A Ugong é a sua
          nova plataforma de ecommerce live.
        </p>
      </S.BlueContainer>
      <S.IconsContainer>
        <S.IconsItemContainer>
          <div>
            <HeartIcon />
          </div>
          <p>Curta</p>
        </S.IconsItemContainer>
        <S.IconsItemContainer>
          <div>
            <TalkIcon />
          </div>
          <p>Comente</p>
        </S.IconsItemContainer>
        <S.IconsItemContainer>
          <div>
            <BagLogo />
          </div>
          <p>Compre</p>
        </S.IconsItemContainer>
      </S.IconsContainer>
      <S.HandContainer>
        <div className="handImgContainer">
          <img src="/hand-phone.png" alt="Utilizando Ugong no celular" />
        </div>
        <S.PinkContainer>
          <p>
            De roupas e calçados a produtos de beleza e saúde, diferentes marcas colaboram como parte de uma experiência
            de varejo combinada.
          </p>
        </S.PinkContainer>
      </S.HandContainer>
      <S.WhiteContainer>
        <h2>Vendedores, passe maior confiança na hora de vender!</h2>
        <p>
          Mostre características do seu produto, tire dúvidas ao vivo, veja feedbacks e explore novas oportunidades
          junto ao seu público. Na Ugong o seu cliente faz tudo de maneira simples e ágil sem sair da live! Vender nunca
          foi tão fácil!
        </p>
      </S.WhiteContainer>
      <S.HandContainerFooter>
        <div className="handImgContainer">
          <img src="/hand-phone.png" alt="Utilizando Ugong no celular" />
        </div>
        <Footer />
      </S.HandContainerFooter>
    </div>
  );
};

export default Sobre;
