
import React from 'react';
import PropTypes from 'prop-types';
import YouTubePlayer from 'components/YouTubePlayer';

/// This component exists only for future-proofing,
/// in a way that it's easier to add other video sources.
const LiveVideoPlayer = ({ URLs }) => {

  let embedID = ''; // default value

  if (URLs && URLs.youtube) {

    // RegEx to find YouTube Video ID inside the URL
    const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|live\/|embed\/|watch\?v=)([^#&?]*).*/;
    const matches = URLs.youtube.match(regex);

    if (matches && matches[1]) {
      embedID = matches[1];
    }
  }

  if (embedID) {
    return <YouTubePlayer embedID={embedID}/>;
  } else {
    return <div>No valid URL found</div>;
  }
}

LiveVideoPlayer.propTypes = {
  URLs: PropTypes.object,
};

export default LiveVideoPlayer;
