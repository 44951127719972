import styled from 'styled-components';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const Button = styled.button`
  background: ${({ background }) =>
    background === 'INSTAGRAM'
      ? C.INSTAGRAM
      : background === 'FACEBOOK'
      ? C.FACEBOOK
      : background === 'GOOGLE'
      ? C.GOOGLE
      : C.SECONDARY_COLOR};
  padding: 19px 0px;
  width: 74.4%;
  max-width: 279px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  ${T.BUTTON_TEXT_EXTRA_LARGE};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
