import React, { createContext, useContext, useState } from 'react';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const [products, setproducts] = useState([{}]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showAllInfo, setShowAllInfo] = useState(false);
  const [showChangeVariation, setShowChangeVariation] = useState(false);

  const checkIfitemIsSelected = (id) => {
    return selectedProduct?._id === id;
  };

  const productIsSetted = !!selectedProduct?.title;

  const store = {
    products,
    setproducts,
    selectedProduct,
    setSelectedProduct,
    checkIfitemIsSelected,
    productIsSetted,
    showAllInfo,
    setShowAllInfo,
    showChangeVariation,
    setShowChangeVariation,
  };

  return (
    <ProductsContext.Provider value={store}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error('useProducts must be used within a Products');
  }
  return context;
};
