import styled from 'styled-components';

export const CarteiraContainer = styled.div`
  min-height: 500px;
  width: 279px;
  max-width: 100%;
  margin: 0px auto 100px;

`;

export const TitleContainer = styled.div`
  margin: 33px 0px;
`;

export const CarteiraAddItem = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;
  height: 113px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarteiraCard = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;

  margin-bottom: 27px;
  /* padding: 40px 0px; */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  transition: background 0.3s;

  cursor: pointer;
/* 
  &:hover {
    background: #d0d0d0;
  } */

  p {
    font-weight: 600;
    font-size: 1.3rem;
  }
`;

export const PaymentText = styled.p`
  margin-bottom: 20px;
`;

export const AddButton = styled.div`
  background: #00dfff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  width: 204px;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  color: ${({theme}) => theme.colors.GREY_1};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;

  svg {
    margin-left: 5px;
    width: 20px;
  }
`;

export const SelectButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  color: ${({theme}) => theme.colors.GREY_1};
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
  width: auto;
  cursor: pointer;

  svg {
    margin-left: 5px;
    width: 20px;
  }
`;

export const WrapPix = styled.div`
  svg {
    object-fit: cover;
    width: 200px;
    height: 30px;
  }
`

export const WrapSvg = styled.div`
  svg {
    object-fit: cover;
    width: 40px;
    height: 50px;
  }
`

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;

  transform: translateY(-50%);

  svg {
    width: ${({icon}) => icon === 'selectRight' ? '12px': '20px'};
  }
`