import React from 'react';

import * as S from './styled';

import { ReactComponent as CancelIcon } from '../../assets/common/cancel.svg';

const Modal = ({ onClick, children, showClose = true }) => {
  return (
    <S.ModalContainer>
      <S.ModalContent>
        {showClose &&
          <S.CloseContainer onClick={onClick}>
            <CancelIcon />
          </S.CloseContainer>}
        {children}
      </S.ModalContent>
    </S.ModalContainer>
  );
};

export default Modal;
