import GlobalStyle from "./constants/styles/global";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes/routes";
import { AuthProvider } from "contexts/AuthContext";
import { LiveProvider } from "contexts/LivesContext";
import { CarrinhoProvider } from "contexts/CarrinhoContext";
import { ShopsProvider } from "contexts/ShopsContext";
import { ProductsProvider } from "contexts/ProductsContext";
import { ThemeProvider } from "styled-components";
import { theme } from "constants/styles/theme";

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <ProductsProvider>
          <ShopsProvider>
            <CarrinhoProvider>
              <LiveProvider>
                <Routes />
              </LiveProvider>
            </CarrinhoProvider>
          </ShopsProvider>
        </ProductsProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
);
