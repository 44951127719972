import React from 'react';

import * as S from './styled';

const AnchorButton = ({ disabled, text, background, path, style }) => {
  return (
    <S.AnchorButton disabled={disabled} background={background} to={path} style={style}>
      {text}
    </S.AnchorButton>
  );
};

export default AnchorButton;
