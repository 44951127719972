import styled from 'styled-components';

import * as C from 'constants/styles/colors';

export const BottomMenuContainer = styled.div`
  overflow: hidden;
`;

export const BottomMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .videoChat {
    position: absolute;
    bottom: ${({ showProducts }) => (showProducts ? '190px' : '50px')};
    left: 25px;
    -webkit-mask-image: ${({ showProducts }) =>
      showProducts
        ? '-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))'
        : '-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))'};
    transition: all 1s ease;
    z-index: 2;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 60px);
  padding: 0px 20px 0px 25px;

  small {
    cursor: pointer;
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
  }
`;

export const UpperButton = styled.div`
  height: 45px;
  width: 60px;
  border-radius: 20px 20px 0px 0px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: #00dfff;
  }
`;

export const ProductsContainer = styled.div`
  max-height: 184px;
  background: linear-gradient(180deg, rgba(2, 2, 2, 0.57) 0%, #020202 100%);
  position: absolute;
  left: 0px;
  bottom: ${({ showProducts }) => (showProducts ? '0px' : '-250px')};
  width: 100%;
  padding: 12px 0px 20px;
  z-index: 2;
  transition: all 1s ease;
`;

export const OcultarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding-right: 25px;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
    margin-right: 10px;
  }

  svg {
    transform: rotate(180deg);
  }

  svg path {
    fill: #00dfff;
  }
`;
