import React from 'react';

import Title from 'components/Title';

import Button from 'components/V2/Button';

import * as S from './styled';

import Text from 'components/V2/Text';
import Loader from 'components/Loader';
import { ReactComponent as Instagram } from 'assets/common/instagram.svg';

const EmpresaSocial = ({ setSteps }) => {
  return (
    <>
      <S.SocialContainer>
        <S.TitleContainer>
          <Title style={{ color: '#FF3D68' }}>Conta Profissional</Title>
        </S.TitleContainer>

        <Text>
          Por favor, conecte sua conta do Instagram com o Ugong para uma melhor
          experência.
        </Text>

        <S.ButtonContainer>
          <Button disabled={false} background="INSTAGRAM">
            <div>
              <Instagram />
            </div>
            Login com Instagram
          </Button>
          {
            <Button
              handleClick={() => setSteps(4)}
              styleOfButton="primaryRed"
              icon="arrowNext"
            >
              Continuar sem vincular
            </Button>
          }
        </S.ButtonContainer>
      </S.SocialContainer>
    </>
  );
};

export default EmpresaSocial;
