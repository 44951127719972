import React, { useEffect, useRef, useState } from 'react';

import { handleLength } from 'utils/handleRegex';
import { ReactComponent as PinkArrow } from 'assets/common/arrow-pink.svg';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './styled';
import api from 'services/api';

import Title from 'components/Title';
import Input from 'components/Input';
import Button from 'components/V2/Button';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/V2/Text';
import Loader from 'components/Loader';
import Textarea from 'components/Textarea';
import SelectGrey from 'components/SelectGrey';
import Variacoes from './Variacoes';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import HeaderCheckout from 'components/HeaderCheckout';
import Modal from 'components/V2/Modal';
import { WrapButton } from 'pages/Empresa/AdicionarEvento/Steps/EditarProduto/styled';
import IconComponent from 'components/V2/Icon';
import { WrapTitle } from 'pages/EditarLive/styled';

const EditarProduto = () => {
  const param = useParams();
  const history = useHistory();
  const productId = param.id;
  const eventId = param.eventId;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fileInput = useRef();
  const [produto, setProduto] = useState({
    photos: [],
    title: '',
    description: '',
    originalPrice: '',
    discountPercentage: '',
    variations: [],
    loading: false,
    error: {
      name: false,
      value: false,
      quantity: false,
    },
  });

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const response = await api.get('/products/getById', {
        params: { _id: productId },
      });
      setProduto({
        ...produto,
        _id: response.data.content[0]._id,
        photos: response.data.content[0].photos,
        title: response.data.content[0].title,
        description: response.data.content[0].description,
        originalPrice: response.data.content[0].originalPrice,
        discountPercentage: response.data.content[0].discountPercentage,
        variations: response.data.content[0].variations,
      });
      setLoading(false);
    };
    getProduct();
  }, []);

  const handleUpdateProduct = async () => {
    if (handleError()) {
      setProduto({
        ...produto,
        error: {
          name: !handleLength(produto.title, 'bigger', 0),
          value: !handleLength(produto.originalPrice, 'bigger', 0),
          quantity: !produto.variations.map((item) => {
            return handleLength(item.quantity, 'bigger', 0);
          }),
        },
      });
    } else {
      setProduto({
        ...produto,
        error: {
          name: false,
          value: false,
          quantity: false,
        },
        loading: true,
      });
      delete produto.error;
      delete produto.loading;
      try {
        const response = await api.post('/products/update', {
          _id: productId,
          ...produto,
        });
        history.push(`/edit-event/${eventId}/products`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleError = () => {
    const { photos, name, value, variations } = produto;

    if (
      !handleLength(name, 'bigger', 0) ||
      !photos.length > 1 ||
      !handleLength(value, 'bigger', 0) ||
      !variations[0]?.quantity
    ) {
      return true;
    } else {
      return false;
    }
  };

  function processFileChange(event) {
    setProduto((el) => {
      return {
        ...el,
        photos: [],
      };
    });

    if (event.target.files?.length > 5) {
      return alert('O máximo são 5');
    }

    if (event.target.files && event.target.files.length) {
      const fileMap = new Map();

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const fileReader = new FileReader();
        fileMap.set(fileReader, file);
      }

      const mapEntries = fileMap.entries();
      readFile(mapEntries);
    }
  }

  function readFile(mapEntries) {
    const nextValue = mapEntries.next();

    if (nextValue.done === true) {
      return;
    }

    const [fileReader, file] = nextValue.value;

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      // Do black magic for each file here (using fileReader.result)
      setProduto((el) => {
        return {
          ...el,
          photos: [...el.photos, fileReader.result],
        };
      });
      // Read the next file
      readFile(mapEntries);
    };
  }

  const handleChange = ({ value, name }) => {
    setProduto({
      ...produto,
      [name]: value,
    });
  };

  const handleDeleteProduct = async () => {
    try {
      const response = await api.delete('/products/delete', {
        params: { _id: produto?._id },
      });
      if (response.data.content == 1) {
        history.push(`/edit-event/${eventId}/products`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <HeaderCheckout
        backAction={() => {
          history.push(`/edit-event/${eventId}/products`);
        }}
        rightBackAction={() => {
          setShowModal(true);
        }}
        rightIconBack="cancel"
        rightBackText="Excluir"
      />
      {loading ? (
        <Loader />
      ) : (
        <S.CadastroContainer className="cadastro-inner-container">
          <WrapTitle>
            <IconComponent icon="packageEdit" />
            <Text type="title">Editar Produto</Text>
          </WrapTitle>
          {/* <S.TitleContainer>
            <Title style={{ color: '#FF3D68' }}>Editar Produto</Title>
          </S.TitleContainer> */}

          {produto.photos.length <= 0 && (
            <S.FileInputContainer>
              <S.FileLabel htmlFor="input-avatar">
                <div>
                  <PinkArrow />
                  <Text as="p" className="labeltext">
                    Pressione aqui e selecione até 5 imagens do produto.
                  </Text>
                </div>
              </S.FileLabel>
              <S.FileInput
                type="file"
                id="input-avatar"
                accept="image/png,   image/jpeg"
                onChange={(e) => processFileChange(e)}
                multiple
              />
            </S.FileInputContainer>
          )}
          {produto.photos.length > 0 && (
            <S.SwiperContainer onClick={() => fileInput.current.click()}>
              <Swiper
                pagination={{ clickable: true }}
                centeredSlides={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: true,
                }}
              >
                {produto.photos.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <S.Avatar background={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <PinkArrow />
              <S.FileInput
                ref={fileInput}
                type="file"
                id="input-avatar"
                accept="image/png,   image/jpeg"
                onChange={(e) => processFileChange(e)}
                multiple
              />
            </S.SwiperContainer>
          )}

          <S.InputsContainer>
            <S.Form>
              <Input
                placeholder="Nome do Produto"
                type="text"
                value={produto.title}
                handleChange={handleChange}
                error={produto.error.name}
                name="title"
                required={true}
              />
              <S.TextareaContainer>
                <Textarea
                  placeholder="Descrição do Produto"
                  type="text"
                  value={produto.description}
                  handleChange={handleChange}
                  name="description"
                />
              </S.TextareaContainer>
              <S.HalfInputContainer>
                <S.RealContainer>
                  <Input
                    placeholder="Valor"
                    value={produto.originalPrice}
                    handleChange={handleChange}
                    error={produto.error.value}
                    name="originalPrice"
                    required={true}
                    type="number"
                  />
                  {produto.value > 0 && <p>R$</p>}
                </S.RealContainer>
                <SelectGrey
                  placeholder="Desconto"
                  value={produto.discountPercentage + '%'}
                  handleChange={handleChange}
                  name="discountPercentage"
                  optionList={[
                    { discountPercentage: 'Sem desconto' },
                    { discountPercentage: '5%' },
                    { discountPercentage: '10%' },
                    { discountPercentage: '15%' },
                    { discountPercentage: '20%' },
                    { discountPercentage: '50%' },
                    { discountPercentage: '70%' },
                  ]}
                />
              </S.HalfInputContainer>
              {produto.variations && produto.variations.length > 0 && (
                <Variacoes setProduto={setProduto} produto={produto} />
              )}
              {(produto.error.name ||
                produto.error.value ||
                produto.error.quantity) && (
                <S.ErrorMessageContainer>
                  <ErrorMessage>
                    Preencha todos os campos necessários
                  </ErrorMessage>
                </S.ErrorMessageContainer>
              )}
            </S.Form>

            <S.ButtonContainer>
              <Button
                disabled={
                  !produto.photos.length > 0 ||
                  !produto.title ||
                  !produto.originalPrice ||
                  !produto.variations[0]?.quantity ||
                  loading
                }
                handleClick={handleUpdateProduct}
                style={{ background: '#FF3D68' }}
                notScroll
              >
                Salvar Edição
              </Button>
            </S.ButtonContainer>
          </S.InputsContainer>
        </S.CadastroContainer>
      )}

      <Modal
        icon=""
        isOpen={showModal}
        setIsOpen={setShowModal}
        bodyMessage="Confirma a exclusão do Produto"
        renderButtons={() => (
          <WrapButton>
            <Button
              icon="cancel"
              styleOfButton="primaryRed"
              handleClick={() => {
                handleDeleteProduct();
              }}
            >
              Excluir
            </Button>
          </WrapButton>
        )}
      />

      <Footer showTop={true} />
    </>
  );
};

export default EditarProduto;
