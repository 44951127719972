import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as C from 'constants/styles/colors';
import * as T from 'constants/styles/typography';

export const AnchorButton = styled(Link)`
  background-color: ${({ background }) => (background !== 'primary' ? C.PRIMARY_COLOR : C.SECONDARY_COLOR)};
  padding: 19px 0px;
  width: 74.4%;
  max-width: 279px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  ${T.BUTTON_TEXT_EXTRA_LARGE};
  color: white;
  display: block;
  text-align: center;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
