import qs from 'qs';
import React from 'react';
import PropTypes from 'prop-types';

const YouTubePlayer = ({ embedID }) => {

  /// YouTube player in-line options
  /// Doc: https://developers.google.com/youtube/player_parameters
  const options = {
    fs: 0,
    rel: 0,
    mute: 0,
    loop: 1,
    showinfo: 0,
    controls: 0,
    autohide: 1,
    autoplay: 1,
    disablekb: 1,
    playsinline: 1,
    enablejsapi: 1,
    color: 'white',
    cc_load_policy: 0,
    modestbranding: 1,
    iv_load_policy: 3,
  };

  // hack to hide YouTube Logo
  const insetMargin = 60;

  return (
    <div style={{ 
        height: `calc(100% + ${insetMargin * 2}px)`,
        marginTop: `-${insetMargin}px`,
    }}>
      <iframe
        id='youtube-player'
        src={`https://www.youtube.com/embed/${embedID}?${qs.stringify(options)}`}
        style={{ width: '100%', height: '100%' }}
        title=""
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        referrerPolicy='no-referrer'
      />
    </div>
  )
}

YouTubePlayer.propTypes = {
  embedID: PropTypes.string.isRequired,
};

export default YouTubePlayer;
