import styled from 'styled-components';

export const AddressContainer = styled.div`
  min-height: 500px;
  width: 279px;
  max-width: 100%;
  margin: 0px auto 100px;

  button {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  margin: 33px 0px;
`;

export const AddressAddItem = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;
  height: 113px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddressCard = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  width: 100%;

  margin-bottom: 27px;
  padding: 40px 30px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  transition: background 0.3s;

  &:hover {
    background: #d0d0d0;
  }

  p {
    margin-bottom: 20px;
    font-weight: 400;
    text-align: center;
    font-size: 1.3rem;
  }
`;

export const AddButton = styled.div`
  background: #00dfff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  width: 204px;
  padding: 10px 0px;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;

  svg {
    margin-left: 5px;
  }
`;


export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;

  transform: translateY(-50%);

  svg {
    width: 10px;
  }
`

export const WrapSvg = styled.div`
  svg {
    object-fit: cover;
    width: 10px;
    height: 50px;
  }
`