import React from 'react';

import * as S from './styled';

import { ReactComponent as Whatsapp } from '../../assets/social/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/social/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/social/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/social/twitter.svg';
import { ReactComponent as Tiktok } from '../../assets/social/tiktok.svg';
import { ReactComponent as Dropdown } from '../../assets/common/dropdown.svg';
import { ReactComponent as Logo } from '../../assets/logos/white-extense.svg';
import { Link } from 'react-router-dom';

const links = [
  // {
  //   path: '/',
  //   image: <Whatsapp />,
  // },
  {
    path: 'https://instagram.com/ugong.live',
    image: <Instagram />,
  },
  {
    path: 'https://www.facebook.com/profile.php?id=100071756661207',
    image: <Facebook />,
  },
  // {
  //   path: '/',
  //   image: <Twitter />,
  // },
  // {
  //   path: '/',
  //   image: <Tiktok />,
  // },
];

const Footer = ({ showTop }) => {

  // turning the footer top off as a business decision
  showTop = false;

  return (
    <S.FooterContainer>
      {showTop && (
        <S.FooterTop>
          <S.TextContainer>
            <small>&copy;2022 Ugong</small>
            <Link to="/sobre">Sobre</Link>
          </S.TextContainer>
          <S.UpperButton
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }}
          >
            <Dropdown />
          </S.UpperButton>
        </S.FooterTop>
      )}
      <S.Footer className="footer">
        <Link to="/">
          <Logo />
        </Link>
        <ul>
          {links.map((el, index) => {
            return (
              <li key={index}>
                <a target="_blank" rel="external noreferrer" href={el.path}>{el.image}</a>
              </li>
            );
          })}
        </ul>
      </S.Footer>
    </S.FooterContainer>
  );
};

export default Footer;
