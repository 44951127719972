import styled from 'styled-components';

export const SearchLives = styled.div`
  width: 90%;
  min-width: 333px;
  height: 216px;
  margin: 0 auto;
  border-radius: 11.6453px;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.35) 95.83%),
    ${({ thumb }) => (thumb ? `url(${thumb})` : 'grey')} no-repeat;
  border: 0.5px solid #c1c1c1;
  background-size: 100% 216px;
  position: relative;

  @media (max-width: 375px) {
    min-width: 100%;
  }
`;

export const ContainerSearchLivesTitle = styled.div`
  position: absolute;
  bottom: 22px;
  right: 24px;
  width: 55%;
  text-align: right;
`;

export const SearchLivesTitle = styled.p`
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.32px;
`;

export const StoreLogo = styled.div`
  background: ${({ logo }) => (logo ? `url(${logo})` : 'black')} no-repeat;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #adadad;
`;
