import Loader from 'components/Loader';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import { ReactComponent as UserIcon } from 'assets/empresa/avatar.svg';
import { ReactComponent as PinkArrow } from 'assets/common/arrow-pink.svg';

import { handleLength } from 'utils/handleRegex';

import * as S from './styled';
import api from 'services/api';
import Button from 'components/V2/Button';
import BackBar from 'components/BackBar';
import Footer from 'components/Footer';
import Modal from 'components/V2/Modal';
import Title from 'components/Title';
import Input from 'components/Input';
import ErrorMessage from 'components/ErrorMessage';
import Text from 'components/V2/Text';
import InputWithMask from 'components/InputMask';
import HeaderCheckout from 'components/HeaderCheckout';
import { WrapCancelButton } from 'containers/ShopperSlider/styled';
import IconComponent from 'components/V2/Icon';

const EditarLive = () => {
  const params = useParams();
  const history = useHistory();
  const eventId = params.id;
  const [eventInfo, setEventInfo] = useState({
    id: '',
    title: '',
    hour: '',
    date: '',
    videoUrl: '',
    thumb: '',
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('eventInfo')) {
      localStorage.setItem('eventInfo', JSON.stringify({}));
    }
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await api.get('/events/getStuff', {
        params: { _id: eventId },
      });
      const eventDate = new Date(response.data.content.scheduled);
      const eventHour = eventDate.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const eventDay = eventDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      setEventInfo({
        ...eventInfo,
        id: response.data.content._id,
        title: response.data.content.title,
        hour: eventHour,
        date: eventDay,
        videoUrl: response.data.content.videoURLs.youtube,
        thumb: response.data.content.thumb,
      });
      setLoading(false);
    };
    if (
      localStorage.getItem('eventInfo').length < 1 ||
      JSON.parse(localStorage.getItem('eventInfo')).id !== eventId
    ) {
      getProducts();
    } else {
      setEventInfo(JSON.parse(localStorage.getItem('eventInfo')));
    }
  }, []);

  useEffect(() => {
    if (eventInfo.id) {
      localStorage.setItem('eventInfo', JSON.stringify(eventInfo));
    }
  }, [eventInfo]);

  const handleDeleteEvent = async () => {
    try {
      const response = await api.post('/events/delete', {
        _id: eventInfo.id,
      });
      if (response.status == 200) {
        setShowConfirmation(false);
        setShowModal(false);
        history.push('/');
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRedirectToProducts = (productId) => {
    try {
      history.push(`/edit-event/${eventId}/products`);
    } catch (e) {
      console.error(e);
    }
  };

  const updateEvent = async () => {
    if (handleError()) {
      setEventInfo({
        ...eventInfo,
        error: true,
      });
    } else {
      let datetime = new Date();
      datetime.setFullYear(parseInt(eventInfo.date.split('/')[2]));
      datetime.setMonth(parseInt(eventInfo.date.split('/')[1]) - 1);
      datetime.setDate(parseInt(eventInfo.date.split('/')[0]));
      datetime.setHours(parseInt(eventInfo.hour.split(':')[0]));
      datetime.setMinutes(parseInt(eventInfo.hour.split(':')[1]));

      try {
        const response = await api.post('/events/update', {
          _id: eventInfo.id,
          title: eventInfo.title,
          scheduled: datetime,
          videoURLs: {
            youtube: eventInfo.videoUrl,
          },
          thumb: eventInfo.thumb,
        });
        if (response.data.content) {
          history.push(`/`);
          window.location.reload();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e;
    setEventInfo({ ...eventInfo, [name]: value });
  };

  const handleUpdateFile = (e) => {
    // Assuming only image
    let file = e.target.files[0];

    if (file) {
      let reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setEventInfo({
          ...eventInfo,
          thumb: reader.result,
        });
      };
    }
  };

  const handleError = () => {
    const { hour, date, title, thumb, videoUrl } = eventInfo;
    if (
      !handleLength(thumb, 'bigger', 0) ||
      !handleLength(date, 'bigger', 6) ||
      !handleLength(hour, 'bigger', 4) ||
      !handleLength(title, 'bigger', 2) ||
      !handleLength(videoUrl, 'bigger', 10)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <HeaderCheckout
        backAction={() => {
          history.push('/');
        }}
        rightBackAction={() => {
          setShowConfirmation(true);
        }}
        rightIconBack="cancel"
        rightBackText="Excluir"
      />
      <S.Wrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            <S.WrapTitle>
              <IconComponent icon="fileEdit" />
              <Text type="title">Editar a Live</Text>
            </S.WrapTitle>
            <S.FileInputContainer>
              <S.FileLabel htmlFor="input-avatar">
                {!eventInfo.thumb && (
                  <div>
                    <PinkArrow />
                    <Text as="p" className="labeltext">
                      Pressione aqui e selecione uma imagem para o evento.
                    </Text>
                  </div>
                )}
                {eventInfo.thumb && (
                  <>
                    <S.Avatar
                      background={eventInfo.thumb ? eventInfo.thumb : ''}
                    />
                    <div className="pinkArrow">
                      <PinkArrow />
                    </div>
                    <S.ContainerSlideItemTitle>
                      <S.SlideItemTitle>{eventInfo.title}</S.SlideItemTitle>
                    </S.ContainerSlideItemTitle>
                  </>
                )}
              </S.FileLabel>
              <S.FileInput
                type="file"
                id="input-avatar"
                accept="image/png,image/jpeg"
                onChange={(e) => handleUpdateFile(e)}
              />
            </S.FileInputContainer>
            <S.InputsContainer>
              <S.Form>
                <Input
                  placeholder="Nome da Live"
                  type="text"
                  value={eventInfo.title}
                  handleChange={(e) => handleChange(e)}
                  error={eventInfo.error}
                  name="title"
                  required={true}
                />
                <Input
                  placeholder="Link da live (YouTube)"
                  type="text"
                  value={eventInfo.videoUrl}
                  handleChange={(e) => handleChange(e)}
                  error={eventInfo.error}
                  name="videoUrl"
                  required={true}
                />
                <S.HalfInputContainer>
                  <InputWithMask
                    mask="99/99/9999"
                    placeholder="Data"
                    value={eventInfo.date}
                    handleChange={(e) => handleChange(e)}
                    error={eventInfo.error}
                    name="date"
                    required={true}
                  />
                  <InputWithMask
                    mask="99:99"
                    placeholder="Hora"
                    //Value will get hour from eventInfo.scheduled
                    value={eventInfo.hour}
                    handleChange={(e) => handleChange(e)}
                    error={eventInfo.error}
                    name="hour"
                    required={true}
                  />
                </S.HalfInputContainer>
                {eventInfo.error && (
                  <S.ErrorMessageContainer>
                    <ErrorMessage>Digite dados válidos</ErrorMessage>
                  </S.ErrorMessageContainer>
                )}
              </S.Form>
            </S.InputsContainer>

            <S.ButtonContainer>
              <Button
                handleClick={handleRedirectToProducts}
                styleOfButton="outlineSecondary"
                icon="pencil"
              >
                Editar Produtos
              </Button>

              <Button
                disabled={
                  !eventInfo.hour ||
                  !eventInfo.date ||
                  !eventInfo.thumb ||
                  !eventInfo.title ||
                  !eventInfo.videoUrl
                }
                handleClick={updateEvent}
                icon="save"
              >
                Salvar Edição
              </Button>
            </S.ButtonContainer>
          </>
        )}
      </S.Wrapper>

      <Modal
        icon="transmissionClose"
        title="Tem certeza que deseja deletar a Live?"
        isOpen={showConfirmation}
        setIsOpen={() => setShowModal({ step: 'main', isOpen: false })}
        renderButtons={() => (
          <WrapCancelButton>
            <Button
              handleClick={() => {
                handleDeleteEvent();

                setShowModal({
                  step: 'main',
                  isOpen: false,
                });
              }}
              styleOfButton="outlinePrimary"
              icon="cancel"
            >
              Deletar
            </Button>
          </WrapCancelButton>
        )}
      />
      <Footer showTop={true} />
    </>
  );
};

export default EditarLive;
