import * as yup from 'yup'
function TestaCPF(strCPF) {
  let Soma
  let Resto
  Soma = 0
  if ([...strCPF].every((item) => item === strCPF[0])) return false

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(strCPF.substring(9, 10))) return false

  Soma = 0
  for (let a = 1; a <= 10; a++)
    Soma = Soma + parseInt(strCPF.substring(a - 1, a)) * (12 - a)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(strCPF.substring(10, 11))) return false
  return true
}

export const Schema = yup.object({
  fullName: yup.string().required('Nome completo é obrigatório'),
  doc: yup
    .string()
    .test('Validate CPF', 'CPF inválido', (valueRaw) => {
      const value = valueRaw.replace(/\./g, '').replace(/-/g, '')
      return TestaCPF(value)
    })
    .required('CPF é obrigatório'),
  birthDate: yup
    .string()
    .test('Date validation', 'Data inválida', (value) => {
      const splited = value?.split('/')
      if (splited?.length < 3) {
        return false
      }

      const validateDate = new Date(1900, 0, 1)
      const date = new Date(`${splited[2]}/${splited[1]}/${splited[0]}`)

      if (isNaN(date.getTime())) return false

      return date > validateDate
    })
    .required('Data é obrigatória'),
  phone: yup
    .string()
    .matches(/\+55 \(\d{2}\) 9\d{4}-\d{4}/g, 'Telefone inválido')
    .required('Telefone é obrigatório'),
})
