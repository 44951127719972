import React from 'react'

import * as S from './styled'
import InputMask from 'react-input-mask'
import Text from 'components/V2/Text'

// eslint-disable-next-line react/display-name
const InputWithMask = React.forwardRef(
  (
    {
      mask,
      disabled,
      value,
      type = 'text',
      placeholder,
      error,
      handleChange,
      name,
      required,
      onChange,
      ...rest
    },
    ref
  ) => {
    return (
      <S.InputContainer>
        <InputMask
          value={value}
          mask={mask}
          onChange={(e) =>
            handleChange ? handleChange(e.target) : onChange(e)
          }
          maskChar=""
          ref={ref}
          {...rest}
        >
          {(inputProps) => (
            <S.Input
              disabled={disabled}
              type={type}
              placeholder={placeholder}
              name={name}
              error={error}
              {...inputProps}
            />
          )}
        </InputMask>
        {required && <div>*</div>}
        {error?.message && <Text type="error">{error?.message}</Text>}
      </S.InputContainer>
    )
  }
)

export default InputWithMask
