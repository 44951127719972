import BackBar from 'components/BackBar';
import Button from 'components/Button';

import Text from 'components/Text';
import Title from 'components/Title';
import React, { useEffect, useState } from 'react';

import * as S from './styled';

import { useCarrinhoContext } from 'contexts/CarrinhoContext';

import { useHistory } from 'react-router-dom';
import ProductLine from 'components/ProductLine';
import HeaderCheckout from 'components/HeaderCheckout';

const PagamentoConcluir = ({ selectedAddress, live, type }) => {
  const { carrinho, cleanCart } = useCarrinhoContext();
  const history = useHistory();

  return (
    <>
      <HeaderCheckout title={'Pedido feito, obrigado!'} />
      <S.Concluir>
        <S.PagamentoContent>
          <S.TextContainer>
            <Text as="p">
              <span>Entraremos em contato por e-mail.</span>
            </Text>
          </S.TextContainer>
        </S.PagamentoContent>
        {carrinho.map((el, index) => {
          return <ProductLine key={index} el={el} />;
        })}
      </S.Concluir>

      <S.DoneRegister>
        <Button
          handleClick={() => {
            history.push('/');
            cleanCart();
          }}
        >
          Voltar para a home
        </Button>
      </S.DoneRegister>
    </>
  );
};

export default PagamentoConcluir;
