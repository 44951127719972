import React, { useEffect, useState } from 'react';
import { useLiveContext } from 'contexts/LivesContext';

import CircleSlider from 'containers/CircleSlider';
import Loader from 'components/Loader';
import Slider from 'containers/Slider';
import ShopperSlider from 'containers/ShopperSlider';

import * as S from './styled';

const HomeLive = ({ isshop }) => {
  const [lives, setLives] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const livesContext = useLiveContext();

  useEffect(() => {

    setLoading(livesContext.isLoading);

    const data = livesContext.lives;
    setLives(data);

    setLoading(livesContext.isLoading);
  }, [livesContext]);

  return (
    <>
      <S.HomeContainer>
        {loading && <Loader />}
        {!loading && (
          <>
            {isshop && <ShopperSlider list={lives?.getByShop || []} live={true} title="Seus eventos" />}
            {lives?.getOnAir && lives?.getOnAir.length > 0 && (
              <Slider list={lives?.getOnAir} title="Ao vivo" live={true} showLabel="live" />
            )}
            {lives?.getUpComing && lives?.getUpComing.length > 0 && (
              <CircleSlider list={lives?.getUpComing} title="Em breve" />
            )}
            {lives?.getHighlights && lives?.getHighlights.length > 0 && (
              <Slider list={lives?.getHighlights} title="Destaques da semana" showLabel="day" />
            )}
            {/*   <Slider list={lives?.getHighlights} title="Fashion e beleza" /> */}
            {lives?.getDiscovery && lives?.getDiscovery.length > 0 && (
              <Slider list={lives?.getDiscovery} title="Descubra" />
            )}
          </>
        )}
      </S.HomeContainer>
    </>
  );
};

export default HomeLive;
