import React, { useState } from 'react';

import * as S from './styled';

import Modal from 'components/Modal';
import Title from 'components/Title';
import Text from 'components/Text';
import InputWithMask from 'components/InputMask';
import Input from 'components/Input';
import Button from 'components/Button';
import AnchorButton from 'components/AnchorButton';

export const OutOfReach = ({ onClick }) => {
  const [data, setData] = useState({
    zipCode: '',
    email: '',
    error: false,
  });
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.name]: e.value,
    });
  };

  const handleSend = () => {
    // enviar e-mail
    setSent(true);
  };

  return (
    <Modal onClick={onClick}>
      {!sent && (
        <>
          <S.TitleContainer>
            <Title>Não está achando?</Title>
          </S.TitleContainer>

          <S.TextContainer>
            <Text>Insira seu CEP e e-mail e avisaremos quando estivermos na sua área.</Text>
          </S.TextContainer>
          <S.InputsContainer>
            <InputWithMask
              mask="99999-999"
              placeholder="Digite seu cep"
              type="text"
              value={data.zipCode}
              handleChange={handleChange}
              error={data.error}
              name="zipCode"
            />
            <Input
              placeholder="Digite seu email"
              type="email"
              value={data.email}
              handleChange={handleChange}
              error={data.error}
              name="email"
            />
          </S.InputsContainer>
          <Button disabled={data.email === '' || data.zipCode === ''} handleClick={handleSend}>
            Enviar
          </Button>
        </>
      )}
      {sent && (
        <S.SuccessContainer>
          <S.TitleContainer>
            <Title>Email enviado com sucesso</Title>
          </S.TitleContainer>
          <S.TextContainer>
            <Text>Insira seu CEP e e-mail e avisaremos quando estivermos na sua área.</Text>
          </S.TextContainer>
          <AnchorButton path="/" text="Voltar para ínicio" background="primary" disabled={false} />
        </S.SuccessContainer>
      )}
    </Modal>
  );
};

export default OutOfReach;
