import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'
import Text from 'components/V2/Text'

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(
  (
    {
      disabled,
      value,
      type = 'text',
      placeholder,
      error,
      handleChange,
      name,
      required,
      label,
      as = 'input',
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <S.Label for={name} error={error}>
            {label}
          </S.Label>
        )}
        <S.InputContainer>
          <S.Input
            ref={ref}
            id={name}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleChange && handleChange(e.target)}
            name={name}
            error={error}
            as={as}
            {...rest}
          />
          {required && <div>*</div>}
          {error?.message && <Text type="error">{error?.message}</Text>}
        </S.InputContainer>
      </>
    )
  }
)

Input.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'tel', 'number', 'email']).isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  as: PropTypes.oneOf(['input', 'textarea']).isRequired,
}
export default Input
