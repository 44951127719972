import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import * as S from './styled';
import BackBar from 'components/BackBar';

import data from '../HomeLive/data.json';
import { filterArray } from 'utils/handleArray';
import Loader from 'components/Loader';
import Title from 'components/Title';
import Text from 'components/Text';
import SearchLives from 'components/SearchLives';
import { handleLiveDate } from 'utils/handleDate';

const HomeBuscar = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [lives, setLives] = useState([]);

  const handleSearch = useCallback(
    (list) => {
      setLoading(true);
      const { search } = qs.parse(location.search, { ignoreQueryPrefix: true });
      setTimeout(() => {
        const filtered = filterArray(list, ['title', 'shop.name', 'type'], search);

        const onAir = filtered.filter((el) => handleLiveDate(el) === 'live').sort((a, b) => b - a);
        const inDay = filtered.filter((el) => handleLiveDate(el) === 'day').sort((a, b) => b - a);
        const inElse = filtered.filter((el) => handleLiveDate(el) === false);

        setLives([...onAir, ...inDay, ...inElse]);
        setLoading(false);
        return filtered;
      }, 1500);
    },
    [location.search],
  );

  useEffect(() => {
    handleSearch(data);
  }, [handleSearch]);

  return (
    <S.HomeContainer>
      <BackBar text="Voltar" type="back" path="/" />
      {loading && <Loader />}
      {!loading && lives.length <= 0 && (
        <S.NotFound>
          <Title>Busca não encontrada</Title>
        </S.NotFound>
      )}
      {!loading && lives.length > 0 && (
        <S.Found>
          {lives.length === 1 ? (
            <Text>
              Foi encontrado <span>{lives.length}</span> resultado
            </Text>
          ) : (
            <Text>
              Foram encontrados <span>{lives.length}</span> resultados
            </Text>
          )}
          <S.ListaLives>
            {lives.map((el, index) => (
              <SearchLives item={el} key={index} showLabel={true} />
            ))}
          </S.ListaLives>
        </S.Found>
      )}
    </S.HomeContainer>
  );
};

export default HomeBuscar;
