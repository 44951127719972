import React from 'react';
import * as S from './styled';

const Title = ({ children, as, style }) => {
  return (
    <S.Title as={as ? as : 'p'} style={style}>
      {children}
    </S.Title>
  );
};

export default Title;
