import styled from 'styled-components';

export const CalendarItemContainer = styled.div`
  margin-top: 20px;
`;

export const LinkCalendar = styled.div`
  a {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #8a8a8a;

    div {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
`;
