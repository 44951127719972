import React, { useEffect, useState } from 'react';

const useVariations = ({
  variations,
  colorSelected,
  sizeSelected,
  hasVariation,
}) => {
  const [colorsAvaliable, setColorsAvaliable] = useState([]);
  const [sizesAvaliable, setSizesAvaliable] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState([]);

  const [optionAvaliable, setOptionAvaliable] = useState([]);

  useEffect(() => {
    if (variations.length) {
      const filterColors = variations
        ?.map((variation) => variation.color)
        ?.reduce((acc, prev) => {
          if (acc.includes(prev)) {
            return acc;
          }

          acc.push(prev);
          return acc;
        }, [])
        ?.filter((item) => !!item);

      setColorsAvaliable(filterColors);
    }
  }, [variations]);

  useEffect(() => {
    if (!sizeSelected && !colorSelected && hasVariation) {
      setColorsAvaliable([]);
      setSizesAvaliable([]);
      setMaxQuantity([]);
    }
  }, [colorSelected, hasVariation, sizeSelected, variations]);

  useEffect(() => {
    setSizesAvaliable([]);
    setMaxQuantity([]);
  }, [colorSelected]);

  useEffect(() => {
    if (variations.length) {
      let filterColors = variations?.filter(
        (variation) => variation.quantity > 0 && variation.size
      );

      if (colorSelected) {
        filterColors = variations?.filter(
          (variation) => variation.color === colorSelected
        );
      }

      setSizesAvaliable(filterColors.map((item) => item.size));
    }
  }, [colorSelected, variations]);

  useEffect(() => {
    if (!hasVariation && variations.length) {
      setMaxQuantity(parseInt(variations[0].quantity));
    } else if (colorSelected !== '' && sizeSelected !== '') {
      const option = variations.find(
        (variation) =>
          variation.color === colorSelected &&
          variation.size === sizeSelected &&
          variation.quantity > 0
      );
      setOptionAvaliable(option);

      if (option) {
        setMaxQuantity(parseInt(option.quantity));
      }
    }
  }, [colorSelected, hasVariation, sizeSelected, variations]);

  return {
    colorsAvaliable,
    sizesAvaliable,
    optionAvaliable,
    maxQuantity,
  };
};

export default useVariations;
