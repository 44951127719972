import styled from 'styled-components';

export const HomeContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  min-height: 500px;
  margin-bottom: 100px;
`;

export const HomeBuscar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 130px;

  p {
    width: 154px;
  }
`;

export const Found = styled.div`
  margin-top: 24px;
`;

export const ListaLives = styled.div`
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 50px 20px;

  @media (max-width: 375px) {
    > div {
      width: 90%;
    }
  }
`;
