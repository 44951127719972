import React from 'react';
import BackBar from 'components/BackBar';
import AnchorButton from 'components/AnchorButton';

import * as S from './styled';
import Footer from 'components/Footer';
import Title from 'components/Title';

const Conta = () => {
  return (
    <>
      <BackBar type="cancel" path="/" shadow={true} text="Conta" />
      <S.TitleContainer>
        <Title>Boas-Vindas</Title>
      </S.TitleContainer>
      <S.ButtonsContainer>
        <AnchorButton text="Acesse a Ugong" background="primary" disabled={false} path="/login" />
        <AnchorButton text="Ajuda" background="primary" disabled={false} path="/ajuda" />
      </S.ButtonsContainer>
      <Footer showTop={true} />
    </>
  );
};

export default Conta;
