import BackBar from 'components/BackBar';
import Done from 'components/Done';
import Footer from 'components/Footer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import EmpresaCarteira from './Steps/Carteira';
import EmpresaCategorias from './Steps/Categorias';
import EmpresaDados from './Steps/Dados';
import EmpresaEndereco from './Steps/Endereco';
import EmpresaSocial from './Steps/Social';
import StepsLine from './StepsLine';

import * as S from './styled';
import HeaderCheckout from 'components/HeaderCheckout';
import { useHistory } from 'react-router-dom';

export const EmpresaCadastro = () => {
  const history = useHistory();
  const { id } = useParams();
  const [categorias, setCategorias] = useState({
    categories: [],
    loading: false,
  });
  const [account, setAccount] = useState({
    logo: '',
    cnpj: '',
    razaoSocial: '',
    nomeFantasia: '',
    inscricaoEstadual: '',
    codigoIndicacao: '',
    enderecoComercial: '',
    website: '',
    cellphone: '',
    telefone: '',
    loading: false,
    error: false,
    cnpjExists: false,
  });
  const [carteira, setCarteira] = useState([]);
  const [endereco, setEndereco] = useState({
    address: '',
    website: '',
    cellphone: '',
    telefone: '',
    loading: false,
    error: false,
  });

  const [steps, setSteps] = useState(0);

  useEffect(() => {
    if (id) {
      getShops(id);
    }
  }, [id]);

  const getShops = async (id) => {
    try {
      const {
        data: { content },
      } = await api.get('/shops/read');

      const shop = content.filter((el) => el._id === id)[0];

      setAccount({
        ...account,
        ...shop,
        ...shop.companyDetails,
      });
      setEndereco({
        ...endereco,
        ...shop,
      });
      setCategorias({
        ...categorias,
        ...shop,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {steps !== 4 && (
        // <BackBar
        //   type="back"
        //   path={steps === 0 || steps === 5 ? '/perfil' : '/empresa/cadastro'}
        //   shadow={true}
        //   text="Voltar"
        //   onClick={() => setSteps((el) => el - 1)}
        // />

        <HeaderCheckout
          title=""
          backText="Voltar"
          backAction={() => {
            if (steps === 0 || steps === 5) {
              history.push('/perfil');
              return;
            }

            setSteps((el) => el - 1);
          }}
        />
      )}
      <S.CadastroEmpresa>
        {steps <= 3 && <StepsLine step={steps} />}
        {steps === 0 && (
          <EmpresaDados
            dados={account}
            handleChange={({ name, value }) => {
              setAccount({
                ...account,
                [name]: value,
              });
            }}
            setDados={setAccount}
            setSteps={setSteps}
          />
        )}
        {steps === 1 && (
          <EmpresaEndereco
            dados={{ ...endereco, logo: account.logo, _id: account._id }}
            handleChange={({ name, value }) => {
              setEndereco({
                ...endereco,
                [name]: value,
              });
            }}
            setDados={setEndereco}
            setSteps={setSteps}
          />
        )}
        {steps === 2 && (
          <EmpresaCategorias
            dados={{ ...categorias, _id: account._id }}
            setDados={setCategorias}
            setSteps={setSteps}
          />
        )}
        {steps === 3 && <EmpresaSocial setSteps={setSteps} />}{' '}
        {/* Currently not acessible */}
        {steps === 4 && (
          <EmpresaCarteira
            setSteps={setSteps}
            carteira={carteira}
            account={account}
            categorias={categorias}
            endereco={endereco}
            setCarteira={setCarteira}
          />
        )}
        {steps === 5 && (
          <Done
            text="Loja cadastrada com sucesso"
            to="/perfil"
            background="secondary"
            button="Ir para o Perfil"
          />
        )}
      </S.CadastroEmpresa>
      <Footer showTop={true} />
    </>
  );
};
