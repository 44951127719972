import React from 'react';
import * as S from './styled';

const Text = ({ children, style, as, className = '' }) => {
  return (
    <S.Text style={style} as={as ? as : 'p'} className={className}>
      {children}
    </S.Text>
  );
};

export default Text;
