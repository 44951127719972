import React, { useState, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { handleCNPJ, handleLength } from 'utils/handleRegex';

import * as S from './styled';
import api from 'services/api';

import Input from 'components/Input';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import InputWithMask from 'components/InputMask';
import SelectGrey from 'components/SelectGrey';

import banks from './banks.json';
import { truncate } from 'utils/handleString';

const CardPaymentForm = ({ setAddCard, setCarteira, carteira }) => {
  const context = useAuthContext();
  const [tribute, setTribute] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [dados, setDados] = useState({
    banco: '',
    cnpj: '',
    agencia: '',
    digitoAgencia: '',
    conta: '',
    digitoConta: '',
    validation: true,
    favorite: true,
    loading: false,
    error: false,
  });

  const handleRegister = async () => {
    setDados({
      ...dados,
      error: false,
      loading: true,
    });

    if (handleError()) {
      setDados({
        ...dados,
        error: true,
      });
    } else {
      //const response = await api.post('/shops/bankAccount', dados);
      try {
        setDados({
          ...dados,
          error: false,
        });
        setCarteira([...carteira, dados]);
        setAddCard(false);
      } catch (err) {
        setDados({
          ...dados,
          error: true,
          loading: false,
        });
      }
    }
  };

  const handleChange = ({ name, value }) => {
    setDados({
      ...dados,
      [name]: value,
    });
  };

  const handleError = () => {
    const { agencia, conta, banco, cnpj } = dados;
    if (
      !handleLength(agencia, 'bigger', 0) ||
      !handleLength(conta, 'bigger', 0) ||
      !handleLength(banco, 'bigger', 0) ||
      !handleLength(cnpj, 'bigger', 17) ||
      !handleCNPJ(cnpj)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const newBankList = banks.map((el) => {
      const text = truncate(el.value + ' - ' + el.label);
      return {
        banco: text,
      };
    });
    setBankList(newBankList);
  }, [context]);

  return (
    <>
      <S.InputsContainer>
        <S.Form>
          <SelectGrey
            required={true}
            placeholder="Banco"
            value={dados.banco}
            handleChange={handleChange}
            error={dados.error}
            name="banco"
            optionList={bankList}
          />
          <InputWithMask
            mask="99.999.999/9999-99"
            placeholder="CNPJ"
            value={dados.cnpj}
            handleChange={handleChange}
            error={dados.error}
            name="cnpj"
            required={true}
          />

          <S.HalfInputContainer>
            <S.CVVContainer>
              <Input
                type="text"
                placeholder="Agência"
                value={dados.agencia}
                handleChange={handleChange}
                error={dados.error}
                name="agencia"
                required={true}
              />
            </S.CVVContainer>
            <S.CVVContainer>
              <Input
                placeholder="Dígito"
                type="text"
                value={dados.digitoAgencia}
                handleChange={handleChange}
                error={dados.error}
                name="digitoAgencia"
                required={false}
              />
            </S.CVVContainer>
          </S.HalfInputContainer>
          <S.HalfInputContainer>
            <S.CVVContainer>
              <Input
                placeholder="Conta"
                type="text"
                value={dados.conta}
                handleChange={handleChange}
                error={dados.error}
                name="conta"
                required={true}
              />
            </S.CVVContainer>
            <S.CVVContainer>
              <Input
                placeholder="Dígito"
                type="text"
                value={dados.digitoConta}
                handleChange={handleChange}
                error={dados.error}
                name="digitoConta"
                required={false}
              />
            </S.CVVContainer>
          </S.HalfInputContainer>

          {dados.error && (
            <S.ErrorMessageContainer>
              <ErrorMessage>Digite dados válidos</ErrorMessage>
            </S.ErrorMessageContainer>
          )}
        </S.Form>

        <S.ButtonContainer>
          {!dados.loading && (
            <Button
              style={{ background: '#FF3D68' }}
              disabled={
                dados.banco.length <= 0 ||
                dados.conta.length <= 0 ||
                dados.cnpj.length <= 0 ||
                dados.agencia.length <= 0
              }
              handleClick={handleRegister}
            >
              Continuar
            </Button>
          )}
          {dados.loading && <Loader />}
        </S.ButtonContainer>
      </S.InputsContainer>
      {tribute && (
        <Modal onClick={() => setTribute(false)}>
          A UGONG em parceria com a Pagar.Me facilita o recebimento das suas
          vendas.
        </Modal>
      )}
    </>
  );
};

export default CardPaymentForm;
